import React from 'react';
import MuiPaper, { PaperProps } from '@material-ui/core/Paper';
import useStyles from './Paper.styles';
import clsx from 'clsx';
import { mergeClasses } from '../../utils';

export interface Props extends PaperProps {
  largeRoundings?: boolean;
}

function Paper({
  children,
  classes,
  largeRoundings = false,
  ...rest
}: Props): React.ReactElement {
  const { largeRoundings: largeRoundingsClassname, ...classNames } =
    useStyles();

  return (
    <MuiPaper
      {...rest}
      classes={mergeClasses(
        {
          ...classNames,
          root: clsx(classNames.root, {
            [largeRoundingsClassname]: largeRoundings,
          }),
        },
        classes,
      )}
    >
      {children}
    </MuiPaper>
  );
}

export default Paper;
