import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { shortenAddress } from '../../utils';
import useStyles from './AccountDisplay.styles';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

export interface Props extends BaseProps {
  account: string;
}

export function AccountDisplay({
  account,
  className,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <div {...rest} className={clsx(classNames.root, className)}>
      <Typography color="textSecondary" variant="body2">
        {shortenAddress(account, { leading: 8, trailing: 4 })}
      </Typography>
    </div>
  );
}
