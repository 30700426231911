import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { PoolBaseInfo } from '../../types';
import Link from '../Link';
import Paper, { PaperProps } from '../Paper';
import PoolAccountLimitText from '../PoolAccountLimitText';
import useStyles from './PoolChangeRow.styles';

type BaseProps = Omit<PaperProps, 'children'>;
export type Props = BaseProps & {
  pool: PoolBaseInfo;
  onPoolChange(poolAddress: string): void;
};

function PoolChangeRow({
  className,
  pool,
  onPoolChange,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  const handleLinkClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      onPoolChange(pool.id);
    },
    [onPoolChange, pool.id],
  );

  return (
    <Paper elevation={2} {...rest} className={clsx(classNames.root, className)}>
      <Grid alignItems="center" container spacing={1}>
        <Grid item sm={4}>
          <Typography variant="subtitle2">{pool.name}</Typography>
        </Grid>

        <Grid item sm={5}>
          <PoolAccountLimitText
            max={pool.maxStakingTokenLimit}
            min={pool.minStakingTokenLimit}
            separator=" - "
            suffix={' ' + pool.stakingTokenSymbol}
            variant="body2"
          />
        </Grid>

        <Grid className={classNames.linkColumn} item sm={3}>
          <Link
            className={classNames.link}
            color="primary"
            showArrow
            underline="none"
            variant="body1"
            onClick={handleLinkClick}
          >
            Switch
          </Link>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PoolChangeRow;
