import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(6),
    },
  },
}));

export default useStyles;
