import { Network } from './types';
import { findNetworkForChain } from './utils/findNetworkForChain';

export const DESIRED_CHAIN_ID = process.env.REACT_APP_DESIRED_CHAIN_ID
  ? parseInt(process.env.REACT_APP_DESIRED_CHAIN_ID, 10)
  : 1;

export const SUPPORTED_NETWORKS: Network[] = [
  {
    appUrl: process.env.REACT_APP_ETHEREUM_APP_URL || '',
    name: 'Ethereum',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    supportedChains: [1, 3, 4, 5, 42],
  },
  {
    appUrl: process.env.REACT_APP_ARBITRUM_APP_URL || '',
    name: 'Arbitrum',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    supportedChains: [
      {
        id: 42161,
        rpcUrl: 'https://arb1.arbitrum.io/rpc',
        blockExplorerUrl: 'https://arbiscan.io/',
      },
      {
        id: 421611,
        rpcUrl: 'https://rinkeby.arbitrum.io/rpc',
        blockExplorerUrl: 'https://rinkeby-explorer.arbitrum.io/',
      },
    ],
  },
];

export const DESIRED_NETWORK = findNetworkForChain(
  SUPPORTED_NETWORKS,
  DESIRED_CHAIN_ID,
);

export const CHAIN_DICT: Record<string, string> = {
  '1': 'Mainnet',
  '3': 'Ropsten Test Network',
  '4': 'Rinkeby Test Network',
  '5': 'Goerli Test Network',
  '42': 'Kovan Test Network',
  '42161': 'Arbitrum One',
  '421611': 'Arbitrum Rinkeby Test Network',
};

export const TNC_URL =
  typeof process.env.REACT_APP_TNC_URL !== 'undefined'
    ? process.env.REACT_APP_TNC_URL
    : '##';

console.log(process.env, TNC_URL);
