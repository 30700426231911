import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import Paper, { PaperProps } from '../Paper';
import useStyles from './SummaryCard.styles';

type BaseProps = Omit<PaperProps, 'children'>;

export type Props = BaseProps & {
  heading: React.ReactNode;
  imageUrl: string;
  primaryText: React.ReactNode;
  secondaryText?: React.ReactNode;
  showSpinner?: boolean;
};

function SummaryCard({
  className,
  heading,
  imageUrl,
  primaryText,
  secondaryText,
  showSpinner = false,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <Paper
      elevation={0}
      largeRoundings
      {...rest}
      className={clsx(classNames.root, className)}
    >
      {showSpinner && (
        <CircularProgress
          className={classNames.spinner}
          color="primary"
          size={16}
        />
      )}

      <Typography
        className={classNames.heading}
        color="textSecondary"
        variant="h6"
      >
        {heading}
      </Typography>

      <div className={classNames.content}>
        <div>
          <Typography color="textSecondary" variant="h5">
            {primaryText}
          </Typography>

          {!!secondaryText && (
            <Typography
              className={classNames.secondaryText}
              variant="subtitle1"
            >
              {secondaryText}
            </Typography>
          )}
        </div>

        <img alt="" className={classNames.image} src={imageUrl} />
      </div>
    </Paper>
  );
}

export default SummaryCard;
