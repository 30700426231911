import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import React, { useCallback, useMemo, useState } from 'react';
import { bignumber, BigNumberish } from '../../math';

type BaseProps = Omit<TextFieldProps, 'onChange' | 'select' | 'value'>;

export type Props = BaseProps & {
  max?: BigNumberish;
  maxDecimalPlaces?: number;
  min?: BigNumberish;
  value?: string;
  onChange?(value: string): void;
};

type ChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const INTEGER_PATTERN = /^[-+]?[0-9]+$/;

function NumericTextField(props: Props): React.ReactElement {
  const {
    max,
    maxDecimalPlaces = 100,
    min,
    value: controlledValue,
    onChange,
    ...rest
  } = props;
  const controlled = props.hasOwnProperty('value');
  const [uncontrolledValue, setUncontrolledValue] = useState<string>('');
  const floatPattern = useMemo(() => {
    return new RegExp(`^[-+]?[0-9]+[\\.]{1}[0-9]{0,${maxDecimalPlaces}}$`);
  }, [maxDecimalPlaces]);

  const value = controlled ? controlledValue : uncontrolledValue;

  const updateValue = useCallback(
    (value: string) => {
      if (!controlled) {
        setUncontrolledValue(value);
      }

      onChange?.(value);
    },
    [controlled, onChange],
  );

  const handleChange = useCallback(
    (event: ChangeEvent) => {
      const nextValue = event.target.value;

      if (nextValue === '') {
        updateValue('');
        return;
      }

      const matchesNumberPattern =
        INTEGER_PATTERN.test(nextValue) || floatPattern.test(nextValue);

      if (!matchesNumberPattern) {
        return;
      }

      try {
        const numberValue = bignumber(nextValue);
        const belowMin =
          typeof min !== 'undefined' && numberValue.lt(bignumber(min));
        const aboveMax =
          typeof max !== 'undefined' && numberValue.gt(bignumber(max));

        if (!belowMin && !aboveMax) {
          updateValue(nextValue);
        }
      } catch (parseError) {
        console.warn('Error parsing field value', parseError);
      }
    },
    [floatPattern, max, min, updateValue],
  );

  return <TextField {...rest} onChange={handleChange} value={value} />;
}

export default NumericTextField;
