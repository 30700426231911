type AsyncFn<T> = (...args: any[]) => Promise<T>;
type KeyExtractorFn<F extends AsyncFn<any>> = (
  ...args: Parameters<F>
) => string;

export function memoizeAsync<T, F extends AsyncFn<T>>(
  fn: F,
  keyExtractor: KeyExtractorFn<F>,
): F {
  let cache: Record<string, T> = {};

  const memoized = async (...args: Parameters<F>): Promise<T> => {
    const key = keyExtractor(...args);

    if (key in cache) {
      return cache[key];
    } else {
      const result = await fn(...args);
      cache[key] = result;
      return result;
    }
  };

  return memoized as F;
}
