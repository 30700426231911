import { alpha, makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

interface StyleProps {
  rounded: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: ({ rounded }: StyleProps) => ({
    borderRadius: rounded ? 24 : 8,
    lineHeight: '24px',
    paddingLeft: 16,
    paddingRight: 16,
  }),
  containedPrimary: {
    background: `linear-gradient(41.06deg, ${theme.palette.primary.main} 26.5%, ${theme.palette.primary.light} 54.5%)`,
    boxShadow: `4px 10px 25px ${alpha(theme.palette.primary.main, 0.3)}`,
    color: theme.palette.getContrastText(theme.palette.primary.main),

    '&:hover': {
      boxShadow: `4px 10px 25px ${alpha(theme.palette.primary.main, 0.3)}`,
    },

    '&$disabled': {
      background: '#C3B7E7',
      boxShadow: 'inset 0 0 0 2px #C7B7FF',
      color: '#8D84C8',
    },
  },
  containedSecondary: {
    borderStyle: 'solid',
    borderWidth: 2,
    color:
      theme.palette.text[theme.variant === 'light' ? 'secondary' : 'primary'],
    paddingBottom: 10,
    paddingTop: 10,

    '&$sizeSmall': {
      borderWidth: 0,
    },

    '&$disabled': {
      backgroundColor: Color.WHITE,
      borderColor: 'transparent',
      color: Color.GRAY,
      opacity: 0.5,
    },
  },
  containedSizeLarge: {
    ...theme.typography.button,
  },
  disabled: {},
  endIcon: {
    marginLeft: 13,
  },
  iconSizeMedium: {
    '& > *:first-child': {
      fontSize: 24,
    },
  },
  sizeLarge: ({ rounded }: StyleProps) => ({
    borderRadius: rounded ? 100 : 16,
    padding: '21px 32px',
  }),
  sizeSmall: ({ rounded }: StyleProps) => ({
    borderRadius: rounded ? 19 : 4,
    padding: 12,
    fontSize: 11,
    fontWeight: 700,
    letterSpacing: '0.1em',
    lineHeight: '14px',
  }),
}));

export default useStyles;
