import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export function StatisticsIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5263 4.24413C12.0138 4.15287 11.4861 4.10526 10.9474 4.10526C6.00587 4.10526 2 8.11113 2 13.0526C2 17.9941 6.00587 22 10.9474 22C15.8889 22 19.8947 17.9941 19.8947 13.0526C19.8947 12.8759 19.8896 12.7004 19.8795 12.5263H17.8751C17.8881 12.7 17.8947 12.8756 17.8947 13.0526C17.8947 16.8895 14.7843 20 10.9474 20C7.11044 20 4 16.8895 4 13.0526C4 9.2157 7.11044 6.10526 10.9474 6.10526C11.4905 6.10526 12.0191 6.16758 12.5263 6.28547V4.24413Z"
        />
        <mask id="path-2-inside-1" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.0526 12.5263C22.7241 6.86311 18.1895 2.32851 12.5263 2V12.5263L23.0526 12.5263Z"
          />
        </mask>
        <path
          d="M23.0526 12.5263V14.5263H25.172L25.0493 12.4105L23.0526 12.5263ZM12.5263 2L12.6421 0.00335634L10.5263 -0.119376V2H12.5263ZM12.5263 12.5263H10.5263V14.5263H12.5263L12.5263 12.5263ZM25.0493 12.4105C24.662 5.73389 19.3187 0.390646 12.6421 0.00335634L12.4105 3.99664C17.0603 4.26636 20.7863 7.99234 21.056 12.6421L25.0493 12.4105ZM10.5263 2V12.5263H14.5263V2H10.5263ZM12.5263 14.5263L23.0526 14.5263V10.5263L12.5263 10.5263L12.5263 14.5263Z"
          mask="url(#path-2-inside-1)"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 9.68935V8C18.842 8.28087 22.7191 12.158 23 17H20.2805C19.9986 20.9683 16.69 24.1 12.65 24.1C8.42502 24.1 5 20.675 5 16.45C5 12.225 8.42502 8.79999 12.65 8.79999C13.1106 8.79999 13.5618 8.84071 14 8.91873L14 9.68935Z"
        />
      </g>

      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
