import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import ErrorCircleIcon from '../icons/ErrorCircleIcon';
import WarningTriangleIcon from '../icons/WarningTriangleIcon';
import useStyles from './Message.styles';

type Variant = 'error' | 'warning';
type BaseProps = React.HTMLAttributes<HTMLDivElement>;

export type Props = BaseProps & {
  disableTypography?: boolean;
  variant: Variant;
};

function Message({
  children,
  className,
  disableTypography = false,
  variant,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  const icon = useMemo(() => {
    switch (variant) {
      case 'error':
        return <ErrorCircleIcon color="inherit" />;
      case 'warning':
        return <WarningTriangleIcon color="inherit" />;
    }
  }, [variant]);

  return (
    <div
      {...rest}
      className={clsx(
        classNames.root,
        {
          [classNames.variantError]: variant === 'error',
          [classNames.variantWarning]: variant === 'warning',
        },
        className,
      )}
    >
      <div className={classNames.iconWrapper}>{icon}</div>

      {disableTypography ? (
        children
      ) : (
        <Typography variant="body2">{children}</Typography>
      )}
    </div>
  );
}

export default Message;
