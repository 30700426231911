import { alpha, makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

interface StyleProps {
  disabled: boolean;
  fadeColor: string | undefined;
  maxHeight: string | number;
  open: boolean;
  scrollable: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  faded: ({ fadeColor, open, scrollable }: StyleProps) => ({
    '&:before': {
      background: `linear-gradient(360deg, ${
        fadeColor || theme.backgroundColor
      } 20.73%, transparent 100%)`,
      bottom: 0,
      content: "' '",
      display: 'block',
      height: 100,
      left: 0,
      opacity: scrollable && open ? 0 : 1,
      position: 'absolute',
      transition: 'opacity 0.3s ease-in-out',
      right: 0,
      zIndex: 1,
    },
  }),
  content: ({ disabled, maxHeight, open, scrollable }: StyleProps) => ({
    maxHeight: disabled || (open && !scrollable) ? 'none' : maxHeight,
    overflowY: scrollable ? 'scroll' : 'hidden',
    position: 'relative',

    '&::-webkit-scrollbar': {
      width: 6,
    },

    '&::-webkit-scrollbar-thumb': {
      background: alpha(Color.GREY, 0),
      borderRadius: 3,
      transition: 'background 250ms ease-in-out 500ms',
    },

    '&:hover::-webkit-scrollbar-thumb': {
      background: alpha(Color.GREY, 0.4),
    },
  }),
  buttonArea: {
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    transform: 'translateY(50%)',
    zIndex: 2,
  },
}));

export default useStyles;
