import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import React from 'react';
import imageSrc from '../../assets/images/deposit.png';
import Typography from '@material-ui/core/Typography';
import useStyles from './PoolChangeDialog.styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '../icons/CloseIcon';
import { PoolBaseInfo } from '../../types';
import PoolChangeCard from '../PoolChangeCard';
import Button from '../Button';
import Hidden from '@material-ui/core/Hidden';
import PoolChangeRow from '../PoolChangeRow';
import Grid from '@material-ui/core/Grid';

type BaseProps = Omit<DialogProps, 'children' | 'onClose'>;
export type Props = BaseProps & {
  candidatePools: PoolBaseInfo[];
  onClose(): void;
  onPoolChange(poolAddress: string): void;
};

function PoolChangeDialog({
  candidatePools,
  onClose,
  onPoolChange,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <Dialog fullWidth maxWidth="sm" scroll="body" {...rest}>
      <div className={classNames.content}>
        <div className={classNames.closeBar}>
          <IconButton color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <img alt="" className={classNames.image} src={imageSrc} />
        <Typography align="center" className={classNames.title} variant="h4">
          Pool limits exceeded
        </Typography>

        <Typography
          align="center"
          className={classNames.text}
          color="textSecondary"
          variant="body1"
        >
          If the amount you wish to stake does not fit pool limits you can try
          selecting another pool.
        </Typography>

        <div>
          <Hidden xsDown>
            <div className={classNames.listHeader}>
              <Grid container spacing={1}>
                <Grid item sm={4}>
                  <Typography color="textSecondary" variant="h6">
                    Pool
                  </Typography>
                </Grid>

                <Grid item sm={5}>
                  <Typography color="textSecondary" variant="h6">
                    Limits
                  </Typography>
                </Grid>

                <Grid item sm={3}>
                  <Typography align="right" color="textSecondary" variant="h6">
                    Action
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Hidden>

          {candidatePools.map((pool) => (
            <React.Fragment key={pool.id}>
              <Hidden smUp>
                <PoolChangeCard
                  className={classNames.item}
                  pool={pool}
                  onPoolChange={onPoolChange}
                />
              </Hidden>

              <Hidden xsDown>
                <PoolChangeRow
                  className={classNames.item}
                  pool={pool}
                  onPoolChange={onPoolChange}
                />
              </Hidden>
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className={classNames.footer}>
        <Button
          color="primary"
          onClick={onClose}
          rounded
          size="large"
          variant="contained"
        >
          Close this message
        </Button>
      </div>
    </Dialog>
  );
}

export default PoolChangeDialog;
