import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => {
  const borderColor =
    theme.variant === 'light' ? Color.LIGHT_BLUE : Color.DARK3;

  return {
    root: {
      border: `2px solid ${borderColor}`,
      padding: theme.spacing(3),
      position: 'relative',
    },
    infoButton: {
      color: Color.GREY,
      position: 'absolute',
      right: 6,
      top: 4,

      '& svg': {
        fontSize: 16,
      },
    },
  };
});

export default useStyles;
