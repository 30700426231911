import { Token } from '@uniswap/sdk-core';
import { providers, utils } from 'ethers';
import { DESIRED_CHAIN_ID } from '../constants';
import { BigNumber } from '../math';
import { getTokenUSDPriceUniswapV2 } from './getTokenUSDPriceUniswapV2';
import { getTokenUSDPriceUniswapV3 } from './getTokenUSDPriceUniswapV3';

export interface Options {
  tokenAddress: string;
  tokenDecimals: number;
}

const UNISWAP_VERSION: 'v2' | 'v3' =
  process.env.REACT_APP_UNISWAP_VERSION?.toLowerCase() === 'v3' ? 'v3' : 'v2';

const USD_LIKE_TOKENS =
  typeof process.env.REACT_APP_USD_LIKE_TOKENS === 'string'
    ? process.env.REACT_APP_USD_LIKE_TOKENS.split(',').filter(utils.isAddress)
    : [];

export async function getTokenUSDPrice(
  provider: providers.Provider,
  { tokenAddress, tokenDecimals }: Options,
): Promise<BigNumber | null> {
  const token = new Token(DESIRED_CHAIN_ID, tokenAddress, tokenDecimals);

  if (UNISWAP_VERSION === 'v3') {
    const result = await getTokenUSDPriceUniswapV3(provider, {
      candidates: USD_LIKE_TOKENS,
      token,
    });
    return result;
  }

  const result = await getTokenUSDPriceUniswapV2(provider, {
    candidates: USD_LIKE_TOKENS,
    token,
  });
  return result;
}
