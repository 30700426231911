import React from 'react';
import MuiLink, { LinkProps } from '@material-ui/core/Link';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import useStyles from './Link.styles';
import clsx from 'clsx';

export interface Props extends LinkProps {
  classes?: LinkProps['classes'] & Partial<ReturnType<typeof useStyles>>;
  component?: React.ElementType;
  showArrow?: boolean;
}

function Link({
  children,
  classes = {},
  showArrow = false,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();
  const { arrowVisible, icon, root, ...restOfClasses } = classes;

  return (
    <MuiLink
      {...rest}
      classes={{
        ...restOfClasses,
        root: clsx(classes.root, {
          [clsx(classNames.arrowVisible, classes.arrowVisible)]: showArrow,
        }),
      }}
    >
      {children}
      {showArrow && (
        <ArrowRightIcon className={clsx(classNames.icon, classes.icon)} />
      )}
    </MuiLink>
  );
}

export default Link;
