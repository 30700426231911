import Box from '@material-ui/core/Box';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import React, { useCallback, useState } from 'react';
import noWalletImageSrc from '../../assets/images/no-wallet.png';
import { TNC_URL } from '../../constants';
import { useWalletOptions } from '../../wallet';
import useStyles from './WalletDialog.styles';

export type Props = Omit<DialogProps, 'children'>;

export function WalletDialog(props: Props): React.ReactElement {
  const classNames = useStyles();
  const walletOptions = useWalletOptions();

  const [, setError] = useState<any>();
  const [, setLoading] = useState(false);

  const connectWallet = useCallback(async (connectFn: () => Promise<void>) => {
    try {
      setError(undefined);
      setLoading(true);
      await connectFn();
    } catch (connectError) {
      setError(connectError);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Dialog {...props}>
      <DialogContent classes={{ root: classNames.dialogContentRoot }}>
        <img alt="" className={classNames.image} src={noWalletImageSrc} />

        <Typography align="center" className={classNames.title} variant="h4">
          No wallet
        </Typography>

        <Typography
          align="center"
          className={classNames.text}
          color="textSecondary"
        >
          We’ve detected that you do not have a wallet connected. Please connect
          one to continue.
        </Typography>

        <List
          classes={{ root: classNames.listRoot }}
          component="div"
          disablePadding
        >
          {walletOptions.map((option, index) => {
            const { connect, icon, label } = option;

            const primaryNode =
              index === 0 ? (
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography variant="body2">{label}</Typography>
                  <div className={classNames.recommendedBadge}>
                    <Typography variant="overline">Recommended</Typography>
                  </div>
                </Box>
              ) : (
                label
              );

            return (
              <ListItem
                key={index}
                button
                classes={{
                  root: classNames.listItemRoot,
                  button: classNames.listItemButton,
                }}
                onClick={() => connectWallet(connect)}
              >
                {!!icon && (
                  <ListItemIcon classes={{ root: classNames.listItemIconRoot }}>
                    {icon}
                  </ListItemIcon>
                )}

                <ListItemText
                  primary={primaryNode}
                  primaryTypographyProps={{
                    variant: 'body2',
                  }}
                />

                <ArrowRightIcon color="primary" fontSize="small" />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>

      <div className={classNames.footer}>
        <Typography align="center" color="textSecondary" variant="body2">
          By connecting, I accept{' '}
          <Link
            color="primary"
            href={TNC_URL}
            target="_blank"
            underline="always"
          >
            Terms of service
          </Link>
        </Typography>
      </div>
    </Dialog>
  );
}
