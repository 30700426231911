import { Web3ReactProvider } from '@web3-react/core';
import { providers } from 'ethers';
import React from 'react';
import { WalletProps } from '../Wallet';
import { Wallet } from '../Wallet/Wallet';

export type Props = WalletProps;

export function WalletProvider(props: Props): React.ReactElement {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Wallet {...props} />
    </Web3ReactProvider>
  );
}

function getLibrary(provider: any) {
  return new providers.Web3Provider(provider);
}
