import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom:
      theme.variant === 'light' ? 'none' : `1px solid ${Color.DARK3}`,
    padding: `0 ${theme.spacing(3)}px`,
  },
  content: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));

export default useStyles;
