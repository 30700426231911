import { useCallback, useEffect, useState } from 'react';
import { getPools } from '../chain';
import { PoolBaseInfo } from '../types';
import { useProvider } from '../wallet';

interface Options {
  refetchOnBlock?: boolean;
}

export function usePools(options: Options = {}) {
  const { refetchOnBlock = false } = options;
  const provider = useProvider();

  const [data, setData] = useState<PoolBaseInfo[]>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);

  const loadData = useCallback(
    async (silent = false) => {
      if (!provider) {
        return;
      }

      if (!silent) {
        setLoading(true);
        setError(undefined);
        setData(undefined);
      }

      try {
        const data = await getPools(provider);
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [provider],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (refetchOnBlock && provider) {
      const refetch = () => loadData(true);

      provider.on('block', refetch);

      return () => {
        provider.off('block', refetch);
      };
    }
  }, [loadData, provider, refetchOnBlock]);

  return {
    data,
    error,
    loading,
    refetch: loadData,
  };
}
