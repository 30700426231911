import React from 'react';
import { useAccountPoolStats } from '../../hooks';
import { Pool } from '../../types';
import PoolOverview from '../PoolOverview';
import PoolStats from '../PoolStats';
import useStyles from './StatsPanel.styles';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

export type Props = BaseProps & {
  pool: Pool;
};

function StatsPanel({ pool, ...rest }: Props): React.ReactElement {
  const classNames = useStyles();
  const { data: accountPoolStats } = useAccountPoolStats({
    poolAddress: pool.id,
    refetchOnBlock: true,
  });

  return (
    <div {...rest}>
      <PoolOverview
        className={classNames.summary}
        estimatedAccrued={accountPoolStats?.estimatedAccrued}
        pool={pool}
      />
      <PoolStats pool={pool} />
    </div>
  );
}

export default StatsPanel;
