import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function WarningTriangleIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path
          d="M10.4372 4.7194L11.3054 5.21554L10.4372 4.7194L3.81571 16.3069C3.13 17.5069 3.99646 19 5.37854 19H18.6215C20.0035 19 20.87 17.5069 20.1843 16.307L13.5628 4.7194C12.8718 3.51013 11.1282 3.51014 10.4372 4.7194Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          opacity="0.1"
          d="M12.4372 8.7194L13.3054 9.21554L12.4372 8.7194L5.81571 20.3069C5.13 21.5069 5.99646 23 7.37854 23H20.6215C22.0035 23 22.87 21.5069 22.1843 20.307L15.5628 8.7194C14.8718 7.51013 13.1282 7.51014 12.4372 8.7194Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2"
        />
        <line
          x1="12"
          y1="12"
          x2="12"
          y2="9"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle cx="12" cy="15" r="1" fill="currentColor" />
      </g>
    </SvgIcon>
  );
}

export default WarningTriangleIcon;
