import Typography, { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import useStyles from './StatCardText.styles';

export type Props = Omit<TypographyProps, 'variant'> & {
  variant: 'primary' | 'secondary';
};

function StatCardText({
  className,
  children,
  variant,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <Typography
      {...rest}
      className={clsx(
        {
          [classNames.primary]: variant === 'primary',
          [classNames.secondary]: variant === 'secondary',
        },
        className,
      )}
      variant={variant === 'primary' ? 'subtitle1' : 'h6'}
    >
      {children}
    </Typography>
  );
}

export default StatCardText;
