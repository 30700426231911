import { FACTORY_ADDRESS } from '@uniswap/v2-sdk';
import { BigNumber, Contract, providers, Signer } from 'ethers';
import ABI from './uniswapV2FactoryABI';

export class UniswapV2Factory {
  protected contract: Contract;

  constructor(providerOrSigner: providers.Provider | Signer) {
    this.contract = new Contract(FACTORY_ADDRESS, ABI, providerOrSigner);
  }

  public async getPair(tokenA: string, tokenB: string): Promise<string | null> {
    const pairAddress: string = await this.contract.getPair(tokenA, tokenB);
    return BigNumber.from(pairAddress).eq(0) ? null : pairAddress;
  }
}
