import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import React from 'react';
import ErrorCircleIcon from '../icons/ErrorCircleIcon';
import InfoCircleIcon from '../icons/InfoCircleIcon';
import SuccessCircleIcon from '../icons/SuccessCircleIcon';
import WarningTriangleIcon from '../icons/WarningTriangleIcon';
import useStyles from './Snackbar.styles';

export interface Props {
  children: SnackbarProviderProps['children'];
}

function Snackbar({ children }: Props): React.ReactElement {
  const classes = useStyles();

  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      classes={classes}
      iconVariant={{
        error: <ErrorCircleIcon />,
        info: <InfoCircleIcon />,
        success: <SuccessCircleIcon />,
        warning: <WarningTriangleIcon />,
      }}
      maxSnack={4}
    >
      {children}
    </SnackbarProvider>
  );
}

export default Snackbar;
