import { makeStyles } from '@material-ui/core/styles';

const ICON_SIZE = 100;

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    height: ICON_SIZE,
    marginBottom: theme.spacing(4.5),
    width: ICON_SIZE,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
