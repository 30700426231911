import { AbstractConnector } from '@web3-react/abstract-connector';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import React, { createContext, useCallback, useMemo } from 'react';
import { WalletError, WalletErrorCode } from '../..';
import { useEagerConnect } from '../../hooks/useEagerConnect';
import { useInactiveListeners } from '../../hooks/useInactiveListeners';
import { WalletConfig } from '../../types/WalletConfig';
import { WalletOption } from '../../types/WalletOption';

type BeforeConnectFn = (connector: AbstractConnector) => Promise<boolean>;

export interface RenderProps {
  error?: WalletError;
  loading: boolean;
}

export interface Props {
  desiredChainId?: string | number;
  walletsConfig: WalletConfig[];
  children(renderProps: RenderProps): React.ReactElement | null;
}

export interface WalletContextShape {
  options: WalletOption[];
}

export const WalletContext = createContext<WalletContextShape>({
  options: [],
});

export function Wallet({
  desiredChainId = 1,
  walletsConfig,
  children: renderFn,
}: Props): React.ReactElement {
  const { active, activate, chainId } = useWeb3React();

  const connect = useCallback(
    async (connector: AbstractConnector, beforeConnect?: BeforeConnectFn) => {
      if (!connector) {
        return;
      }

      const shouldActivate = beforeConnect
        ? await beforeConnect(connector)
        : true;

      if (shouldActivate) {
        await activate(connector, undefined, true);
      }
    },
    [activate],
  );

  const error: WalletError | undefined = useMemo(() => {
    if (
      active &&
      typeof chainId !== 'undefined' &&
      chainId.toString() !== desiredChainId.toString()
    ) {
      return {
        code: WalletErrorCode.INVALID_CHAIN,
        currentChainId: chainId,
        desiredChainId:
          typeof desiredChainId === 'string'
            ? parseInt(desiredChainId, 10)
            : desiredChainId,
      };
    }
  }, [active, chainId, desiredChainId]);

  const options = useMemo(() => {
    return walletsConfig.map<WalletOption>(
      ({ connector, label, icon, beforeConnect }) => {
        return {
          label,
          icon: icon || null,
          connect: () => connect(connector, beforeConnect),
        };
      },
    );
  }, [connect, walletsConfig]);

  const injectedConnectorConfig = walletsConfig.find(
    (item) => item.connector instanceof InjectedConnector,
  );

  const injectedConnector =
    injectedConnectorConfig?.connector as InjectedConnector;

  const triedEager = useEagerConnect(injectedConnector);

  useInactiveListeners(!triedEager, injectedConnector);

  return (
    <WalletContext.Provider value={{ options }}>
      {renderFn({ error, loading: !triedEager })}
    </WalletContext.Provider>
  );
}
