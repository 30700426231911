import React from 'react';
import MuiSwitch, { SwitchProps } from '@material-ui/core/Switch';
import { mergeClasses } from '../../utils';
import useStyles from './Switch.styles';

export type Props = SwitchProps;

export function Switch({ classes, ...rest }: Props): React.ReactElement {
  const classNames = useStyles();
  return <MuiSwitch {...rest} classes={mergeClasses(classNames, classes)} />;
}
