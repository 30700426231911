import React, { useMemo } from 'react';
import { bignumber, BigNumberish } from '../../math';
import BigNumberFormat, { BigNumberFormatProps } from '../BigNumberFormat';

export type Props = BigNumberFormatProps & {
  usdPrice: BigNumberish | null;
};

function BigNumberUSDFormat({
  prefix,
  suffix,
  usdPrice,
  value,
  ...rest
}: Props): React.ReactElement {
  const autoPrefix = usdPrice === null ? prefix : '$';
  const autoSuffix = usdPrice === null ? suffix : undefined;

  const autoValue: BigNumberish = useMemo(() => {
    if (usdPrice !== null) {
      return bignumber(value).mul(bignumber(usdPrice));
    }

    return value;
  }, [usdPrice, value]);

  return (
    <BigNumberFormat
      {...rest}
      prefix={autoPrefix}
      suffix={autoSuffix}
      value={autoValue}
    />
  );
}

export default BigNumberUSDFormat;
