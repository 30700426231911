import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export function PoolsIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0)" fill="none">
        <path
          d="M3.42678 3.57322L6.82322 0.176777C6.92085 0.0791456 7.07915 0.0791453 7.17678 0.176776L10.5732 3.57322C10.7307 3.73071 10.6192 4 10.3964 4H3.60355C3.38083 4 3.26928 3.73072 3.42678 3.57322Z"
          fill="currentColor"
        />
        <path
          d="M6 5.75C6 5.88807 6.11193 6 6.25 6H7.75C7.88807 6 8 5.88807 8 5.75V4H6V5.75Z"
          fill="currentColor"
        />
        <path
          d="M19.5732 2.42678L16.1768 5.82322C16.0791 5.92085 15.9209 5.92085 15.8232 5.82322L12.4268 2.42678C12.2693 2.26928 12.3808 2 12.6036 2L19.3964 2C19.6192 2 19.7307 2.26928 19.5732 2.42678Z"
          fill="currentColor"
        />
        <path
          d="M17 0.25C17 0.111929 16.8881 -9.78513e-09 16.75 -2.18557e-08L15.25 -1.5299e-07C15.1119 -1.6506e-07 15 0.111929 15 0.25L15 2L17 2L17 0.25Z"
          fill="currentColor"
        />
        <rect
          x="3"
          y="9"
          width="18"
          height="11"
          rx="1"
          stroke="currentColor"
          strokeWidth="2"
        />
        <rect
          opacity="0.1"
          x="6"
          y="12"
          width="18"
          height="12"
          rx="2"
          fill="currentColor"
        />
        <line
          x1="4"
          y1="13"
          x2="20"
          y2="13"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>

      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
