import clsx from 'clsx';
import React from 'react';
import Paper from '../Paper';
import useStyles from './ContentCard.styles';

export type Props = React.HTMLAttributes<HTMLDivElement>;

function ContentCard({ children, className, ...rest }: Props) {
  const classNames = useStyles();

  return (
    <Paper elevation={2} {...rest} className={clsx(classNames.root, className)}>
      {children}
    </Paper>
  );
}

export default ContentCard;
