import { TransactionStatus } from '../types';

const DICT: Record<TransactionStatus, string> = {
  [TransactionStatus.INITIALIZED]: 'Transaction initialised.',
  [TransactionStatus.APPROVE_REQUIRED]: 'Approve speding in your wallet.',
  [TransactionStatus.APPROVE_IN_PROGRESS]: 'Waiting for approval.',
  [TransactionStatus.CONFIRMATION_REQUIRED]:
    'Confirm transaction in your wallet.',
  [TransactionStatus.IN_PROGRESS]: 'Waiting for transaction to finish.',
  [TransactionStatus.DONE]: 'Transaction completed.',
};

export function showTransactionStatusMessage(
  transactionStatus: TransactionStatus,
): string {
  return DICT[transactionStatus];
}
