import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import React from 'react';
import imageSrc from '../../assets/images/wallet.png';
import useStyles from './TransactionDialog.styles';

export type Props = DialogProps;

function TransactionDialog({ children, ...rest }: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <Dialog fullWidth maxWidth="md" {...rest}>
      <img alt="" className={classNames.image} src={imageSrc} />
      <div className={classNames.content}>{children}</div>
    </Dialog>
  );
}

export default TransactionDialog;
