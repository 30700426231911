import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import { useAccount } from '../../wallet';
import AppBar from '../AppBar';
import { Container } from '../Container';
import { ControlsBar } from '../ControlsBar';
import { WalletDialog } from '../WalletDialog';
import useStyles from './Screen.styles';

export type Props = React.PropsWithChildren<{}>;

function Screen({ children }: Props): React.ReactElement {
  const classNames = useStyles();
  const { account } = useAccount();

  return (
    <React.Fragment>
      <div className={classNames.root}>
        <main className={classNames.main}>
          <AppBar />
          <Container className={classNames.content}>{children}</Container>

          <Hidden mdDown>
            <div className={classNames.bottomBar}>
              <ControlsBar className={classNames.controlsBar} />
            </div>
          </Hidden>
        </main>
      </div>

      <WalletDialog fullWidth maxWidth="md" open={!account} />
    </React.Fragment>
  );
}

export default Screen;
