import { useWeb3React } from '@web3-react/core';

export function useAccount() {
  const { account, chainId, deactivate } = useWeb3React();

  return {
    account,
    chainId,
    disconnect: deactivate,
  };
}
