import { providers } from 'ethers';
import { adjustDecimals, BigNumber } from '../math';
import { ERC20 } from './contracts/ERC20';
import { ChainError } from './errors/ChainError';
import { getTokenDecimals } from './getTokenDecimals';

interface Options {
  account: string;
  token: string;
}

export async function getTokenBalance(
  provider: providers.Provider,
  { account, token }: Options,
): Promise<BigNumber> {
  try {
    const erc20 = new ERC20(token, provider);

    const [balance, decimals] = await Promise.all([
      erc20.balanceOf(account),
      getTokenDecimals(provider, token),
    ]);

    return adjustDecimals(balance, decimals);
  } catch (error) {
    throw ChainError.fromError(error);
  }
}
