import { providers } from 'ethers';
import { ChainError } from '.';
import { TransactionStatus } from '../types';
import { ParibusStake } from './contracts/ParibusStake';

export async function* claimRewards(
  provider: providers.Web3Provider,
  poolAddress: string,
) {
  try {
    yield TransactionStatus.INITIALIZED;

    const pool = new ParibusStake(poolAddress, provider.getSigner());

    yield TransactionStatus.CONFIRMATION_REQUIRED;
    const tx = await pool.claimUnlocked();
    yield TransactionStatus.IN_PROGRESS;
    await tx.wait();
    yield TransactionStatus.DONE;
  } catch (error) {
    throw ChainError.fromError(error);
  }
}
