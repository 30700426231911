export enum Color {
  BLACK = '#1E1F23',
  DARK1 = '#222339',
  DARK2 = '#1B1C30',
  DARK3 = '#383952',
  DARK4 = '#888FA5',
  DARK5 = '#4593FF',
  GRAY = '#5B637C',
  GREY = '#AEB0B6',
  LIGHT_BLUE = '#F1F7FE',
  LIGHT_GREY = '#F4F5FB',
  ORANGE = '#F4B740',
  PURPLE = '#725BFF',
  RED = '#F46868',
  TURQUOISE = '#00E5CC',
  WHITE = '#FFFFFF',
}
