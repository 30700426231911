import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '32px 16px',

    [theme.breakpoints.up('md')]: {
      padding: `32px 40px`,
    },
  },
}));

export default useStyles;
