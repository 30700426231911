import { Network } from '../types';

export function findNetworkForChain(
  networks: Network[],
  chainId: number,
): Network | undefined {
  return networks.find((network) => {
    const foundIndex = network.supportedChains.findIndex((chain) => {
      return typeof chain === 'number'
        ? chain === chainId
        : chain.id === chainId;
    });

    return foundIndex !== -1;
  });
}
