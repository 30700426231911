import { alpha, makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';
import { blendColors } from '../../utils';

const useStyles = makeStyles((theme) => {
  const backgroundColorBase =
    theme.variant === 'light' ? Color.WHITE : Color.DARK2;
  const shadowColor =
    theme.variant === 'light'
      ? 'rgba(69, 147, 255, 0.2)'
      : 'rgba(244, 183, 64, 0.1)';

  const createBackgroundColor = (color: string) => {
    return blendColors(alpha(color, 0.07), backgroundColorBase);
  };

  return {
    root: {
      alignItems: 'center',
      borderRadius: 8,
      borderStyle: 'solid',
      borderWidth: 2,
      boxShadow: `0px 0px 20px ${shadowColor}`,
      color:
        theme.palette.text[theme.variant === 'light' ? 'secondary' : 'primary'],
      display: 'flex',
      padding: '10px 6px',
    },
    iconWrapper: {
      marginRight: theme.spacing(1),
    },
    variantError: {
      backgroundColor: createBackgroundColor(theme.palette.error.main),
      borderColor: theme.palette.error.main,

      '& $iconWrapper': {
        color: theme.palette.error.main,
      },
    },
    variantWarning: {
      backgroundColor: createBackgroundColor(theme.palette.warning.main),
      borderColor: theme.palette.warning.main,

      '& $iconWrapper': {
        color: theme.palette.warning.main,
      },
    },
  };
});

export default useStyles;
