import clsx from 'clsx';
import React from 'react';
import Spinner from '../Spinner';
import useStyles from './LoadingBox.styles';

export type Props = React.HTMLAttributes<HTMLDivElement>;

function LoadingBox({
  children,
  className,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <div {...rest} className={clsx(classNames.root, className)}>
      <div className={classNames.content}>{children}</div>
      <Spinner size={116} />
    </div>
  );
}

export default LoadingBox;
