import { providers } from 'ethers';
import { AccountPoolStats } from '../types';
import { ParibusStake } from './contracts/ParibusStake';
import { ChainError } from './errors/ChainError';

interface Options {
  account: string;
  poolAddress: string;
}

export async function getAccountPoolStats(
  provider: providers.Provider,
  { account, poolAddress }: Options,
): Promise<AccountPoolStats> {
  try {
    const pool = new ParibusStake(poolAddress, provider);

    const [
      ,
      stakedTokenPenaltyFree,
      rewardAmountPenaltyFree,
      stakedTokenPenalty,
      rewardAmountAfterPenalty,
      rewardAmountLost,
    ] = await pool.getUserRewardInformationFor(account);

    const [, , , unlockTimestamp] = await pool.simulateUnstake(
      account,
      stakedTokenPenalty.add(stakedTokenPenaltyFree).toHex(),
    );

    return {
      estimatedAccrued: rewardAmountPenaltyFree.add(rewardAmountAfterPenalty),
      penaltyExpirationDate: unlockTimestamp,
      potentialRewardLoss: rewardAmountLost,
      rewards: rewardAmountPenaltyFree,
      withdrawable: stakedTokenPenaltyFree.add(stakedTokenPenalty),
      withdrawableWithoutLoss: stakedTokenPenaltyFree,
    };
  } catch (error) {
    throw ChainError.fromError(error);
  }
}
