import { BigNumber, Contract, providers, Signer } from 'ethers';
import { BigNumber as BN, bignumber, BigNumberish } from '../../../math';
import { ChainTransaction } from '../../types/ChainTransaction';
import ABI from './paribusStakeABI';

type EventType = 'ClaimedTokensFromShare' | 'Staked' | 'Unstaked';

export class ParibusStake {
  protected contract: Contract;

  constructor(address: string, providerOrSigner: providers.Provider | Signer) {
    this.contract = new Contract(address, ABI, providerOrSigner);
  }

  public async claimUnlocked(): Promise<ChainTransaction<any>> {
    const tx = await this.contract.claimUnlocked();
    return tx;
  }

  public async emergencyWithdrawn(): Promise<ChainTransaction<any>> {
    const tx = await this.contract.emergencyWithdrawn();
    return tx;
  }

  public async getPoolInfo(): Promise<[string, BN, BN, BN, number, BN, BN]> {
    const [
      poolName,
      penalyPercent,
      penaltyLockupPeriod,
      maximumStakedTokenAllowed,
      unlockedAt,
      minAmountOfStakedTokensRequired,
      maxAmountOfStakedTokensRequired,
    ]: [
      string,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
    ] = await this.contract.getPoolInfo();

    return [
      poolName,
      bignumber(penalyPercent.toString()),
      bignumber(penaltyLockupPeriod.toString()),
      bignumber(maximumStakedTokenAllowed.toString()),
      unlockedAt.toNumber(),
      bignumber(minAmountOfStakedTokensRequired.toString()),
      bignumber(maxAmountOfStakedTokensRequired.toString()),
    ];
  }

  public async getPoolState(): Promise<[BN, number, BN, BN, BN, BN, BN, BN]> {
    const [
      stakedTokenCurrentAmount,
      updateLastTime,
      updateUnlocksPerSecond,
      rewardTokenFromAllTimeToDistribute,
      rewardTokenTotalClaimedRewards,
      rewardTokenTotalUnlockedTokens,
      rewardTokensCurrentlyUnlocked,
      rewardTokensCurrentlyToBeUnlocked,
    ]: [
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
    ] = await this.contract.getPoolState();

    return [
      bignumber(stakedTokenCurrentAmount.toString()),
      updateLastTime.toNumber(),
      bignumber(updateUnlocksPerSecond.toString()),
      bignumber(rewardTokenFromAllTimeToDistribute.toString()),
      bignumber(rewardTokenTotalClaimedRewards.toString()),
      bignumber(rewardTokenTotalUnlockedTokens.toString()),
      bignumber(rewardTokensCurrentlyUnlocked.toString()),
      bignumber(rewardTokensCurrentlyToBeUnlocked.toString()),
    ];
  }

  public async getUserRewardInformationFor(
    account: string,
  ): Promise<[BN, BN, BN, BN, BN, BN, number]> {
    const [
      currentTimestamp,
      stakedTokenPenaltyFree,
      rewardAmountPenaltyFree,
      stakedTokenPenalty,
      rewardAmountAfterPenalty,
      rewardAmountLost,
      rewardPenaltyTimestamp,
    ]: [
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
    ] = await this.contract.getUserRewardInformationFor(account);

    return [
      bignumber(currentTimestamp.toString()),
      bignumber(stakedTokenPenaltyFree.toString()),
      bignumber(rewardAmountPenaltyFree.toString()),
      bignumber(stakedTokenPenalty.toString()),
      bignumber(rewardAmountAfterPenalty.toString()),
      bignumber(rewardAmountLost.toString()),
      rewardPenaltyTimestamp.toNumber(),
    ];
  }

  public async rewardToken(): Promise<string> {
    const address: string = await this.contract.rewardToken();
    return address;
  }

  public async simulateStake(
    account: string,
    amount: BigNumberish,
    time: number,
  ): Promise<[number, BN, number]> {
    const [currentTimestamp, reward, returnCode]: [
      BigNumber,
      BigNumber,
      BigNumber,
    ] = await this.contract.simulateStake(
      account,
      BigNumber.from(amount),
      BigNumber.from(time),
    );

    return [
      currentTimestamp.toNumber(),
      bignumber(reward.toString()),
      returnCode.toNumber(),
    ];
  }

  public async simulateUnstake(
    account: string,
    amount: BigNumberish,
  ): Promise<[number, BN, BN, number, number]> {
    const [currentTimestamp, reward, penalty, unlockTimestamp, returnCode]: [
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
    ] = await this.contract.simulateUnstake(
      account,
      BigNumber.from(amount).toHexString(),
    );

    return [
      currentTimestamp.toNumber(),
      bignumber(reward.toString()),
      bignumber(penalty.toString()),
      unlockTimestamp.toNumber(),
      returnCode.toNumber(),
    ];
  }

  public async stake(value: BigNumberish): Promise<ChainTransaction<any>> {
    const tx = await this.contract.stake(bignumber(value).toHex(), []);
    return tx;
  }

  public async stakingToken(): Promise<string> {
    const address: string = await this.contract.stakingToken();
    return address;
  }

  public async unstake(value: BigNumberish): Promise<ChainTransaction<any>> {
    const tx = await this.contract.unstake(bignumber(value).toHex(), []);
    return tx;
  }

  public on(eventType: EventType, listener: providers.Listener) {
    this.contract.on(eventType, listener);
  }

  public off(eventType: EventType, listener: providers.Listener) {
    this.contract.on(eventType, listener);
  }
}
