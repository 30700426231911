import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import Intro from '../../components/Intro';
import PillTab from '../../components/PillTab';
import PillTabs from '../../components/PillTabs';
import PoolChangeDialog from '../../components/PoolChangeDialog';
import PoolContent from '../../components/PoolContent';
import Screen from '../../components/Screen';
import Spinner from '../../components/Spinner';
import { usePools } from '../../hooks';
import { isSameAddress } from '../../utils';
import useStyles from './HomePage.styles';

export function HomePage(): React.ReactElement {
  const classNames = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data: pools, error: poolsError, loading: poolsLoading } = usePools();
  const [selectedPoolAddress, setSelectedPoolAddress] = useState<string>();
  const [poolChangeDialogOpen, setPoolChangeDialogOpen] = useState(false);

  const candidatePools = (pools || []).filter((pool) => {
    return !isSameAddress(pool.id, selectedPoolAddress || '');
  });

  const openPoolChangeDialog = useCallback(() => {
    setPoolChangeDialogOpen(true);
  }, []);

  const closePoolChangeDialog = useCallback(() => {
    setPoolChangeDialogOpen(false);
  }, []);

  const changeSelectedPool = useCallback((poolAddress: string) => {
    setSelectedPoolAddress(poolAddress);
  }, []);

  const changeSelectedPoolFromDialog = useCallback(
    (poolAddress: string) => {
      setSelectedPoolAddress(poolAddress);
      setPoolChangeDialogOpen(false);
      enqueueSnackbar('Pool was changed', { variant: 'info' });
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    if (!!pools && pools.length > 0) {
      setSelectedPoolAddress((currentSelectedPoolAddress) => {
        if (typeof currentSelectedPoolAddress === 'undefined') {
          return pools[0].id;
        }

        return currentSelectedPoolAddress;
      });
    }
  }, [pools]);

  return (
    <Screen>
      {poolsLoading && (
        <Box display="flex" justifyContent="center" p={3}>
          <Spinner />
        </Box>
      )}

      {!poolsLoading && !!poolsError && (
        <Box display="flex" justifyContent="center" p={3}>
          <Typography>Could not load pools.</Typography>
        </Box>
      )}

      {!poolsLoading && !poolsError && !selectedPoolAddress && (
        <Box display="flex" justifyContent="center" p={3}>
          <Typography>No pools available</Typography>
        </Box>
      )}

      {!poolsLoading && !poolsError && !!pools && !!selectedPoolAddress && (
        <div className={classNames.content}>
          <Intro pools={pools} />

          <div className={classNames.right}>
            <div className={classNames.poolTabsWrapper}>
              <div className={classNames.poolTabs}>
                <PillTabs
                  onChange={changeSelectedPool}
                  value={selectedPoolAddress}
                >
                  {pools.map((pool) => (
                    <PillTab key={pool.id} label={pool.name} value={pool.id} />
                  ))}
                </PillTabs>
              </div>
            </div>

            <PoolContent
              canChangePool={candidatePools.length > 0}
              onRequestChangePool={openPoolChangeDialog}
              poolAddress={selectedPoolAddress}
            />

            <PoolChangeDialog
              candidatePools={candidatePools}
              open={poolChangeDialogOpen}
              onClose={closePoolChangeDialog}
              onPoolChange={changeSelectedPoolFromDialog}
            />
          </div>
        </div>
      )}
    </Screen>
  );
}
