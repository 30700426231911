import MuiAccordionDetails, {
  AccordionDetailsProps,
} from '@material-ui/core/AccordionDetails';
import React from 'react';
import { mergeClasses } from '../../utils';
import useStyles from './AccordionDetails.styles';

export type Props = AccordionDetailsProps;

function AccordionDetails({
  children,
  classes,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <MuiAccordionDetails {...rest} classes={mergeClasses(classNames, classes)}>
      {children}
    </MuiAccordionDetails>
  );
}

export default AccordionDetails;
