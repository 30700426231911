import { Color } from './Color';
import createThemeVariant from './createThemeVariant';

const BACKGROUND_COLOR = Color.LIGHT_GREY;

const lightTheme = createThemeVariant({
  variant: 'light',
  backgroundColor: BACKGROUND_COLOR,
  assetTextField: {
    baseBackgroundColor: Color.WHITE,
  },
  palette: {
    text: {
      primary: Color.BLACK,
      secondary: Color.GRAY,
    },
  },
  pillTabs: {
    activeBackgroundColor: Color.WHITE,
    activeColor: Color.BLACK,
    backgroundColor: Color.LIGHT_GREY,
    color: Color.PURPLE,
    pillShadow: '4px 8px 35px #E7EAEC',
  },
  table: {
    bodyCellBackgroundColor: Color.WHITE,
    separatorColor: BACKGROUND_COLOR,
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: Color.WHITE,
      },
    },
    MuiButton: {
      containedSecondary: {
        backgroundColor: Color.WHITE,
        borderColor: Color.LIGHT_GREY,
        boxShadow: '4px 8px 35px #E7EAEC',

        '&:hover': {
          backgroundColor: Color.WHITE,
          boxShadow: '4px 8px 35px #E7EAEC',

          '@media(hover: none)': {
            backgroundColor: Color.WHITE,
          },
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: BACKGROUND_COLOR,
        },
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: Color.WHITE,
      },
    },
    MuiInputBase: {
      root: {
        color: Color.GRAY,
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: Color.LIGHT_GREY,
        },
        '&$focused $notchedOutline': {
          borderColor: Color.LIGHT_GREY,
        },
      },
      notchedOutline: {
        borderColor: Color.LIGHT_GREY,
      },
      focused: {},
    },
    MuiPaper: {
      root: {
        backgroundColor: Color.WHITE,
      },
    },
  },
});

export default lightTheme;
