import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import React, { useCallback, useState } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import useStyles from './InfoButton.styles';
import Box from '@material-ui/core/Box';
import CloseIcon from '../icons/CloseIcon';

type BaseProps = Omit<IconButtonProps, 'children' | 'onClick'>;

export type Props = BaseProps & {
  popoverHeading: React.ReactNode;
  popoverText: React.ReactNode;
};

function InfoButton({
  popoverHeading,
  popoverText,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <React.Fragment>
      <IconButton {...rest} onClick={handleClick}>
        <InfoIcon />
      </IconButton>

      <Popover
        anchorEl={anchorEl}
        classes={{ paper: classNames.popoverPaper }}
        elevation={0}
        open={anchorEl !== null}
        onClose={handleClose}
      >
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Typography className={classNames.heading} variant="h6">
            {popoverHeading}
          </Typography>

          <IconButton
            className={classNames.popoverCloseButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <div className={classNames.textWrapper}>
          <Typography className={classNames.text} variant="caption">
            {popoverText}
          </Typography>
        </div>
      </Popover>
    </React.Fragment>
  );
}

export default InfoButton;
