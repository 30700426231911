const paribusStakeABI = [
  {
    inputs: [
      {
        internalType: 'contract IERC20',
        name: '_stakingToken',
        type: 'address',
      },
      {
        internalType: 'contract IERC20',
        name: '_rewardToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_penaltyPeriod',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_penaltyNumber',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_maximumAmountOfStakedTokenAllowed',
        type: 'uint256',
      },
      {
        internalType: 'string',
        name: '_poolName',
        type: 'string',
      },
      {
        internalType: 'uint256',
        name: '_minimumAmountOfStakedTokenPossible',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_maximumAmountOfStakedTokenPossible',
        type: 'uint256',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'shareId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'claimed',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'sumOfClaimed',
        type: 'uint256',
      },
    ],
    name: 'ClaimedTokensFromShare',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'stakingToken',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'rewardToken',
        type: 'uint256',
      },
    ],
    name: 'EmergencyWithdrawn',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'Paused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'int256',
        name: 'delta',
        type: 'int256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'rewardPerSecond',
        type: 'uint256',
      },
    ],
    name: 'RewardPoolTimeSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountAdded',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'rewardPerSecond',
        type: 'uint256',
      },
    ],
    name: 'RewardPoolTokensTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'shareHolder',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'share',
        type: 'uint256',
      },
    ],
    name: 'ShareCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'shareHolder',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'shareId',
        type: 'uint256',
      },
    ],
    name: 'ShareDeleted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'shareHolder',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'newShareId',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'oldShareId',
        type: 'uint256',
      },
    ],
    name: 'ShareMoved',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'shareHolder',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'shareId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'shareChange',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'shareAbsolute',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'sumOfClaimed',
        type: 'uint256',
      },
    ],
    name: 'ShareUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'total',
        type: 'uint256',
      },
    ],
    name: 'Staked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'rewardPerSecond',
        type: 'uint256',
      },
    ],
    name: 'UnlockedTokensChangedRewardPerSecond',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'Unpaused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'total',
        type: 'uint256',
      },
    ],
    name: 'Unstaked',
    type: 'event',
  },
  {
    inputs: [],
    name: 'claimUnlocked',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'emergencyWithdrawn',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getPoolInfo',
    outputs: [
      {
        internalType: 'string',
        name: 'name',
        type: 'string',
      },
      {
        internalType: 'uint256',
        name: 'penaltyPercent',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'penaltyLockupPeriod',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'maximumStakedTokenAllowed',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'unlockedAt',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'minAmountOfStakedTokensRequired',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'maxAmountOfStakedTokensRequired',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getPoolState',
    outputs: [
      {
        internalType: 'uint256',
        name: 'stakedTokenCurrentAmount',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'updateLastTime',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'updateUnlocksPerSecond',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'rewardTokenFromAllTimeToDistribute',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'rewardTokenTotalClaimedRewards',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'rewardTokenTotalUnlockedTokens',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'rewardTokensCurrentlyUnlocked',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'rewardTokensCurrentlyToBeUnlocked',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address',
      },
    ],
    name: 'getUserRewardInformationFor',
    outputs: [
      {
        internalType: 'uint256',
        name: 'currentTimestamp',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'stakedTokenPenaltyFree',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'rewardAmountPenaltyFree',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'stakedTokenPenalty',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'rewardAmountAfterPenalty',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'rewardAmountLost',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'rewardPenaltyTimestamp',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'paused',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_endTimestamp',
        type: 'uint256',
      },
    ],
    name: 'rewardChangeEndTime',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'rewardToken',
    outputs: [
      {
        internalType: 'contract IERC20',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_endTimestamp',
        type: 'uint256',
      },
    ],
    name: 'rewardTransferAndChange',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256',
      },
    ],
    name: 'rewardTransferIntoPool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_sender',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_time',
        type: 'uint256',
      },
    ],
    name: 'simulateStake',
    outputs: [
      {
        internalType: 'uint256',
        name: 'currentTimestamp',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'rewardFull',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'returnCode',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_sender',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256',
      },
    ],
    name: 'simulateUnstake',
    outputs: [
      {
        internalType: 'uint256',
        name: 'currentTimestamp',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'reward',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'penalty',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'unlockTimestamp',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'returnCode',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256',
      },
    ],
    name: 'stake',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'stakingToken',
    outputs: [
      {
        internalType: 'contract IERC20',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'supportsHistory',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [],
    name: 'token',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalStaked',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address',
      },
    ],
    name: 'totalStakedFor',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'unlockTokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'unpause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256',
      },
    ],
    name: 'unstake',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export default paribusStakeABI;
