import Typography, { TypographyProps } from '@material-ui/core/Typography';
import React from 'react';
import { mergeClasses } from '../../utils';
import useStyles from './DialogTitle.styles';

export type Props = TypographyProps;

function DialogTitle({
  children,
  classes,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <Typography
      align="center"
      variant="h4"
      {...rest}
      classes={mergeClasses(classNames, classes)}
    >
      {children}
    </Typography>
  );
}

export default DialogTitle;
