import Typography, { TypographyProps } from '@material-ui/core/Typography';
import React from 'react';
import { mergeClasses } from '../../utils';
import useStyles from './LoadingBoxTitle.styles';

export type Props = TypographyProps;

function LoadingBoxTitle({
  children,
  classes,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <Typography
      variant="h5"
      {...rest}
      classes={mergeClasses(classNames, classes)}
    >
      {children}
    </Typography>
  );
}

export default LoadingBoxTitle;
