import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { useUnstake } from '../../hooks';
import { bignumber, BigNumber } from '../../math';
import { Pool } from '../../types';
import BigNumberFormat from '../BigNumberFormat';
import Button from '../Button';
import NumericTextField from '../NumericTextField';
import UnstakeTransactionDialog from '../UnstakeTransactionDialog';
import useStyles from './WithdrawForm.styles';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

export type Props = BaseProps & {
  pool: Pool;
  unlocked: BigNumber;
  total: BigNumber;
};

function WithdrawForm({
  pool,
  unlocked,
  total,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();
  const [amount, setAmount] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  const [unstake, { loading: unstakeInProgress, status: unstakeStatus }] =
    useUnstake(pool.id);

  const disabled =
    unstakeInProgress || amount === '' || parseFloat(amount) <= 0;

  const withdrawable = total.sub(pool.minStakingTokenLimit).clamp(0, unlocked);

  const handleAmountChange = useCallback((value: string) => {
    setAmount(value);
  }, []);

  const handleMaxAmountButtonClick = useCallback(() => {
    setAmount(withdrawable.toString());
  }, [withdrawable]);

  const handleWithdrawButtonClick = useCallback(() => {
    unstake(amount).then(({ error }) => {
      if (error) {
        enqueueSnackbar('An error occured. Please try again later.', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Withdrawal successfull!', { variant: 'success' });
        setAmount('');
      }
    });
  }, [amount, enqueueSnackbar, unstake]);

  const amountUSD = useMemo(() => {
    if (pool.stakingTokenPriceUSD === null) {
      return null;
    }

    return bignumber(amount === '' ? '0' : amount).mul(
      pool.stakingTokenPriceUSD,
    );
  }, [amount, pool.stakingTokenPriceUSD]);

  return (
    <div {...rest}>
      <Typography
        className={classNames.text}
        color="textSecondary"
        variant="caption"
      >
        The amount to be withdrawn (without losing any rewards) is influenced by
        the deposited amount, the unlocked funds and the minimum limit.
      </Typography>

      <div className={classNames.topBar}>
        <div className={classNames.topBarItem}>
          <Typography
            className={classNames.topBarLabel}
            color="textSecondary"
            component="p"
            variant="overline"
          >
            Deposited
          </Typography>

          <Typography variant="caption">
            <BigNumberFormat
              compact
              decimalScale={3}
              suffix={' ' + pool.stakingTokenSymbol}
              value={total}
            />
          </Typography>
        </div>

        <div className={classNames.topBarItem}>
          <Typography
            className={classNames.topBarLabel}
            color="textSecondary"
            component="p"
            variant="overline"
          >
            Unlocked
          </Typography>

          <Typography variant="caption">
            <BigNumberFormat
              compact
              decimalScale={3}
              suffix={' ' + pool.stakingTokenSymbol}
              value={unlocked}
            />
          </Typography>
        </div>

        <div className={classNames.topBarItem}>
          <Typography
            className={classNames.topBarLabel}
            color="textSecondary"
            component="p"
            variant="overline"
          >
            Max. withdraw amount
          </Typography>

          <Typography variant="caption">
            <BigNumberFormat
              compact
              decimalScale={3}
              suffix={' ' + pool.stakingTokenSymbol}
              value={withdrawable}
            />
          </Typography>
        </div>
      </div>

      <Typography
        className={classNames.heading}
        color="textSecondary"
        variant="h6"
      >
        Withdraw Amount
      </Typography>

      <div className={classNames.wrapper}>
        <div className={classNames.textFieldWrapper}>
          <NumericTextField
            className={classNames.textField}
            fullWidth
            max={withdrawable}
            maxDecimalPlaces={pool.stakingTokenDecimals}
            onChange={handleAmountChange}
            value={amount}
            variant="outlined"
            InputProps={{
              classes: {
                root: classNames.inputRoot,
                input: classNames.inputInput,
              },
              endAdornment: (
                <InputAdornment
                  className={classNames.maxButton}
                  onClick={handleMaxAmountButtonClick}
                  position="end"
                  role="button"
                >
                  <Typography color="primary" variant="body2">
                    Max.
                  </Typography>
                </InputAdornment>
              ),
              placeholder: 'Enter withdraw amount...',
            }}
          />

          {amountUSD !== null && (
            <Typography
              className={classNames.usdValue}
              color="textSecondary"
              variant="caption"
            >
              =
              <BigNumberFormat
                compact
                prefix="$"
                thousandSeparator
                value={amountUSD}
              />
            </Typography>
          )}
        </div>

        <Button
          className={classNames.button}
          color="primary"
          disabled={disabled}
          onClick={handleWithdrawButtonClick}
          rounded
          size="large"
          variant="contained"
        >
          Withdraw
        </Button>
      </div>

      <UnstakeTransactionDialog
        open={unstakeInProgress}
        status={unstakeStatus}
      />
    </div>
  );
}

export default WithdrawForm;
