import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',

    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
}));

export default useStyles;
