import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginLeft: 12,
  },
  popoverPaper: {
    boxShadow: `4px 8px 35px ${
      theme.variant === 'light' ? '#E7EAEC' : '#16172B'
    }`,
    maxWidth: 300,
    padding: '4px 4px 16px',
  },
  popoverCloseButton: {
    color: theme.variant === 'light' ? Color.GRAY : Color.DARK4,

    '& svg': {
      fontSize: 16,
    },
  },
  textWrapper: {
    margin: `0 12px`,
  },
  text: {
    color:
      theme.palette.text[theme.variant === 'light' ? 'secondary' : 'primary'],
  },
}));

export default useStyles;
