import { BigNumber, Contract, providers, Signer } from 'ethers';
import { abi } from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json';

export class UniswapV3Pool {
  protected contract: Contract;

  constructor(address: string, providerOrSigner: providers.Provider | Signer) {
    this.contract = new Contract(address, abi, providerOrSigner);
  }

  public async liquidity(): Promise<BigNumber> {
    const liquidity = await this.contract.liquidity();
    return liquidity;
  }

  public async slot0(): Promise<
    [BigNumber, number, number, number, number, number, boolean]
  > {
    const [
      sqrtPriceX96,
      tick,
      observationIndex,
      observationCardinality,
      observationCardinalityNext,
      feeProtocol,
      unlocked,
    ]: [BigNumber, number, number, number, number, number, boolean] =
      await this.contract.slot0();

    return [
      sqrtPriceX96,
      tick,
      observationIndex,
      observationCardinality,
      observationCardinalityNext,
      feeProtocol,
      unlocked,
    ];
  }
}
