import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import { useUnstake } from '../../hooks';
import { adjustDecimals } from '../../math';
import { AccountPoolStats, Pool } from '../../types';
import BigNumberFormat from '../BigNumberFormat';
import Button from '../Button';
import Message from '../Message';
import UnstakeTransactionDialog from '../UnstakeTransactionDialog';
import useStyles from './LeavePoolForm.styles';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

export type Props = BaseProps & {
  accountPoolStats: AccountPoolStats;
  pool: Pool;
};

function LeavePoolForm({
  accountPoolStats,
  pool,
  ...rest
}: Props): React.ReactElement {
  const theme = useTheme();
  const classNames = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [unstake, { loading: leaveInProgress, status: leaveStatus }] =
    useUnstake(pool.id);
  const hasLoss = accountPoolStats.potentialRewardLoss.comparedTo(0) > 0;

  const handleWithdrawButtonClick = useCallback(() => {
    unstake(
      adjustDecimals(accountPoolStats.withdrawable, pool.stakingTokenDecimals),
    ).then(({ error }) => {
      if (error) {
        enqueueSnackbar(
          'An error occured when claiming rewards. Please try again later.',
          { variant: 'error' },
        );
      } else {
        enqueueSnackbar('Success!', { variant: 'success' });
      }
    });
  }, [
    accountPoolStats.withdrawable,
    enqueueSnackbar,
    pool.stakingTokenDecimals,
    unstake,
  ]);

  const total = useMemo(() => {
    if (
      pool.stakingTokenAddress.toLowerCase() !==
      pool.rewardTokenAddress.toLowerCase()
    ) {
      return null;
    }

    return adjustDecimals(
      accountPoolStats.withdrawable
        .add(accountPoolStats.estimatedAccrued)
        .sub(accountPoolStats.potentialRewardLoss),
      pool.stakingTokenDecimals,
    );
  }, [
    accountPoolStats.estimatedAccrued,
    accountPoolStats.potentialRewardLoss,
    accountPoolStats.withdrawable,
    pool.rewardTokenAddress,
    pool.stakingTokenAddress,
    pool.stakingTokenDecimals,
  ]);

  return (
    <div {...rest}>
      {hasLoss && (
        <Message className={classNames.alert} variant="warning">
          By early withdrawal you will lose{' '}
          <BigNumberFormat
            compact
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator
            suffix={' ' + pool.rewardTokenSymbol}
            value={adjustDecimals(
              accountPoolStats.potentialRewardLoss,
              pool.rewardTokenDecimals,
            )}
          />
          . Avoid it by waiting{' '}
          {dayjs
            .utc(accountPoolStats.penaltyExpirationDate * 1000)
            .fromNow(true)}
          .
        </Message>
      )}

      <div className={classNames.wrapper}>
        <table className={classNames.table}>
          <tbody>
            {/* staked row */}
            <tr>
              <td>
                <Typography
                  className={classNames.label}
                  color="inherit"
                  variant="h6"
                >
                  Staked tokens:
                </Typography>
              </td>
              <td>
                <div className={classNames.valuesWrapper}>
                  <Typography
                    color={
                      theme.variant === 'light'
                        ? 'textSecondary'
                        : 'textPrimary'
                    }
                    variant="h6"
                  >
                    <BigNumberFormat
                      compact
                      decimalScale={2}
                      thousandSeparator
                      suffix={' ' + pool.stakingTokenSymbol}
                      value={adjustDecimals(
                        accountPoolStats.withdrawable,
                        pool.stakingTokenDecimals,
                      )}
                    />
                  </Typography>

                  {pool.stakingTokenPriceUSD !== null && (
                    <Typography color="inherit" variant="caption">
                      <BigNumberFormat
                        className={classNames.usdValue}
                        compact
                        decimalScale={2}
                        thousandSeparator
                        prefix="$"
                        value={adjustDecimals(
                          accountPoolStats.withdrawable.mul(
                            pool.stakingTokenPriceUSD,
                          ),
                          pool.stakingTokenDecimals,
                        )}
                      />
                    </Typography>
                  )}
                </div>
              </td>
            </tr>
            {/* staked row end */}

            {/* reward row */}
            <tr className={classNames.rewardsRow}>
              <td>
                <Typography
                  className={classNames.label}
                  color="inherit"
                  variant="h6"
                >
                  Rewards:
                </Typography>
              </td>
              <td>
                <div className={classNames.valuesWrapper}>
                  <Typography color="inherit" variant="h6">
                    <BigNumberFormat
                      compact
                      decimalScale={2}
                      thousandSeparator
                      suffix={' ' + pool.rewardTokenSymbol}
                      value={adjustDecimals(
                        accountPoolStats.estimatedAccrued,
                        pool.rewardTokenDecimals,
                      )}
                    />
                  </Typography>

                  {pool.rewardTokenPriceUSD !== null && (
                    <Typography color="inherit" variant="caption">
                      <BigNumberFormat
                        className={classNames.usdValue}
                        compact
                        decimalScale={2}
                        thousandSeparator
                        prefix="$"
                        value={adjustDecimals(
                          accountPoolStats.estimatedAccrued.mul(
                            pool.rewardTokenPriceUSD,
                          ),
                          pool.rewardTokenDecimals,
                        )}
                      />
                    </Typography>
                  )}
                </div>
              </td>
            </tr>
            {/* reward row end */}

            {/* loss row */}
            {hasLoss && (
              <tr className={classNames.lossRow}>
                <td>
                  <Typography
                    className={classNames.label}
                    color="inherit"
                    variant="h6"
                  >
                    Loss:
                  </Typography>
                </td>
                <td>
                  <div className={classNames.valuesWrapper}>
                    <Typography color="inherit" variant="h6">
                      <BigNumberFormat
                        compact
                        decimalScale={2}
                        prefix="-"
                        suffix={' ' + pool.rewardTokenSymbol}
                        thousandSeparator
                        value={adjustDecimals(
                          accountPoolStats.potentialRewardLoss,
                          pool.rewardTokenDecimals,
                        )}
                      />
                    </Typography>

                    {pool.rewardTokenPriceUSD !== null && (
                      <Typography color="inherit" variant="caption">
                        <BigNumberFormat
                          className={classNames.usdValue}
                          compact
                          decimalScale={2}
                          thousandSeparator
                          prefix="$"
                          value={adjustDecimals(
                            accountPoolStats.potentialRewardLoss.mul(
                              pool.rewardTokenPriceUSD,
                            ),
                            pool.rewardTokenDecimals,
                          )}
                        />
                      </Typography>
                    )}
                  </div>
                </td>
              </tr>
            )}
            {/* loss row end */}
          </tbody>

          {total !== null && (
            <tfoot>
              <tr>
                <td>
                  <Typography
                    className={classNames.label}
                    color="inherit"
                    variant="h6"
                  >
                    Final:
                  </Typography>
                </td>

                <td>
                  <div className={classNames.valuesWrapper}>
                    <Typography
                      color={
                        theme.variant === 'light'
                          ? 'textSecondary'
                          : 'textPrimary'
                      }
                      variant="h5"
                    >
                      <BigNumberFormat
                        compact
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={' ' + pool.stakingTokenSymbol}
                        thousandSeparator
                        value={total}
                      />
                    </Typography>

                    {pool.stakingTokenPriceUSD !== null && (
                      <Typography
                        className={classNames.usdValue}
                        variant="caption"
                      >
                        <BigNumberFormat
                          compact
                          decimalScale={2}
                          fixedDecimalScale
                          prefix="$"
                          thousandSeparator
                          value={total.mul(pool.stakingTokenPriceUSD)}
                        />
                      </Typography>
                    )}
                  </div>
                </td>
              </tr>
            </tfoot>
          )}
        </table>

        <Button
          color="primary"
          disabled={leaveInProgress}
          onClick={handleWithdrawButtonClick}
          rounded
          size="large"
          variant="contained"
        >
          Withdraw
        </Button>
      </div>

      <UnstakeTransactionDialog open={leaveInProgress} status={leaveStatus} />
    </div>
  );
}

export default LeavePoolForm;
