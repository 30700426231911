import { providers } from 'ethers';
import { ChainError } from '.';
import {
  adjustDecimals,
  bignumber,
  BigNumber,
  BigNumberish,
  exponentToBigNumber,
} from '../math';
import { ParibusStake } from './contracts/ParibusStake';
import { getPoolStakingTokenAddress } from './getPoolStakingToken';
import { getTokenDecimals } from './getTokenDecimals';

interface Options {
  account: string;
  amount: BigNumberish;
  poolAddress: string;
  time: number;
}

export async function simulateStake(
  provider: providers.Provider,
  { account, amount, poolAddress, time }: Options,
): Promise<BigNumber> {
  try {
    const pool = new ParibusStake(poolAddress, provider);
    const stakingTokenAddress = await getPoolStakingTokenAddress(
      provider,
      poolAddress,
    );
    const decimals = await getTokenDecimals(provider, stakingTokenAddress);
    const adjustedAmount = bignumber(amount).mul(exponentToBigNumber(decimals));
    const [, reward] = await pool.simulateStake(
      account,
      adjustedAmount.toHex(),
      time,
    );
    return adjustDecimals(reward, decimals);
  } catch (error) {
    throw ChainError.fromError(error);
  }
}
