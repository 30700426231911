import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    borderRadius: 8,
    display: 'flex',
    color:
      theme.palette.text[theme.variant === 'light' ? 'secondary' : 'primary'],
    cursor: 'pointer',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  active: {
    backgroundColor: theme.variant === 'light' ? Color.LIGHT_GREY : Color.DARK3,
    color: theme.pillTabs.activeColor,
    position: 'relative',
    zIndex: 1,

    '& > span': {
      fontWeight: 600,
    },
  },
  iconWrapper: {
    color: theme.palette.primary.main,
    fontSize: 24,
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;
