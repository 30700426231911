import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  regular: {
    height: 67,

    [theme.breakpoints.up('lg')]: {
      height: 88,
    },
  },
}));

export default useStyles;
