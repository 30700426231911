import React from 'react';
import { TransactionStatus } from '../../types';
import { showTransactionStatusMessage } from '../../utils';
import DialogTitle from '../DialogTitle';
import LoadingBox from '../LoadingBox';
import LoadingBoxText from '../LoadingBoxText';
import LoadingBoxTitle from '../LoadingBoxTitle/LoadingBoxTitle';
import TransactionDialog, {
  TransactionDialogProps,
} from '../TransactionDialog';

type BaseProps = Omit<TransactionDialogProps, 'children'>;

export type Props = BaseProps & {
  status: TransactionStatus;
};

function StakeTransactionDialog({
  status,
  ...rest
}: Props): React.ReactElement {
  return (
    <TransactionDialog {...rest}>
      <DialogTitle>Withdraw</DialogTitle>

      <LoadingBox>
        <LoadingBoxTitle>Withdraw in progress</LoadingBoxTitle>
        <LoadingBoxText>{showTransactionStatusMessage(status)}</LoadingBoxText>
      </LoadingBox>
    </TransactionDialog>
  );
}

export default StakeTransactionDialog;
