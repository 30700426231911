import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
  },
  secondary: {
    color: theme.variant === 'light' ? Color.GREY : Color.DARK4,
  },
}));

export default useStyles;
