import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
  },
  linkColumn: {
    textAlign: 'right',
  },
  link: {
    cursor: 'pointer',
  },
}));

export default useStyles;
