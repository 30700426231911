import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 600,
    minHeight: '100%',
    margin: '0 auto',
  },
  heading: {
    marginBottom: theme.spacing(4),
  },
  networkBox: {
    alignItems: 'center',
    backgroundColor: theme.variant === 'light' ? Color.WHITE : Color.DARK1,
    border: `2px solid ${
      theme.variant === 'light' ? Color.LIGHT_GREY : Color.DARK3
    }`,
    borderRadius: 8,
    display: 'flex',
    margin: `${theme.spacing(3)}px auto 0`,
    maxWidth: 400,
    justifyContent: 'space-between',
  },
  networkBoxContent: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  networkName: {
    marginBottom: theme.spacing(0.5),
  },
  networkSwitchButton: {
    marginRight: 12,
  },
}));

export default useStyles;
