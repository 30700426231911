import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => ({
  paper: {
    background: `linear-gradient(41.06deg, ${theme.palette.primary.main} 26.5%, ${theme.palette.primary.light} 54.5%)`,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    padding: '32px 32px 22px 32px',
  },
  topBar: {
    border: `2px solid ${
      theme.variant === 'light' ? Color.LIGHT_BLUE : Color.DARK3
    }`,
    borderRadius: 20,
    marginBottom: theme.spacing(2),
    padding: `12px 16px`,

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
  topBarItem: {
    alignItems: 'center',
    display: 'flex',
    margin: 8,
  },
  topBarIcon: {
    marginRight: theme.spacing(1),
  },
  heading: {
    marginBottom: theme.spacing(4),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  input: {
    backgroundColor: Color.WHITE,
    color: Color.GRAY,

    '&:hover $inputOutline': {
      borderColor: Color.LIGHT_GREY,
    },

    '&$inputFocused $inputOutline': {
      borderColor: Color.LIGHT_GREY,
    },
  },
  inputError: {
    color: theme.palette.error.main,
  },
  inputFocused: {},
  inputOutline: {
    borderColor: Color.LIGHT_GREY,
  },
  maxButton: {
    cursor: 'pointer',
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  button: {
    backgroundColor: Color.WHITE,
    borderColor: Color.LIGHT_GREY,
    color: Color.GRAY,

    '&:hover': {
      backgroundColor: Color.WHITE,
    },
  },
  summary: {
    height: '100%',
  },
  poolChangeLink: {
    cursor: 'pointer',
    fontWeight: 700,
    textDecoration: 'underline',
  },
}));

export default useStyles;
