import { providers } from 'ethers';
import { memoizeAsync } from '../utils';
import { ERC20 } from './contracts/ERC20';

async function getTokenDecimalsUncached(
  provider: providers.Provider,
  address: string,
): Promise<number> {
  const token = new ERC20(address, provider);
  const decimals = await token.decimals();
  return decimals;
}

// memoize token decimals because they don't change
export const getTokenDecimals = memoizeAsync(
  getTokenDecimalsUncached,
  (_, address) => address,
);
