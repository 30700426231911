import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { ReactComponent as LockedRewardsIcon } from '../../assets/images/locked-rewards.svg';
import useStyles from './PoolClosedInfo.styles';

export type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

function PoolClosedInfo({
  className,
  ...rest
}: Props): React.ReactElement<HTMLDivElement> {
  const classNames = useStyles();

  return (
    <div {...rest} className={clsx(classNames.root, className)}>
      <LockedRewardsIcon className={classNames.icon} />
      <Typography className={classNames.title} variant="h4">
        The pool is closed
      </Typography>
      <Typography color="textSecondary" variant="body1">
        It seems that the pool is closed and there are no more tokens in the
        pool to unlock.
      </Typography>
    </div>
  );
}

export default PoolClosedInfo;
