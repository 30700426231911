import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import Accordion, { AccordionProps } from '../Accordion';
import AccordionDetails from '../AccordionDetails';
import AccordionSummary from '../AccordionSummary';

type BaseProps = Omit<AccordionProps, 'children'>;

export type Props = BaseProps & {
  answerText: React.ReactNode;
  questionText: React.ReactNode;
};

function FAQQuestion({
  answerText,
  questionText,
  ...rest
}: Props): React.ReactElement {
  return (
    <Accordion {...rest} component="div">
      <AccordionSummary expandIcon={<ArrowDropDownIcon color="primary" />}>
        <Typography variant="subtitle2">{questionText}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Typography variant="subtitle2">{answerText}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default FAQQuestion;
