import { makeStyles } from '@material-ui/core/styles';
import backgroundUrl from '../../assets/images/intro-bg.png';

const SIZE = 575;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '40px 0',

    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${backgroundUrl})`,
      backgroundPosition: 'center center',
      backgroundSize: 'contain',
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      minHeight: SIZE,
      justifyContent: 'center',
      width: SIZE,
    },
  },
  content: {
    color:
      theme.palette.text[theme.variant === 'light' ? 'secondary' : 'primary'],

    padding: `0px 16px`,
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      padding: `0 36px`,
    },

    [theme.breakpoints.up('xl')]: {
      textAlign: 'left',
    },
  },
  heading: {
    marginBottom: 16,
  },
  poolsList: {
    display: 'inline-flex',
    flexDirection: 'column',
    margin: `${theme.spacing(4)}px 0 0 0`,
    padding: 0,

    '& > li': {
      listStyle: 'none',
      paddingLeft: 22,
      position: 'relative',

      '&::before, &::after': {
        content: "' '",
        display: 'block',
        left: 0,
        position: 'absolute',
        top: 0,
      },

      '&::before': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 6,
        height: 12,
        marginTop: 9,
        opacity: 0.12,
        width: 12,
      },

      '&::after': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 3,
        height: 6,
        marginLeft: 3,
        marginTop: 12,
        width: 6,
      },
    },
  },
}));

export default useStyles;
