import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export function FAQIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0)" fill="none">
        <path
          d="M21 5C21 4.44772 20.5523 4 20 4H4C3.44772 4 3 4.44772 3 5V16C3 16.5523 3.44771 17 4 17H5.07642C5.9554 17 6.79016 17.3855 7.36015 18.0546L10.4256 21.6531C10.9855 22.3105 12.0294 21.6533 11.6787 20.8642C10.871 19.0469 12.2012 17 14.1899 17H20C20.5523 17 21 16.5523 21 16V5Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          opacity="0.1"
          d="M24 9C24 7.89543 23.1046 7 22 7H8C6.89543 7 6 7.89543 6 9V18.1579C6 19.2625 6.89543 20.1579 8 20.1579H8.69178C9.2689 20.1579 9.81789 20.4072 10.1977 20.8417L12.9719 24.0159C14.1481 25.3617 16.2921 23.9782 15.5505 22.352C15.0823 21.3251 15.8329 20.1579 16.9615 20.1579H22C23.1046 20.1579 24 19.2625 24 18.1579V9Z"
          fill="currentColor"
        />
        <path
          d="M10.9519 11.444C10.9519 11.1213 11.0172 10.8566 11.148 10.6501C11.2787 10.4371 11.4688 10.2047 11.7184 9.95297C11.8907 9.7787 12.0184 9.63347 12.1016 9.51729C12.1848 9.39465 12.2264 9.26556 12.2264 9.13001C12.2264 8.94283 12.1521 8.79438 12.0036 8.68465C11.861 8.56846 11.6738 8.51037 11.4421 8.51037C11.2044 8.51037 10.9875 8.57169 10.7914 8.69433C10.6013 8.81697 10.4468 8.98801 10.328 9.20747L9 8.43292C9.24361 7.9811 9.58824 7.62932 10.0339 7.37759C10.4795 7.12586 11.0172 7 11.6471 7C12.3541 7 12.9216 7.16136 13.3494 7.48409C13.7831 7.80037 14 8.24574 14 8.82019C14 9.08483 13.9584 9.32042 13.8752 9.52697C13.792 9.72706 13.691 9.89811 13.5722 10.0401C13.4593 10.1757 13.3108 10.3306 13.1266 10.5048C12.9245 10.692 12.776 10.8534 12.6809 10.9889C12.5859 11.118 12.5383 11.2697 12.5383 11.444H10.9519ZM11.7451 14C11.454 14 11.2133 13.9032 11.0232 13.7095C10.833 13.5095 10.738 13.2674 10.738 12.9834C10.738 12.6929 10.8301 12.4541 11.0143 12.2669C11.2044 12.0798 11.448 11.9862 11.7451 11.9862C12.0422 11.9862 12.2858 12.0798 12.4759 12.2669C12.6661 12.4541 12.7611 12.6929 12.7611 12.9834C12.7611 13.2674 12.6661 13.5095 12.4759 13.7095C12.2858 13.9032 12.0422 14 11.7451 14Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
