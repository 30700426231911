import { FACTORY_ADDRESS } from '@uniswap/v3-sdk';
import { BigNumber, BigNumberish, Contract, providers, Signer } from 'ethers';
import { abi } from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Factory.sol/IUniswapV3Factory.json';

export class UniswapV3Factory {
  protected contract: Contract;

  constructor(providerOrSigner: providers.Provider | Signer) {
    this.contract = new Contract(FACTORY_ADDRESS, abi, providerOrSigner);
  }

  public async getPool(
    tokenA: string,
    tokenB: string,
    fee: BigNumberish,
  ): Promise<string | null> {
    const poolAddress: string = await this.contract.getPool(
      tokenA,
      tokenB,
      BigNumber.from(fee),
    );

    return BigNumber.from(poolAddress).eq(0) ? null : poolAddress;
  }
}
