import clsx from 'clsx';
import React, { createContext } from 'react';
import useStyles from './HighlightTabs.styles';

export interface HighlightTabsContextShape {
  onChange?(nextValue: string): void;
  value: string;
}

export const HighlightTabsContext = createContext<HighlightTabsContextShape>({
  value: '',
});

type BaseProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'onChange' | 'value'
>;

export interface Props extends BaseProps {
  value: string;
  onChange?(nextValue: string): void;
}

function HighlightTabs({
  children,
  className,
  value,
  onChange,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <HighlightTabsContext.Provider
      value={{
        onChange,
        value,
      }}
    >
      <div {...rest} className={clsx(classNames.root, className)}>
        {children}
      </div>
    </HighlightTabsContext.Provider>
  );
}

export default HighlightTabs;
