import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { ReactComponent as ClaimedRewardsIcon } from '../../assets/images/claimed-rewards.svg';
import { ReactComponent as LockedRewardsIcon } from '../../assets/images/locked-rewards.svg';
import { ReactComponent as ProgramDurationIcon } from '../../assets/images/program-duration.svg';
import { ReactComponent as RewardsUnlockRateIcon } from '../../assets/images/rewards-unlock-rate.svg';
import { ReactComponent as TotalAccruedRewardsIcon } from '../../assets/images/total-accrued-rewards.svg';
import { ReactComponent as TotalRewardsIcon } from '../../assets/images/total-rewards.svg';
import { useMediaQuery } from '../../hooks';
import { Pool } from '../../types';
import { clamp } from '../../utils';
import BigNumberFormat from '../BigNumberFormat';
import StatCard from '../StatCard';
import StatCardText from '../StatCardText';
import useStyles from './PoolStats.styles';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

export type Props = BaseProps & {
  pool: Pool;
};

function PoolStats({ pool, ...rest }: Props): React.ReactElement {
  const classNames = useStyles();
  const { breakpoints } = useTheme();
  const largeSpacing = useMediaQuery(breakpoints.up('md'));

  const timeLeftString = useMemo(() => {
    const endDate = dayjs.utc(pool.fullyUnlockedAt).local();
    const daysDiff = clamp(endDate.diff(dayjs(), 'days'), 0);
    const suffix = daysDiff === 1 ? 'day' : 'days';
    return `${daysDiff.toFixed(0)} ${suffix}`;
  }, [pool.fullyUnlockedAt]);

  return (
    <Grid {...rest} container spacing={largeSpacing ? 4 : 3}>
      <Grid item xs={12} md={4}>
        <StatCard
          info={{
            heading: 'Total Rewards',
            text: `The total amount of ${pool.rewardTokenSymbol} rewards for the program, including claimed amount.`,
          }}
        >
          <TotalRewardsIcon className={classNames.icon} />
          <StatCardText variant="primary">
            <BigNumberFormat
              compact
              decimalScale={3}
              suffix={' ' + pool.rewardTokenSymbol}
              thousandSeparator
              value={pool.totalRewards}
            />
          </StatCardText>
          <StatCardText variant="secondary">Total Rewards</StatCardText>
        </StatCard>
      </Grid>

      <Grid item xs={12} md={4}>
        <StatCard
          info={{
            heading: 'Locked Rewards',
            text: `The total amount of rewards not yet distributed to participants.`,
          }}
        >
          <LockedRewardsIcon className={classNames.icon} />
          <StatCardText variant="primary">
            <BigNumberFormat
              compact
              decimalScale={3}
              suffix={' ' + pool.rewardTokenSymbol}
              thousandSeparator
              value={pool.lockedRewards}
            />
          </StatCardText>
          <StatCardText variant="secondary">Locked Rewards</StatCardText>
        </StatCard>
      </Grid>

      <Grid item xs={12} md={4}>
        <StatCard>
          <RewardsUnlockRateIcon className={classNames.icon} />
          <StatCardText variant="primary">
            <BigNumberFormat
              compact
              decimalScale={3}
              suffix={` ${pool.rewardTokenSymbol} / day`}
              thousandSeparator
              value={pool.rewardsPerSecond.mul(60 * 60 * 24)}
            />
          </StatCardText>
          <StatCardText variant="secondary">Reward Unlock Rate</StatCardText>
        </StatCard>
      </Grid>

      <Grid item xs={12} md={4}>
        <StatCard
          info={{
            heading: 'Claimed Rewards',
            text: `The total amount of rewards claimed (withdrawn) by all participants.`,
          }}
        >
          <ClaimedRewardsIcon className={classNames.icon} />
          <StatCardText variant="primary">
            <BigNumberFormat
              compact
              decimalScale={3}
              suffix={' ' + pool.rewardTokenSymbol}
              thousandSeparator
              value={pool.totalClaimedRewards}
            />
          </StatCardText>
          <StatCardText variant="secondary">Claimed Rewards</StatCardText>
        </StatCard>
      </Grid>

      <Grid item xs={12} md={4}>
        <StatCard>
          <ProgramDurationIcon className={classNames.icon} />
          <StatCardText variant="primary">{timeLeftString}</StatCardText>
          <StatCardText variant="secondary">Program Duration</StatCardText>
        </StatCard>
      </Grid>

      <Grid item xs={12} md={4}>
        <StatCard
          info={{
            heading: 'Total Accrued Rewards',
            text: `The total amount of rewards distributed but not yet withdrawn by participants.`,
          }}
        >
          <TotalAccruedRewardsIcon className={classNames.icon} />
          <StatCardText variant="primary">
            <BigNumberFormat
              compact
              decimalScale={3}
              suffix={' ' + pool.rewardTokenSymbol}
              thousandSeparator
              value={pool.totalAccruedRewards}
            />
          </StatCardText>
          <StatCardText variant="secondary">Total Accrued Rewards</StatCardText>
        </StatCard>
      </Grid>
    </Grid>
  );
}

export default PoolStats;
