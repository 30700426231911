import { useCallback, useEffect, useState } from 'react';

export function useMediaQuery(query: string): boolean {
  const sanitizedQuery = query.replace(/^@media( ?)/m, '');
  const [match, setMatch] = useState(false);

  const handleChange = useCallback((event: MediaQueryListEvent) => {
    setMatch(event.matches);
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia(sanitizedQuery);
    setMatch(mediaQuery.matches);
    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [handleChange, sanitizedQuery]);

  return match;
}
