import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

export function useEagerConnect(
  injectedConnector?: InjectedConnector,
): boolean {
  const { activate, active } = useWeb3React();
  const [tried, setTried] = useState(false);

  useEffect(() => {
    if (injectedConnector) {
      injectedConnector.isAuthorized().then((authorized) => {
        if (authorized) {
          activate(injectedConnector, undefined, true).catch(() => {
            setTried(true);
          });
        } else {
          if (isMobile && (window as any).ethereum) {
            activate(injectedConnector, undefined, true).catch(() => {
              setTried(true);
            });
          } else {
            setTried(true);
          }
        }
      });
    }
  }, [activate, injectedConnector]);

  useEffect(() => {
    if (active) {
      setTried(active);
    }
  }, [active]);

  return injectedConnector ? tried : true;
}
