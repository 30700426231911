import { providers } from 'ethers';
import { ChainError } from '.';
import { TransactionStatus } from '../types';
import { bignumber, BigNumberish, exponentToBigNumber } from '../math';
import { MAX_UINT_256 } from './constants';
import { ERC20 } from './contracts/ERC20';
import { ParibusStake } from './contracts/ParibusStake';
import { getTokenDecimals } from './getTokenDecimals';
import { getPoolStakingTokenAddress } from './getPoolStakingToken';

interface Options {
  account: string;
  amount: BigNumberish;
  poolAddress: string;
}

export async function* stake(
  provider: providers.Web3Provider,
  { account, amount, poolAddress }: Options,
) {
  try {
    yield TransactionStatus.INITIALIZED;

    const pool = new ParibusStake(poolAddress, provider.getSigner());
    const stakingTokenAddress = await getPoolStakingTokenAddress(
      provider,
      poolAddress,
    );
    const stakingToken = new ERC20(stakingTokenAddress, provider.getSigner());
    const stakingTokenDecimals = await getTokenDecimals(
      provider,
      stakingTokenAddress,
    );
    const value = bignumber(amount).mul(
      exponentToBigNumber(stakingTokenDecimals),
    );

    const allowance = await stakingToken.allowance(account, poolAddress);

    if (allowance.comparedTo(value) < 0) {
      yield TransactionStatus.APPROVE_REQUIRED;
      const approveTx = await stakingToken.approve(
        poolAddress,
        MAX_UINT_256.sub(allowance),
      );
      yield TransactionStatus.APPROVE_IN_PROGRESS;
      await approveTx.wait();
    }

    yield TransactionStatus.CONFIRMATION_REQUIRED;
    const tx = await pool.stake(value);
    yield TransactionStatus.IN_PROGRESS;
    await tx.wait();
    yield TransactionStatus.DONE;
  } catch (error) {
    throw ChainError.fromError(error);
  }
}
