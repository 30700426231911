import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1620,
    margin: '0 auto',
    width: '100%',

    [theme.breakpoints.up('lg')]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
  },
}));

export default useStyles;
