import { useCallback, useEffect, useState } from 'react';
import { getTokenBalance } from '../chain';
import { BigNumber } from '../math';
import { useAccount, useProvider } from '../wallet';

interface Options {
  refetchOnBlock?: boolean;
  tokenAddress: string;
}

export function useTokenBalance(options: Options) {
  const { refetchOnBlock = false, tokenAddress } = options;
  const provider = useProvider();
  const { account } = useAccount();

  const [data, setData] = useState<BigNumber>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);

  const loadData = useCallback(
    async (silent = false) => {
      if (!provider || !account) {
        return;
      }

      if (!silent) {
        setLoading(true);
        setError(undefined);
        setData(undefined);
      }

      try {
        const data = await getTokenBalance(provider, {
          account,
          token: tokenAddress,
        });
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [account, provider, tokenAddress],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (refetchOnBlock && provider) {
      const refetch = () => loadData(true);

      provider.on('block', refetch);

      return () => {
        provider.off('block', refetch);
      };
    }
  }, [loadData, provider, refetchOnBlock]);

  return {
    data,
    error,
    loading,
    refetch: loadData,
  };
}
