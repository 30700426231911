import createTheme, {
  Theme,
  ThemeOptions,
} from '@material-ui/core/styles/createTheme';
import { Color } from './Color';
import { CommonTheme } from './types/CommonTheme';
import { VariantSpecificTheme } from './types/VariantSpecificTheme';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme extends CommonTheme, VariantSpecificTheme {}
  interface ThemeOptions extends CommonTheme, VariantSpecificTheme {}
}

function createThemeVariant(
  variantSpecificTheme: Omit<ThemeOptions, keyof CommonTheme>,
): Theme {
  return createTheme({
    ...variantSpecificTheme,
    breakpoints: {
      ...variantSpecificTheme.breakpoints,
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1620,
        ...variantSpecificTheme.breakpoints?.values,
      },
    },
    overrides: {
      ...variantSpecificTheme.overrides,
      MuiButton: {
        ...variantSpecificTheme.overrides?.MuiButton,
        root: {
          ...variantSpecificTheme.overrides?.MuiButton?.root,
          padding: 12,
        },
        contained: {
          ...variantSpecificTheme.overrides?.MuiButton?.contained,
          boxShadow: 'none',
        },
      },
      MuiDialog: {
        ...variantSpecificTheme.overrides?.MuiDialog,
        paper: {
          borderRadius: 20,
          margin: 16,
          ...variantSpecificTheme.overrides?.MuiDialog?.paper,
        },
        paperFullWidth: {
          width: 'calc(100% - 32px)',
          ...variantSpecificTheme.overrides?.MuiDialog?.paperFullWidth,
        },
      },
      MuiInputBase: {
        ...variantSpecificTheme.overrides?.MuiInputBase,
        root: {
          fontSize: 14,
          letterSpacing: '0.0025em',
          lineHeight: '17.07px',
          ...variantSpecificTheme.overrides?.MuiInputBase?.root,
        },
      },
      MuiOutlinedInput: {
        ...variantSpecificTheme.overrides?.MuiOutlinedInput,
        root: {
          borderRadius: 8,
          ...variantSpecificTheme.overrides?.MuiOutlinedInput?.root,
        },
        input: {
          height: 'auto',
          padding: '15px 16px 16px',
          ...variantSpecificTheme.overrides?.MuiOutlinedInput?.input,
        },
        notchedOutline: {
          borderWidth: 2,
          ...variantSpecificTheme.overrides?.MuiOutlinedInput?.notchedOutline,
        },
      },
    },
    palette: {
      error: {
        main: Color.RED,
        ...variantSpecificTheme.palette?.error,
      },
      info: {
        main: Color.DARK5,
        ...variantSpecificTheme.palette?.info,
      },
      primary: {
        light: '#9665FB',
        main: Color.PURPLE,
        ...variantSpecificTheme.palette?.primary,
      },
      success: {
        main: Color.TURQUOISE,
        ...variantSpecificTheme.palette?.success,
      },
      warning: {
        main: Color.ORANGE,
        ...variantSpecificTheme.palette?.warning,
      },
      ...variantSpecificTheme.palette,
    },
    typography: (palette) => {
      const variantSpecificTypographyOptions =
        typeof variantSpecificTheme.typography === 'function'
          ? variantSpecificTheme.typography(palette)
          : variantSpecificTheme.typography;

      return {
        fontFamily: "'Montserrat', sans-serif",
        ...variantSpecificTypographyOptions,
        h1: {
          fontSize: 96,
          fontWeight: 400,
          letterSpacing: '-0.015em',
          lineHeight: '111.55px',
          ...variantSpecificTypographyOptions?.h1,
        },
        h2: {
          fontSize: 60,
          fontWeight: 400,
          letterSpacing: '-0.005em',
          lineHeight: '69.72px',
          ...variantSpecificTypographyOptions?.h2,
        },
        h3: {
          fontSize: 48,
          fontWeight: 400,
          lineHeight: '55.78px',
          ...variantSpecificTypographyOptions?.h3,
        },
        h4: {
          fontSize: 34,
          fontWeight: 500,
          letterSpacing: '0.0025em',
          lineHeight: '39.51px',
          ...variantSpecificTypographyOptions?.h4,
        },
        h5: {
          fontSize: 24,
          fontWeight: 400,
          letterSpacing: '0.03em',
          lineHeight: '28.13px',
          ...variantSpecificTypographyOptions?.h5,
        },
        h6: {
          fontSize: 11,
          fontWeight: 700,
          letterSpacing: '0.1em',
          lineHeight: '13.41px',
          textTransform: 'uppercase',
          ...variantSpecificTypographyOptions?.h6,
        },
        subtitle1: {
          fontSize: 16,
          letterSpacing: '0.07em',
          lineHeight: '19.5px',
          ...variantSpecificTypographyOptions?.subtitle1,
        },
        subtitle2: {
          fontSize: 14,
          fontWeight: 500,
          letterSpacing: '0.001em',
          lineHeight: '24px',
          ...variantSpecificTypographyOptions?.subtitle2,
        },
        body1: {
          fontSize: 16,
          letterSpacing: '0.005em',
          lineHeight: '30px',
          ...variantSpecificTypographyOptions?.body1,
        },
        body2: {
          fontSize: 14,
          letterSpacing: '0.0025em',
          lineHeight: '17.07px',
          ...variantSpecificTypographyOptions?.body2,
        },
        button: {
          fontSize: 15,
          fontWeight: 600,
          letterSpacing: '0.08em',
          lineHeight: '18.29px',
          textTransform: 'uppercase',
          ...variantSpecificTypographyOptions?.button,
        },
        caption: {
          fontSize: 12,
          letterSpacing: '0.04em',
          lineHeight: '14.63px',
          ...variantSpecificTypographyOptions?.caption,
        },
        overline: {
          fontSize: 12,
          fontWeight: 600,
          letterSpacing: '0.08em',
          lineHeight: '14.63px',
          textTransform: 'uppercase',
          ...variantSpecificTypographyOptions?.overline,
        },
      };
    },
  });
}

export default createThemeVariant;
