import React from 'react';

type BaseProps = Omit<
  React.SVGAttributes<SVGSVGElement>,
  'children' | 'viewBox'
>;

export interface Props extends BaseProps {
  size?: number;
}

function Spinner({ size = 116, ...rest }: Props): React.ReactElement {
  return (
    <svg
      height={size}
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      width={size}
      {...rest}
    >
      <g transform="translate(50 50)">
        <g transform="scale(0.36)">
          <g transform="translate(-50 -50)">
            <g>
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="2.0833333333333335s"
              ></animateTransform>
              <path
                fillOpacity="0.89"
                fill="#725bff"
                d="M50 50L50 0A50 50 0 0 1 100 50Z"
              ></path>
            </g>
            <g>
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="2.7777777777777777s"
              ></animateTransform>
              <path
                fillOpacity="0.89"
                fill="#4593ff"
                d="M50 50L50 0A50 50 0 0 1 100 50Z"
                transform="rotate(90 50 50)"
              ></path>
            </g>
            <g>
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="4.166666666666667s"
              ></animateTransform>
              <path
                fillOpacity="0.89"
                fill="#00e5cc"
                d="M50 50L50 0A50 50 0 0 1 100 50Z"
                transform="rotate(180 50 50)"
              ></path>
            </g>
            <g>
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="8.333333333333334s"
              ></animateTransform>
              <path
                fillOpacity="0.89"
                fill="#3f4fd6"
                d="M50 50L50 0A50 50 0 0 1 100 50Z"
                transform="rotate(270 50 50)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Spinner;
