import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';
import { useMediaQuery } from '../../hooks';
import { PoolBaseInfo } from '../../types';
import LimitedHeightBox from '../LimitedHeightBox';
import useStyles from './Intro.styles';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

export type Props = BaseProps & {
  pools: PoolBaseInfo[];
};

function formatPoolPenaltyPeriod(lockupPeriod: number): string {
  const days = dayjs.duration({ seconds: lockupPeriod }).asDays();
  const suffix = days === 1 ? 'day' : 'days';
  return `${days.toFixed(0)} ${suffix}`;
}

function Intro({
  className,
  pools,
  ...rest
}: Props): React.ReactElement<HTMLDivElement> {
  const classNames = useStyles();
  const { breakpoints } = useTheme();
  const largeHeading = useMediaQuery(breakpoints.up('md'));

  return (
    <LimitedHeightBox
      {...rest}
      className={clsx(classNames.root, className)}
      disabled={largeHeading}
      maxHeight={190}
    >
      <div className={classNames.content}>
        <Typography
          className={classNames.heading}
          color="inherit"
          variant={largeHeading ? 'h3' : 'h4'}
        >
          Paribus Staking
        </Typography>

        <Typography color="inherit">
          Paribus single-sided staking is where you can lock up your PBX tokens
          for a fixed length and earn PBX in return as per rates announced.
          Choose a pool of your liking 90, 180, or 365 days and start earning
          PBX!
        </Typography>

        <ul className={classNames.poolsList}>
          {pools.map((pool) => (
            <li key={pool.id}>
              <Typography>
                {pool.name} -{' '}
                {formatPoolPenaltyPeriod(pool.penaltyPeriod.toNumber())} (lockup
                period)
              </Typography>
            </li>
          ))}
        </ul>
      </div>
    </LimitedHeightBox>
  );
}

export default Intro;
