import Typography, { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useCallback, useContext } from 'react';
import { PillTabsContext } from '../PillTabs';
import useStyles from './PillTab.styles';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
type LabelCreator = (active: boolean) => React.ReactNode;
type Classes = ReturnType<typeof useStyles>;

export interface Props extends BaseProps {
  classes?: Classes;
  disableTypography?: boolean;
  value: string;
  TypographyProps?: TypographyProps;
  label: React.ReactNode | LabelCreator;
}

function PillTab({
  classes,
  className,
  disableTypography = false,
  value,
  TypographyProps,
  label,
  onClick,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();
  const { onChange, value: currentValue } = useContext(PillTabsContext);
  const active = value === currentValue;
  const labelContent = typeof label === 'function' ? label(active) : label;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      onClick?.(event);
      onChange?.(value);
    },
    [onChange, onClick, value],
  );

  return (
    <div
      {...rest}
      className={clsx(
        classNames.root,
        classes?.root,
        {
          [clsx(classNames.active, classes?.active)]: active,
        },
        className,
      )}
      onClick={handleClick}
    >
      {!disableTypography && (
        <Typography color="inherit" variant="body1" {...TypographyProps}>
          {labelContent}
        </Typography>
      )}

      {disableTypography && labelContent}
    </div>
  );
}

export default PillTab;
