import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function SuccessCircleIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <circle cx="12" cy="12" r="8" stroke="currentColor" strokeWidth="2" />
        <circle opacity="0.1" cx="14.5" cy="15.5" r="8.5" fill="currentColor" />
        <path
          d="M9 12.1538L11.3437 14.4976C11.4274 14.5813 11.565 14.5744 11.6399 14.4828L16 9.15383"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    </SvgIcon>
  );
}

export default SuccessCircleIcon;
