import { providers } from 'ethers';
import { ChainError } from '.';
import { bignumber, BigNumberish, exponentToBigNumber } from '../math';
import { TransactionStatus } from '../types';
import { ParibusStake } from './contracts/ParibusStake';
import { getPoolStakingTokenAddress } from './getPoolStakingToken';
import { getTokenDecimals } from './getTokenDecimals';

interface Options {
  amount: BigNumberish;
  poolAddress: string;
}

export async function* unstake(
  provider: providers.Web3Provider,
  { amount, poolAddress }: Options,
) {
  try {
    yield TransactionStatus.INITIALIZED;

    const pool = new ParibusStake(poolAddress, provider.getSigner());
    const stakingTokenAddress = await getPoolStakingTokenAddress(
      provider,
      poolAddress,
    );
    const stakingTokenDecimals = await getTokenDecimals(
      provider,
      stakingTokenAddress,
    );
    const value = bignumber(amount).mul(
      exponentToBigNumber(stakingTokenDecimals),
    );

    yield TransactionStatus.CONFIRMATION_REQUIRED;
    const tx = await pool.unstake(value);
    yield TransactionStatus.IN_PROGRESS;
    await tx.wait();
    yield TransactionStatus.DONE;
  } catch (error) {
    throw ChainError.fromError(error);
  }
}
