import { useCallback, useEffect, useRef, useState } from 'react';
import { getPool, ParibusStake } from '../chain';
import { PoolInfo } from '../types';
import { useProvider } from '../wallet';

export function usePoolInfo(poolAddress: string) {
  const provider = useProvider();

  const [data, setData] = useState<PoolInfo>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const requestId = useRef(0);

  const loadData = useCallback(
    async (silent = false) => {
      if (!provider) {
        return;
      }

      const thisRequestId = requestId.current + 1;
      requestId.current = thisRequestId;

      if (!silent) {
        setLoading(true);
        setError(undefined);
        setData(undefined);
      }

      try {
        const data = await getPool(provider, poolAddress);

        if (thisRequestId === requestId.current) {
          setData(data);
          setLoading(false);
        }
      } catch (error) {
        if (thisRequestId === requestId.current) {
          setError(error);
          setLoading(false);
        }
      }
    },
    [poolAddress, provider],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (provider) {
      const pool = new ParibusStake(poolAddress, provider);
      const refetch = () => loadData(true);

      pool.on('ClaimedTokensFromShare', refetch);
      pool.on('Staked', refetch);
      pool.on('Unstaked', refetch);

      return () => {
        pool.off('ClaimedTokensFromShare', refetch);
        pool.off('Staked', refetch);
        pool.off('Unstaked', refetch);
      };
    }
  }, [loadData, poolAddress, provider]);

  return {
    data,
    error,
    loading,
    refetch: loadData,
  };
}
