import { useCallback, useState } from 'react';
import { stake } from '../chain';
import { TransactionStatus } from '../types';
import { useAccount, useProvider } from '../wallet';

type StakeResult = {
  error: any | null;
};

type StakeFn = (amount: string) => Promise<StakeResult>;

type StakeState = {
  error?: any;
  loading: boolean;
  status: TransactionStatus;
};

export function useStake(poolAddress: string): [StakeFn, StakeState] {
  const provider = useProvider();
  const { account } = useAccount();

  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(TransactionStatus.INITIALIZED);

  const execute = useCallback(
    async (amount: string): Promise<StakeResult> => {
      if (!provider || !account) {
        return {
          error: new Error('Wallet not connected'),
        };
      }

      setLoading(true);
      setError(undefined);

      try {
        for await (const currentStatus of stake(provider, {
          account,
          amount,
          poolAddress,
        })) {
          setStatus(currentStatus);
        }

        return {
          error: null,
        };
      } catch (error) {
        setError(error);

        return {
          error,
        };
      } finally {
        setLoading(false);
      }
    },
    [account, poolAddress, provider],
  );

  return [
    execute,
    {
      error,
      loading,
      status,
    },
  ];
}
