import MenuItem from '@material-ui/core/MenuItem';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import React, { useCallback } from 'react';
import { DESIRED_NETWORK, SUPPORTED_NETWORKS } from '../../constants';
import useStyles from './NetworkSelect.styles';

export type Props = Omit<
  TextFieldProps,
  'children' | 'label' | 'select' | 'value' | 'variant' | 'onChange'
>;

function NetworkSelect({ SelectProps, ...rest }: Props): React.ReactElement {
  const classNames = useStyles();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      window.location.href = event.target.value;
    },
    [],
  );

  return (
    <TextField
      {...rest}
      label="Network"
      select
      variant="outlined"
      value={DESIRED_NETWORK?.appUrl}
      onChange={handleChange}
      SelectProps={{
        classes: { icon: classNames.selectIcon },
        ...SelectProps,
      }}
    >
      {SUPPORTED_NETWORKS.map((network, index) => (
        <MenuItem key={index} value={network.appUrl}>
          {network.name}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default NetworkSelect;
