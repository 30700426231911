import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import ContentNavigationSelect from '../ContentNavigationSelect';
import HighlightTab from '../HighlightTab';
import HighlightTabs from '../HighlightTabs';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

interface Option {
  icon: React.ReactNode;
  label: string;
  value: string;
}

export type Props = BaseProps & {
  options: Option[];
  value: string;
  onOptionChange(option: string): void;
};

function ContentNavigation({
  options,
  value,
  onOptionChange,
  ...rest
}: Props): React.ReactElement {
  return (
    <div {...rest}>
      <Hidden smUp>
        <ContentNavigationSelect
          options={options}
          value={value}
          onOptionChange={onOptionChange}
        />
      </Hidden>

      <Hidden xsDown>
        <HighlightTabs onChange={onOptionChange} value={value}>
          {options.map((option, index) => (
            <HighlightTab
              key={index}
              icon={option.icon}
              label={option.label}
              value={option.value}
            />
          ))}
        </HighlightTabs>
      </Hidden>
    </div>
  );
}

export default ContentNavigation;
