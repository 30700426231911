import Typography from '@material-ui/core/Typography';
import React, { useCallback } from 'react';
import { PoolBaseInfo } from '../../types';
import Link from '../Link';
import Paper, { PaperProps } from '../Paper';
import PoolAccountLimitText from '../PoolAccountLimitText';
import useStyles from './PoolChangeCard.styles';

type BaseProps = Omit<PaperProps, 'children'>;
export type Props = BaseProps & {
  pool: PoolBaseInfo;
  onPoolChange(poolAddress: string): void;
};

function PoolChangeCard({
  pool,
  onPoolChange,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  const handleLinkClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      onPoolChange(pool.id);
    },
    [onPoolChange, pool.id],
  );

  return (
    <Paper elevation={2} {...rest}>
      <div className={classNames.content}>
        <div className={classNames.row}>
          <Typography
            className={classNames.label}
            color="textSecondary"
            variant="h6"
          >
            Pool
          </Typography>
          <Typography variant="body2">{pool.name}</Typography>
        </div>

        <div className={classNames.row}>
          <Typography
            className={classNames.label}
            color="textSecondary"
            variant="h6"
          >
            Limits
          </Typography>
          <PoolAccountLimitText
            max={pool.maxStakingTokenLimit}
            min={pool.minStakingTokenLimit}
            separator=" - "
            suffix={' ' + pool.stakingTokenSymbol}
            variant="body2"
          />
        </div>
      </div>

      <div className={classNames.footer}>
        <Link
          className={classNames.link}
          color="primary"
          showArrow
          underline="none"
          variant="body1"
          onClick={handleLinkClick}
        >
          Switch
        </Link>
      </div>
    </Paper>
  );
}

export default PoolChangeCard;
