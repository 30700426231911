import React, { useEffect } from 'react';
import { useThrottledCallback } from 'use-debounce';
import imageUrl from '../../assets/images/reward-big.png';
import { useSimulateStake } from '../../hooks';
import { BigNumberish } from '../../math';
import { Pool } from '../../types';
import BigNumberFormat from '../BigNumberFormat';
import SummaryCard, { SummaryCardProps } from '../SummaryCard';

type BaseProps = Omit<
  SummaryCardProps,
  'heading' | 'imageUrl' | 'primaryText' | 'secondaryText' | 'showSpinner'
>;

export type Props = BaseProps & {
  amount: BigNumberish;
  pool: Pool;
};

const SIMULATE_TIME_S = 30 * 24 * 60 * 60; // 30 days aka 1 month

function EstimatedRewardsCard({
  amount,
  pool,
  ...rest
}: Props): React.ReactElement {
  const [simulateStake, { data: estimatedReward, loading }] = useSimulateStake({
    poolAddress: pool.id,
    time: SIMULATE_TIME_S,
  });
  const simulateStakeThrottled = useThrottledCallback(simulateStake, 500);

  useEffect(() => {
    simulateStakeThrottled(amount);
  }, [amount, simulateStakeThrottled]);

  return (
    <SummaryCard
      {...rest}
      heading="Your Estimated Rewards"
      imageUrl={imageUrl}
      primaryText={
        typeof estimatedReward !== 'undefined' ? (
          <BigNumberFormat
            compact
            decimalScale={3}
            fixedDecimalScale
            suffix={' ' + pool.rewardTokenSymbol}
            thousandSeparator
            value={estimatedReward}
          />
        ) : (
          '-'
        )
      }
      secondaryText="/ month"
      showSpinner={loading}
    />
  );
}

export default EstimatedRewardsCard;
