import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '100%',
    minHeight: '100vh',
    overflowX: 'hidden',

    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(12),
      paddingTop: 0,
    },
  },
  content: {
    flex: 1,

    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  breadcrumbs: {
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  bottomBar: {
    backgroundColor: theme.backgroundColor,
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    zIndex: 10,
  },
  controlsBar: {},
}));

export default useStyles;
