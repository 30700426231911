import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  summary: {
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

export default useStyles;
