import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.variant === 'light' ? Color.WHITE : Color.DARK2,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100vh',
    maxWidth: 350,
    width: '90%',
  },
  toolbarRoot: {
    justifyContent: 'flex-end',
  },
  toolbarGutters: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  content: {
    flex: 1,
  },
  inset: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  accountDisplay: {
    marginBottom: theme.spacing(4),
  },
}));

export default useStyles;
