import { alpha, makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const IMAGE_SIZE = 180;

const useStyles = makeStyles((theme) => ({
  closeBar: {
    paddingLight: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5),
  },
  closeIcon: {
    color: '#888fa5',
  },
  dialogContentRoot: {
    margin: '0 auto',
    maxWidth: 440,
    overflow: 'visible',

    '&:first-child': {
      padding: `${theme.spacing(8)}px ${theme.spacing(2)}px ${theme.spacing(
        1,
      )}px`,
    },
  },
  image: {
    display: 'block',
    height: IMAGE_SIZE,
    margin: `0 auto ${theme.spacing(3)}px`,
    width: IMAGE_SIZE,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(3),
  },
  listRoot: {
    marginBottom: theme.spacing(4),
  },
  listPadding: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  listItemRoot: {
    backgroundColor: theme.variant === 'light' ? Color.WHITE : Color.DARK1,
    border: theme.variant === 'light' ? 'none' : `1px solid ${Color.DARK3}`,
    boxShadow: theme.variant === 'light' ? '4px 8px 35px #E7EAEC' : 'none',
    borderRadius: 8,
    height: 57,
    marginBottom: theme.spacing(2),
  },
  listItemButton: {
    '&:hover': {
      backgroundColor: theme.variant === 'light' ? Color.WHITE : Color.DARK1,
    },
  },
  listItemIconRoot: {
    minWidth: 32,
  },
  recommendedBadge: {
    backgroundColor: alpha(Color.TURQUOISE, 0.1),
    borderRadius: 4,
    color: Color.TURQUOISE,
    marginRight: theme.spacing(0.25),
    padding: `${theme.spacing(0.5)}px`,

    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  footer: {
    backgroundColor: theme.variant === 'light' ? Color.LIGHT_GREY : Color.DARK1,
    padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
  },
}));

export default useStyles;
