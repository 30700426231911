import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { usePool } from '../../hooks';
import ContentCard, { ContentCardProps } from '../ContentCard';
import ContentNavigation from '../ContentNavigation';
import DepositPanel from '../DepositPanel';
import FAQPanel from '../FAQPanel';
import DepositIcon from '../icons/DepositIcon';
import { FAQIcon } from '../icons/FAQIcon';
import { StatisticsIcon } from '../icons/StatisticsIcon';
import WithdrawIcon from '../icons/WithdrawIcon';
import Spinner from '../Spinner';
import StatsPanel from '../StatsPanel';
import WithdrawPanel from '../WithdrawPanel';
import useStyles from './PoolContent.styles';

export type Props = Omit<ContentCardProps, 'children'> & {
  canChangePool: boolean;
  poolAddress: string;
  onRequestChangePool(): void;
};

const NAVIGATION_OPTIONS = [
  {
    icon: <WithdrawIcon color="primary" />,
    label: 'Withdraw',
    value: 'withdraw',
  },
  {
    icon: <DepositIcon color="primary" />,
    label: 'Deposit',
    value: 'deposit',
  },
  {
    icon: <StatisticsIcon color="primary" />,
    label: 'Stats',
    value: 'stats',
  },
  {
    icon: <FAQIcon color="primary" />,
    label: 'FAQ',
    value: 'faq',
  },
];

function PoolContent({
  canChangePool,
  poolAddress,
  onRequestChangePool,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();
  const [navigationOption, setNavigationOption] = useState('withdraw');
  const {
    data: pool,
    error: poolError,
    loading: poolLoading,
  } = usePool(poolAddress);

  return (
    <ContentCard {...rest}>
      {poolLoading && (
        <Box display="flex" justifyContent="center">
          <Spinner />
        </Box>
      )}

      {!poolLoading && !!poolError && (
        <Box p={3}>
          <Typography align="center" color="textSecondary">
            Could not load pool data.
          </Typography>
        </Box>
      )}

      {!poolLoading && !poolError && !!pool && (
        <React.Fragment>
          <ContentNavigation
            className={classNames.navigation}
            options={NAVIGATION_OPTIONS}
            value={navigationOption}
            onOptionChange={setNavigationOption}
          />

          {navigationOption === 'withdraw' && <WithdrawPanel pool={pool} />}
          {navigationOption === 'deposit' && (
            <DepositPanel
              canChangePool={canChangePool}
              onRequestChangePool={onRequestChangePool}
              pool={pool}
            />
          )}
          {navigationOption === 'stats' && <StatsPanel pool={pool} />}
          {navigationOption === 'faq' && <FAQPanel />}
        </React.Fragment>
      )}
    </ContentCard>
  );
}

export default PoolContent;
