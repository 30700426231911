import { useCallback, useState } from 'react';
import { claimRewards } from '../chain';
import { TransactionStatus } from '../types';
import { useProvider } from '../wallet';

type Result = {
  error: any | null;
};

type ClaimFn = () => Promise<Result>;

type State = {
  error?: any;
  loading: boolean;
  status: TransactionStatus;
};

export function useClaimRewards(poolAddress: string): [ClaimFn, State] {
  const provider = useProvider();

  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(TransactionStatus.INITIALIZED);

  const execute = useCallback(async (): Promise<Result> => {
    if (!provider) {
      return {
        error: new Error('Wallet not connected'),
      };
    }

    setLoading(true);
    setError(undefined);

    try {
      for await (const currentStatus of claimRewards(provider, poolAddress)) {
        setStatus(currentStatus);
      }

      return {
        error: null,
      };
    } catch (error) {
      setError(error);

      return {
        error,
      };
    } finally {
      setLoading(false);
    }
  }, [poolAddress, provider]);

  return [
    execute,
    {
      error,
      loading,
      status,
    },
  ];
}
