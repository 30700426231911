import Typography, { TypographyProps } from '@material-ui/core/Typography';
import React from 'react';

export type Props = TypographyProps & {
  bleak?: boolean;
};

function OverviewCardValue({
  bleak = false,
  children,
  ...rest
}: Props): React.ReactElement {
  return (
    <Typography
      color={bleak ? 'textSecondary' : 'textPrimary'}
      variant="h5"
      {...rest}
    >
      {children}
    </Typography>
  );
}

export default OverviewCardValue;
