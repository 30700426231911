import { CurrencyAmount, Token, WETH9 } from '@uniswap/sdk-core';
import { Pair } from '@uniswap/v2-sdk';
import { providers } from 'ethers';
import { bignumber, BigNumber } from '../math';
import { UniswapPair } from './contracts/UniswapPair';
import { UniswapV2Factory } from './contracts/UniswapV2Factory';
import { getUniswapToken } from './utils/getUniswapToken';

interface Options {
  candidates: Array<Token | string>;
  token: Token;
}

async function getTokenPair(
  provider: providers.Provider,
  token: Token,
  candidates: Array<Token | string>,
): Promise<Pair | null> {
  if (candidates.length === 0) {
    return null;
  }

  const [candidateTokenOrAddress, ...remainingCandidates] = candidates;
  const uniswapFactory = new UniswapV2Factory(provider);
  const maybePairAddress = await uniswapFactory.getPair(
    token.address,
    typeof candidateTokenOrAddress === 'string'
      ? candidateTokenOrAddress
      : candidateTokenOrAddress.address,
  );

  if (maybePairAddress === null) {
    const result = await getTokenPair(provider, token, remainingCandidates);
    return result;
  }

  const candidateToken = await getUniswapToken(
    provider,
    candidateTokenOrAddress,
  );

  const pairContract = new UniswapPair(maybePairAddress, provider);
  const reserves = await pairContract.getReserves();
  const [tokenReserves, candidateReserves] = token.sortsBefore(candidateToken)
    ? reserves
    : [reserves[1], reserves[0]];

  const pair = new Pair(
    CurrencyAmount.fromRawAmount(token, tokenReserves.toHex()),
    CurrencyAmount.fromRawAmount(candidateToken, candidateReserves.toHex()),
  );

  return pair;
}

export async function getTokenUSDPriceUniswapV2(
  provider: providers.Provider,
  { candidates, token }: Options,
): Promise<BigNumber | null> {
  const maybeUSDPair = await getTokenPair(provider, token, candidates);

  if (maybeUSDPair !== null) {
    return bignumber(maybeUSDPair.priceOf(token).toSignificant());
  }

  const wethToken = WETH9[token.chainId];
  const tokenWethPair = await getTokenPair(provider, token, [wethToken]);

  if (tokenWethPair === null) {
    return null;
  }

  const wethUsdPair = await getTokenPair(provider, wethToken, candidates);

  if (wethUsdPair === null) {
    return null;
  }

  return bignumber(tokenWethPair.priceOf(token).toSignificant()).mul(
    wethUsdPair.priceOf(wethToken).toSignificant(),
  );
}
