import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
    },
  },
  right: {
    maxWidth: 960,
    width: '100%',

    [theme.breakpoints.up('xl')]: {
      flex: 1,
      marginLeft: 90,
      maxWidth: 'auto',
    },
  },
  poolTabsWrapper: {
    marginBottom: theme.spacing(3),
    maxWidth: '100%',
    overflowX: 'scroll',
    padding: '0 8px',
    textAlign: 'center',

    '&::-webkit-scrollbar': {
      display: 'none',
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  poolTabs: {
    display: 'inline-flex',
    justifyContent: 'center',
  },
  navigation: {
    marginBottom: theme.spacing(3),
  },
}));

export default useStyles;
