import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  rounded: {
    borderRadius: 8,
  },
  largeRoundings: {
    borderRadius: 20,
  },
  elevation2: {
    boxShadow:
      theme.variant === 'light'
        ? '4px 8px 35px #E7EAEC'
        : '0px 4px 35px #16172B',
  },
}));

export default useStyles;
