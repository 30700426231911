import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function InfoCircleIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <circle cx="12" cy="12" r="8" stroke="currentColor" strokeWidth="2" />
        <circle opacity="0.1" cx="14.5" cy="15.5" r="8.5" fill="currentColor" />
        <line
          x1="12"
          y1="12"
          x2="12"
          y2="15"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle
          cx="12"
          cy="9"
          r="1"
          transform="rotate(-180 12 9)"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default InfoCircleIcon;
