import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const THUMB_SIZE = 18;

const useStyles = makeStyles((theme) => ({
  root: {
    height: 48,
    width: 72,
  },
  checked: {},
  colorPrimary: {
    color: theme.palette.primary.main,

    '&$checked': {
      color: Color.WHITE,

      '& + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  switchBase: {
    left: 6,
    top: 6,

    '&$checked': {
      transform: 'translateX(23px)',

      '& + $track': {
        opacity: 1,
      },
    },
  },
  thumb: {
    boxShadow: 'none',
    height: THUMB_SIZE,
    width: THUMB_SIZE,
  },
  track: {
    backgroundColor: Color.WHITE,
    borderRadius: 24,
    opacity: 1,
  },
}));

export default useStyles;
