import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@material-ui/core/AccordionSummary';
import React from 'react';
import { mergeClasses } from '../../utils';
import useStyles from './AccordionSummary.styles';

export type Props = AccordionSummaryProps;

function AccordionSummary({
  children,
  classes,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <MuiAccordionSummary {...rest} classes={mergeClasses(classNames, classes)}>
      {children}
    </MuiAccordionSummary>
  );
}

export default AccordionSummary;
