import { providers } from 'ethers';
import { adjustDecimals } from '../math';
import { PoolBaseInfo } from '../types';
import { ParibusStake } from './contracts/ParibusStake';
import { ChainError } from './errors/ChainError';
import { getPoolStakingTokenAddress } from './getPoolStakingToken';
import { getTokenDecimals } from './getTokenDecimals';
import { getTokenSymbol } from './getTokenSymbol';

export async function getPoolBaseInfo(
  provider: providers.Provider,
  poolAddress: string,
): Promise<PoolBaseInfo> {
  try {
    const pool = new ParibusStake(poolAddress, provider);
    const [poolInfo, stakingTokenAddress] = await Promise.all([
      pool.getPoolInfo(),
      getPoolStakingTokenAddress(provider, poolAddress),
    ]);

    const [stakingTokenDecimals, stakingTokenSymbol] = await Promise.all([
      getTokenDecimals(provider, stakingTokenAddress),
      getTokenSymbol(provider, stakingTokenAddress),
    ]);

    const [
      name,
      ,
      penaltyLockupPeriod,
      ,
      ,
      minAmountOfStakedTokensRequired,
      maxAmountOfStakedTokensRequired,
    ] = poolInfo;

    return {
      id: poolAddress,
      maxStakingTokenLimit: adjustDecimals(
        maxAmountOfStakedTokensRequired,
        stakingTokenDecimals,
      ),
      minStakingTokenLimit: adjustDecimals(
        minAmountOfStakedTokensRequired,
        stakingTokenDecimals,
      ),
      name,
      penaltyPeriod: penaltyLockupPeriod,
      stakingTokenSymbol,
    };
  } catch (error) {
    throw ChainError.fromError(error);
  }
}
