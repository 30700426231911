import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  overview: {
    marginBottom: theme.spacing(3),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      flex: 2,
    },

    [theme.breakpoints.up('xl')]: {
      flex: 'auto',
    },
  },
  imageWrapper: {
    [theme.breakpoints.up('md')]: {
      flex: 1,
    },

    [theme.breakpoints.up('xl')]: {
      flex: 'auto',
    },
  },
  accordionContent: {
    width: '100%',
  },
  lockedRewardsIcon: {
    height: 100,
    marginBottom: theme.spacing(3),
    width: 100,
  },
  image: {
    display: 'block',
    margin: '0 auto',
    width: 172,
  },
}));

export default useStyles;
