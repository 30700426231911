import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(3),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-end',
      flexDirection: 'row',
    },
  },
  table: {
    marginBottom: theme.spacing(3),
    width: '100%',

    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },

    '& > tbody > tr:last-child > td': {
      paddingBottom: theme.spacing(3),
    },

    '& > tfoot > tr:first-child > td': {
      borderTop: `1px dashed ${Color.GREY}`,
      paddingTop: theme.spacing(3),
    },

    '& td': {
      color: theme.variant === 'light' ? Color.DARK4 : Color.GREY,
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),

      '&:first-child': {
        paddingRight: 42,
        width: '1%',
      },
      '&:last-child': {
        width: '99%',
      },
    },
  },
  rewardsRow: {
    '& td:last-child': {
      color: theme.palette.success.main,
    },
  },
  lossRow: {
    '& td:last-child': {
      color: theme.palette.error.main,
    },
  },
  label: {
    whiteSpace: 'nowrap',
  },
  valuesWrapper: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  usdValue: {
    marginTop: theme.spacing(0.5),

    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1),
      marginTop: 0,
    },
  },
}));

export default useStyles;
