import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function ErrorCircleIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <circle cx="12" cy="12" r="8" stroke="currentColor" strokeWidth="2" />
        <circle opacity="0.1" cx="14.5" cy="15.5" r="8.5" fill="currentColor" />
        <line
          x1="9.17157"
          y1="14.8284"
          x2="14.8284"
          y2="9.17158"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          x1="9.17157"
          y1="9.17157"
          x2="14.8284"
          y2="14.8284"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    </SvgIcon>
  );
}

export default ErrorCircleIcon;
