import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { useEffect } from 'react';

export function useInactiveListeners(
  suppress: boolean,
  injectedConnector?: InjectedConnector,
) {
  const { activate, active, error } = useWeb3React();

  useEffect(() => {
    const { ethereum } = window as any;

    if (
      !!suppress &&
      injectedConnector &&
      ethereum &&
      ethereum.on &&
      !active &&
      !error
    ) {
      const handleChainChanged = () => {
        activate(injectedConnector, undefined, true).catch((error) => {
          console.error('Failed to activate after chain changed', error);
        });
      };

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          activate(injectedConnector, undefined, true).catch((error) => {
            console.error('Failed to activate after accounts changed', error);
          });
        }
      };

      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
        }
      };
    }
  }, [activate, active, error, injectedConnector, suppress]);
}
