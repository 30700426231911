import MuiAccordion, { AccordionProps } from '@material-ui/core/Accordion';
import React from 'react';
import { mergeClasses } from '../../utils';
import useStyles from './Accordion.styles';

export type Props = AccordionProps;

function Accordion({ children, classes, ...rest }: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <MuiAccordion
      elevation={0}
      {...rest}
      classes={mergeClasses(classNames, classes)}
    >
      {children}
    </MuiAccordion>
  );
}

export default Accordion;
