import { ChainError, ChainErrorCode } from '../chain';

const GENERIC_ERROR = 'An error has occured';

const CHAIN_ERROR_DICT: Partial<Record<ChainErrorCode, string>> = {
  [ChainErrorCode.USER_REJECTED_REQUEST]: 'Transaction was rejected',
};

export function renderErrorMessage(error: any): string {
  if (error instanceof ChainError) {
    return CHAIN_ERROR_DICT[error.code] || GENERIC_ERROR;
  }

  return GENERIC_ERROR;
}
