import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  image: {
    display: 'block',
    height: 140,
    margin: `${theme.spacing(6)}px auto 0`,
    width: 140,

    [theme.breakpoints.up('md')]: {
      height: 180,
      width: 180,
    },
  },
  content: {
    maxWidth: 632,
    margin: `0 auto`,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(6),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

export default useStyles;
