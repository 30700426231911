import { Token, WETH9 } from '@uniswap/sdk-core';
import { FeeAmount, Pool } from '@uniswap/v3-sdk';
import { providers } from 'ethers';
import { bignumber, BigNumber } from '../math';
import { UniswapV3Factory } from './contracts/UniswapV3Factory';
import { UniswapV3Pool } from './contracts/UniswapV3Pool';
import { getUniswapToken } from './utils/getUniswapToken';

export interface Options {
  candidates: Array<Token | string>;
  token: Token;
}

type PoolMetadata = [string, FeeAmount];

// @see https://docs.uniswap.org/protocol/concepts/V3-overview/fees
const FEE_LEVELS = [FeeAmount.LOW, FeeAmount.MEDIUM, FeeAmount.HIGH];

async function getTokenPoolMetadata(
  provider: providers.Provider,
  token: Token,
  candidate: Token | string,
  feeLevels: FeeAmount[] = FEE_LEVELS,
): Promise<PoolMetadata | null> {
  if (feeLevels.length === 0) {
    return null;
  }

  const [currentFeeLevel, ...remainingFeeLevels] = feeLevels;

  const uniswapFactory = new UniswapV3Factory(provider);
  const maybePoolAddress = await uniswapFactory.getPool(
    token.address,
    typeof candidate === 'string' ? candidate : candidate.address,
    currentFeeLevel,
  );

  if (maybePoolAddress === null) {
    const result = await getTokenPoolMetadata(
      provider,
      token,
      candidate,
      remainingFeeLevels,
    );

    return result;
  }

  return [maybePoolAddress, currentFeeLevel];
}

async function getTokenPool(
  provider: providers.Provider,
  token: Token,
  candidates: Array<Token | string>,
): Promise<Pool | null> {
  if (candidates.length === 0) {
    return null;
  }

  const [candidateTokenOrAddress, ...remainingCandidates] = candidates;
  const maybePoolMetadata = await getTokenPoolMetadata(
    provider,
    token,
    candidateTokenOrAddress,
  );

  if (maybePoolMetadata === null) {
    const result = await getTokenPool(provider, token, remainingCandidates);
    return result;
  }

  const [poolAddress, fee] = maybePoolMetadata;

  const candidateToken = await getUniswapToken(
    provider,
    candidateTokenOrAddress,
  );
  const pool = new UniswapV3Pool(poolAddress, provider);
  const [sqrtPriceX96, tick] = await pool.slot0();
  const liquidity = await pool.liquidity();

  return new Pool(
    token,
    candidateToken,
    fee,
    sqrtPriceX96.toString(),
    liquidity.toString(),
    tick,
  );
}

export async function getTokenUSDPriceUniswapV3(
  provider: providers.Provider,
  { candidates, token }: Options,
): Promise<BigNumber | null> {
  const pool = await getTokenPool(provider, token, candidates);

  if (pool !== null) {
    return bignumber(pool.priceOf(token).toSignificant());
  }

  const wethToken = WETH9[token.chainId];
  const tokenWethPool = await getTokenPool(provider, token, [wethToken]);

  if (tokenWethPool === null) {
    return null;
  }

  const wethUsdPool = await getTokenPool(provider, wethToken, candidates);

  if (wethUsdPool === null) {
    return null;
  }

  return bignumber(tokenWethPool.priceOf(token).toSignificant()).mul(
    wethUsdPool.priceOf(wethToken).toSignificant(),
  );
}
