import { Color } from './Color';
import createThemeVariant from './createThemeVariant';

const darkTheme = createThemeVariant({
  variant: 'dark',
  backgroundColor: Color.DARK2,
  assetTextField: {
    baseBackgroundColor: Color.DARK1,
  },
  palette: {
    text: {
      primary: Color.WHITE,
      secondary: Color.GREY,
    },
  },
  pillTabs: {
    activeBackgroundColor: Color.DARK3,
    activeColor: Color.WHITE,
    backgroundColor: Color.DARK1,
    color: Color.PURPLE,
    pillShadow: '0px 4px 35px #16172B',
  },
  table: {
    bodyCellBackgroundColor: Color.DARK1,
    separatorColor: Color.DARK2,
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: Color.DARK1,
      },
    },
    MuiButton: {
      containedSecondary: {
        backgroundColor: Color.DARK3,
        borderColor: Color.DARK1,

        '&:hover': {
          backgroundColor: Color.DARK3,

          '@media(hover: none)': {
            backgroundColor: Color.DARK3,
          },
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: Color.DARK2,
        },
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: Color.DARK2,
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: Color.DARK3,
        },
        '&$focused $notchedOutline': {
          borderColor: Color.DARK3,
        },
      },
      notchedOutline: {
        borderColor: Color.DARK3,
      },
      focused: {},
    },
    MuiPaper: {
      root: {
        backgroundColor: Color.DARK1,
      },
    },
  },
});

export default darkTheme;
