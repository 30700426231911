import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function CloseIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <line
          x1="4.17332"
          y1="18.7563"
          x2="18.9296"
          y2="4.00002"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          x1="4.41125"
          y1="4"
          x2="19.1676"
          y2="18.7563"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <g opacity="0.1">
          <line
            x1="5.42628"
            y1="19.7563"
            x2="20.1826"
            y2="4.99999"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <line
            x1="3.41421"
            y1="5"
            x2="18.1705"
            y2="19.7563"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default CloseIcon;
