import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import { mergeClasses } from '../../utils';
import useStyles from './Button.styles';

export interface Props extends ButtonProps {
  rounded?: boolean;
}

function Button({
  children,
  classes,
  rounded = false,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles({ rounded });

  return (
    <MuiButton {...rest} classes={mergeClasses(classNames, classes)}>
      {children}
    </MuiButton>
  );
}

export default Button;
