import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import Button from '../../components/Button';
import Screen from '../../components/Screen';
import Spinner from '../../components/Spinner';
import { CHAIN_DICT, DESIRED_CHAIN_ID, DESIRED_NETWORK } from '../../constants';
import { useDesiredNetworkSwitch } from '../../hooks';
import useStyles from './InvalidChainPage.styles';

function InvalidChainPage(): React.ReactElement {
  const classNames = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [switchNetwork, { loading: switchInProgress }] =
    useDesiredNetworkSwitch();

  const handleSwitchButtonClick = useCallback(() => {
    switchNetwork().then(({ error }) => {
      if (error) {
        enqueueSnackbar(
          'Could not switch network. Please try chaning the network manually using your wallet controls.',
          {
            autoHideDuration: 10000,
            variant: 'error',
          },
        );
      }
    });
  }, [enqueueSnackbar, switchNetwork]);

  return (
    <Screen>
      <div className={classNames.content}>
        <Typography align="center" className={classNames.heading} variant="h4">
          Unsupported Network
        </Typography>

        <div>
          <Typography align="center" color="textSecondary">
            We've detected that you are currently on unsupported network. Please
            switch to the following network in your wallet.
          </Typography>

          {!!DESIRED_NETWORK && (
            <div className={classNames.networkBox}>
              <div className={classNames.networkBoxContent}>
                <Typography className={classNames.networkName} variant="body2">
                  {DESIRED_NETWORK.name}
                </Typography>

                {!!CHAIN_DICT[DESIRED_CHAIN_ID.toString()] && (
                  <Typography variant="caption" color="textSecondary">
                    {CHAIN_DICT[DESIRED_CHAIN_ID.toString()]}
                  </Typography>
                )}
              </div>

              {!switchInProgress && (
                <Button
                  className={classNames.networkSwitchButton}
                  color="primary"
                  onClick={handleSwitchButtonClick}
                  size="small"
                >
                  Switch
                </Button>
              )}

              {switchInProgress && <Spinner size={70} />}
            </div>
          )}
        </div>
      </div>
    </Screen>
  );
}

export default InvalidChainPage;
