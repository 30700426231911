import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.variant === 'light' ? Color.LIGHT_GREY : Color.DARK2,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    paddingRight: 0,
    position: 'relative',
  },
  heading: {
    marginBottom: theme.spacing(3),
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  secondaryText: {
    color:
      theme.variant === 'light' ? Color.GREY : theme.palette.text.secondary,
    marginTop: 2,
  },
  image: {
    display: 'block',
    height: 120,
    width: 120,

    [theme.breakpoints.up('md')]: {
      height: 144,
      marginRight: theme.spacing(2),
      width: 144,
    },
  },
  spinner: {
    position: 'absolute',
    right: 16,
    top: 16,
  },
}));

export default useStyles;
