import Box, { BoxProps } from '@material-ui/core/Box';
import clsx from 'clsx';
import React from 'react';
import useStyles from './Container.styles';

export type Props = BoxProps;

export const Container: React.FC<Props> = ({
  children,
  className,
  ...rest
}) => {
  const classNames = useStyles();

  return (
    <Box {...rest} className={clsx(classNames.root, className)}>
      {children}
    </Box>
  );
};
