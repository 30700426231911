import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${
      theme.variant === 'light' ? Color.LIGHT_BLUE : Color.DARK3
    }`,
    boxShadow: 'none',
    height: 67,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),

    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      height: 88,
    },
  },
  toolbarRoot: {
    color:
      theme.palette.text[theme.variant === 'light' ? 'secondary' : 'primary'],
    justifyContent: 'space-between',
  },
  logo: {
    [theme.breakpoints.up('md')]: {
      marginRight: 66,
    },
  },
  title: {
    marginBottom: 2,
  },
  actions: {
    minWidth: 56,
  },
  networkSelect: {
    marginRight: theme.spacing(4),
    minWidth: 200,
  },
}));

export default useStyles;
