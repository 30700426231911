import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useMediaQuery } from '../../hooks';
import { BigNumber, exponentToBigNumber } from '../../math';
import { Pool } from '../../types';
import BigNumberFormat from '../BigNumberFormat';
import BigNumberUSDFormat from '../BigNumberUSDFormat';
import OverviewCard from '../OverviewCard';
import OverviewCardLabel from '../OverviewCardLabel';
import OverviewCardValue from '../OverviewCardValue';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

export type Props = BaseProps & {
  estimatedAccrued?: BigNumber;
  pool: Pool;
};

function PoolOverview({
  estimatedAccrued,
  pool,
  ...rest
}: Props): React.ReactElement {
  const { breakpoints } = useTheme();
  const largeSpacings = useMediaQuery(breakpoints.up('md'));

  return (
    <Grid {...rest} container spacing={largeSpacings ? 4 : 2}>
      <Grid item xs={12} md={4}>
        <OverviewCard
          info={{
            heading: 'TVL',
            text: 'Estimated total value locked in the pool',
          }}
        >
          <OverviewCardLabel>TVL</OverviewCardLabel>
          <OverviewCardValue>
            <BigNumberUSDFormat
              compact
              decimalScale={3}
              suffix={' ' + pool.stakingTokenSymbol}
              thousandSeparator
              usdPrice={pool.stakingTokenPriceUSD}
              value={pool.totalValueLocked}
            />
          </OverviewCardValue>
        </OverviewCard>
      </Grid>

      <Grid item xs={12} md={4}>
        <OverviewCard>
          <OverviewCardLabel>APY</OverviewCardLabel>
          <OverviewCardValue>
            {pool.apr !== null ? (
              <BigNumberFormat
                compact
                decimalScale={2}
                fixedDecimalScale
                suffix=" %"
                value={pool.apr.mul(100)}
              />
            ) : (
              'N/A'
            )}
          </OverviewCardValue>
        </OverviewCard>
      </Grid>

      <Grid item xs={12} md={4}>
        <OverviewCard
          info={{
            heading: 'Estimated Accrued',
            text: `The estimated number of ${pool.rewardTokenSymbol} you have accrued. Excludes ${pool.rewardTokenSymbol} rewards that you withdrew before, if any.`,
          }}
        >
          <OverviewCardLabel>Estimated Accrued</OverviewCardLabel>
          <OverviewCardValue>
            {typeof estimatedAccrued !== 'undefined' && (
              <BigNumberFormat
                compact
                decimalScale={3}
                suffix={' ' + pool.rewardTokenSymbol}
                thousandSeparator
                value={estimatedAccrued.div(
                  exponentToBigNumber(pool.rewardTokenDecimals),
                )}
              />
            )}

            {typeof estimatedAccrued === 'undefined' && <span>-</span>}
          </OverviewCardValue>
        </OverviewCard>
      </Grid>
    </Grid>
  );
}

export default PoolOverview;
