import MuiDrawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useAccount } from '../../wallet';
import { AccountDisplay } from '../AccountDisplay';
import { ControlsBar } from '../ControlsBar';
import NetworkSelect from '../NetworkSelect';
import { Toolbar } from '../Toolbar';
import useStyles from './Drawer.styles';

export type Props = {
  open: boolean;
  onClose(): void;
};

function Drawer({ open, onClose }: Props): React.ReactElement {
  const classNames = useStyles();
  const { account } = useAccount();

  return (
    <MuiDrawer
      anchor="left"
      classes={{
        paper: classNames.paper,
      }}
      container={window.document.body}
      open={open}
      onClose={onClose}
      variant="temporary"
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Toolbar
        classes={{
          root: classNames.toolbarRoot,
          gutters: classNames.toolbarGutters,
        }}
      >
        <IconButton onClick={onClose}>
          <CloseIcon color="primary" />
        </IconButton>
      </Toolbar>

      <div className={classNames.content}>
        <div className={classNames.inset}>
          {!!account && (
            <AccountDisplay
              account={account}
              className={classNames.accountDisplay}
            />
          )}
          <NetworkSelect fullWidth />
        </div>
      </div>

      <ControlsBar />
    </MuiDrawer>
  );
}

export default Drawer;
