import React from 'react';
import MuiToolbar, { ToolbarProps } from '@material-ui/core/Toolbar';
import useStyles from './Toolbar.styles';
import { mergeClasses } from '../../utils';

export type Props = ToolbarProps;

export function Toolbar({
  children,
  classes,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <MuiToolbar {...rest} classes={mergeClasses(classNames, classes)}>
      {children}
    </MuiToolbar>
  );
}
