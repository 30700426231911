import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const IMAGE_SIZE = 140;

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  closeBar: {
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  image: {
    display: 'block',
    height: IMAGE_SIZE,
    marginBottom: theme.spacing(6),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: IMAGE_SIZE,
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  text: {
    marginBottom: theme.spacing(4),
  },
  listHeader: {
    marginBottom: theme.spacing(2),
    padding: `0 ${theme.spacing(3)}px`,
  },
  item: {
    marginBottom: theme.spacing(2),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  footer: {
    backgroundColor: theme.variant === 'light' ? Color.LIGHT_GREY : Color.DARK1,
    padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
    textAlign: 'center',
  },
}));

export default useStyles;
