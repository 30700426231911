import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import Button from '../Button';
import EyeIcon from '../icons/EyeIcon';
import useStyles from './LimitedHeightBox.styles';

type BaseProps = React.HTMLAttributes<HTMLDivElement>;

export type Props = BaseProps & {
  disabled?: boolean;
  fadeColor?: string;
  maxHeight: string | number;
  scrollable?: boolean;
};

function LimitedHeightBox({
  children,
  className,
  disabled = false,
  fadeColor,
  maxHeight,
  scrollable = false,
  ...rest
}: Props): React.ReactElement<HTMLDivElement> {
  const [open, setOpen] = useState(false);
  const [contentEl, setContentEl] = useState<HTMLDivElement | null>(null);
  const classNames = useStyles({
    disabled,
    fadeColor,
    maxHeight,
    open,
    scrollable,
  });
  const fitsLimit =
    contentEl !== null && contentEl.clientHeight >= contentEl.scrollHeight;
  const faded = !disabled && !open && !fitsLimit;

  const handleOpenButtonClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleScroll = useCallback((event: React.UIEvent) => {
    const target = event.target as HTMLDivElement;
    setOpen(target.clientHeight + target.scrollTop >= target.scrollHeight);
  }, []);

  const handleScrollDebounced = useDebouncedCallback(handleScroll, 16);

  return (
    <div
      {...rest}
      className={clsx(
        classNames.root,
        {
          [classNames.faded]: faded,
        },
        className,
      )}
    >
      <div
        className={classNames.content}
        onScroll={scrollable ? handleScrollDebounced : undefined}
        ref={setContentEl}
      >
        {children}
      </div>

      {faded && !scrollable && (
        <div className={classNames.buttonArea}>
          <Button
            color="secondary"
            endIcon={<EyeIcon />}
            onClick={handleOpenButtonClick}
            rounded
            variant="contained"
          >
            Read more
          </Button>
        </div>
      )}
    </div>
  );
}

export default LimitedHeightBox;
