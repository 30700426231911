import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import React, { useCallback } from 'react';
import useStyles from './ContentNavigationSelect.styles';

interface Option {
  icon: React.ReactNode;
  label: string;
  value: string;
}

export type Props = {
  options: Option[];
  value: string;
  onOptionChange(nextOption: string): void;
};

function ContentNavigationSelect({
  options,
  value,
  onOptionChange,
}: Props): React.ReactElement {
  const classNames = useStyles();
  const selectedOption = options.find((option) => option.value === value);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onOptionChange(event.target.value);
    },
    [onOptionChange],
  );

  return (
    <TextField
      fullWidth
      select
      variant="outlined"
      value={value}
      onChange={handleChange}
      InputProps={{
        startAdornment: selectedOption ? (
          <InputAdornment position="start">
            {selectedOption.icon}
          </InputAdornment>
        ) : undefined,
      }}
      SelectProps={{
        classes: { icon: classNames.selectIcon },
      }}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default ContentNavigationSelect;
