import { useCallback, useState } from 'react';
import { CHAIN_DICT, DESIRED_CHAIN_ID, DESIRED_NETWORK } from '../constants';
import { useProvider } from '../wallet';

type SwitchResult = {
  error: any | null;
};

type SwitchFn = () => Promise<SwitchResult>;

type SwitchState = {
  error?: any;
  loading: boolean;
};

export function useDesiredNetworkSwitch(): [SwitchFn, SwitchState] {
  const provider = useProvider();

  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);

  const execute = useCallback(async (): Promise<SwitchResult> => {
    // should never happen with correct .env, checking so typing works
    if (!DESIRED_NETWORK) {
      return {
        error: new Error('No desired network specified'),
      };
    }

    if (!provider) {
      return {
        error: new Error('Wallet not connected'),
      };
    }

    setLoading(true);
    setError(undefined);

    try {
      await provider.send('wallet_switchEthereumChain', [
        { chainId: '0x' + DESIRED_CHAIN_ID.toString(16) },
      ]);

      return { error: null };
    } catch (switchError) {
      const chain = DESIRED_NETWORK.supportedChains.find((chain) => {
        return typeof chain === 'number'
          ? chain === DESIRED_CHAIN_ID
          : chain.id === DESIRED_CHAIN_ID;
      });
      const chainParams = typeof chain === 'object' ? chain : null;

      if ((switchError as any)?.code === 4902 && chainParams !== null) {
        try {
          await provider.send('wallet_addEthereumChain', [
            {
              blockExplorerUrls:
                typeof chainParams.blockExplorerUrl === 'string'
                  ? [chainParams.blockExplorerUrl]
                  : undefined,
              chainId: '0x' + DESIRED_CHAIN_ID.toString(16),
              chainName: CHAIN_DICT[DESIRED_CHAIN_ID],
              nativeCurrency: DESIRED_NETWORK.nativeCurrency,
              rpcUrls: [chainParams.rpcUrl],
            },
          ]);

          return { error: null };
        } catch (addError) {
          setError(addError);

          return {
            error: addError,
          };
        }
      } else {
        setError(switchError);

        return {
          error: switchError,
        };
      }
    } finally {
      setLoading(false);
    }
  }, [provider]);

  return [
    execute,
    {
      error,
      loading,
    },
  ];
}
