import MuiAppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useState } from 'react';
import { useAccount } from '../../wallet';
import { AccountDisplay } from '../AccountDisplay';
import Drawer from '../Drawer';
import { HamburgerIcon } from '../icons/HamburgerIcon';
import { Logo } from '../Logo';
/* import NetworkSelect from '../NetworkSelect'; */
import { Toolbar } from '../Toolbar';
import useStyles from './AppBar.styles';

function AppBar(): React.ReactElement {
  const classNames = useStyles();
  const { account } = useAccount();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const openDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  return (
    <MuiAppBar
      classes={{ root: classNames.root }}
      color="default"
      position="sticky"
    >
      <Toolbar
        classes={{
          root: classNames.toolbarRoot,
        }}
        disableGutters
      >
        <Hidden lgUp>
          <IconButton onClick={openDrawer}>
            <HamburgerIcon color="primary" fontSize="large" />
          </IconButton>
        </Hidden>

        <Box alignItems="flex-end" display="flex">
          <Logo className={classNames.logo} size={35} />

          <Hidden mdDown>
            <Typography
              className={classNames.title}
              color="inherit"
              variant="h5"
            >
              Staking
            </Typography>
          </Hidden>
        </Box>

        <div className={classNames.actions}>
          <Hidden mdDown>
            <Box alignItems="center" display="flex">
              {/* <NetworkSelect className={classNames.networkSelect} /> */}
              {!!account && <AccountDisplay account={account} />}
            </Box>
          </Hidden>
        </div>
      </Toolbar>

      <Hidden lgUp>
        <Drawer open={drawerOpen} onClose={closeDrawer} />
      </Hidden>
    </MuiAppBar>
  );
}

export default AppBar;
