import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    borderBottom: `1px solid ${theme.backgroundColor}`,
    padding: '20px 18px',
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(3),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  label: {
    width: 90,
  },
  footer: {
    padding: '10px 18px',
    textAlign: 'center',
  },
  link: {
    cursor: 'pointer',
  },
}));

export default useStyles;
