import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.variant === 'light' ? Color.WHITE : Color.DARK1,
    border: `2px solid ${
      theme.variant === 'light' ? Color.LIGHT_GREY : Color.DARK3
    }`,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },

  content: {
    flex: 1,
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
}));

export default useStyles;
