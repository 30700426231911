import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function WalletIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 5H19V7H21V5C21 3.89543 20.1046 3 19 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H19C20.1046 21 21 20.1046 21 19V17H19V19H3V5Z"
          fill="currentColor"
        />
        <path
          d="M14 11C14 9.34315 15.3431 8 17 8H22V16H17C15.3431 16 14 14.6569 14 13V11Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <circle cx="18" cy="12" r="1" fill="currentColor" />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 8C5.89543 8 5 8.89543 5 10V22C5 23.1046 5.89543 24 7 24H22C23.1046 24 24 23.1046 24 22V20C24 20.5523 23.5523 21 23 21H20C17.7909 21 16 19.2091 16 17V15C16 12.7909 17.7909 11 20 11H23C23.5523 11 24 11.4477 24 12V10C24 8.89543 23.1046 8 22 8H7Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default WalletIcon;
