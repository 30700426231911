import { ClassNameMap } from '@material-ui/core/styles/withStyles';

export function mergeClasses<K extends string>(
  providedClasses: Partial<ClassNameMap<K>>,
  additionalClasses?: Partial<ClassNameMap<K>>,
): Partial<ClassNameMap<K>> {
  if (!additionalClasses) {
    return providedClasses;
  }

  const providedKeys = Object.keys(providedClasses) as any as K[];

  const mergedClasses = providedKeys.reduce((acc, classKey) => {
    const providedClass = providedClasses[classKey];
    const additionalClass = additionalClasses[classKey];

    return {
      ...acc,
      [classKey]: additionalClass
        ? providedClass + ' ' + additionalClass
        : providedClass,
    };
  }, {});

  return {
    ...providedClasses,
    ...additionalClasses,
    ...mergedClasses,
  };
}
