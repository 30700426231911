import { providers } from 'ethers';
import { PoolBaseInfo } from '../types';
import { ParibusStakeManager } from './contracts/ParibusStakeManager';
import { ChainError } from './errors/ChainError';
import { getPoolBaseInfo } from './getPoolBaseInfo';

export async function getPools(
  provider: providers.Provider,
): Promise<PoolBaseInfo[]> {
  try {
    const manager = new ParibusStakeManager(provider);
    const addresses = await manager.getContracts();
    const pools = await Promise.all(
      addresses.map((address) => getPoolBaseInfo(provider, address)),
    );

    return pools;
  } catch (error) {
    throw ChainError.fromError(error);
  }
}
