import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const ICON_SIZE = 58;

const useStyles = makeStyles((theme) => {
  const valuesBorderColor =
    theme.variant === 'light' ? Color.WHITE : Color.DARK3;

  return {
    text: {
      display: 'block',
      marginBottom: 29,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    content: {
      alignItems: 'center',
      display: 'flex',
      marginBottom: 40,

      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
    icon: {
      height: ICON_SIZE,
      width: ICON_SIZE,
    },
    values: {
      border: `2px solid ${valuesBorderColor}`,
      borderRadius: 8,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: 66,
      justifyContent: 'center',
      padding: `0 ${theme.spacing(2)}px`,
    },
    usdValue: {
      color: Color.DARK4,
      marginTop: theme.spacing(0.5),
    },
  };
});

export default useStyles;
