import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useMediaQuery } from '../../hooks';
import { Color } from '../../theme';
import FAQQuestion from '../FAQQuestion';
import LimitedHeightBox from '../LimitedHeightBox';

export type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

interface Item {
  answer: React.ReactNode;
  question: React.ReactNode;
}

const ITEMS: Item[] = [
  {
    question: 'What is staking?',
    answer:
      'Staking involves locking up a portion of your cryptocurrency for a set period of time as a way of contributing to a blockchain network. In exchange, stakers are rewarded with a percentage return of that cryptocurrency. Staking is similar to depositing money in a bank, in that an investor locks up their assets, and in exchange, earns rewards, or interest.',
  },
  {
    question: 'Who can participate in staking?',
    answer:
      'Any PBX holder who meets the minimum requirement per pool can participate in Paribus staking.',
  },
  {
    question: 'Is staking right for me?',
    answer:
      'Staking may not be correct for everyone. While single asset staking will not result in a diminishment of principle, trading strategies and timelines can be different for different people. Please decide what you think works best for you before you stake. Make the desired calculations in advance of placing any funds into a stake pool.',
  },
  {
    question: 'How do gas fees affect my rewards?',
    answer:
      'Gas fees can be a major issue on the Ethereum network. While stakers will be rewarded with PBX and pay fees in ETH, there is a possibility for a financial loss when participants in lower pools stake small amounts. We highly recommend stakers in Aereus pool to calculate potential reward amounts and compare to the fees before submitting to a stake pool.',
  },
  {
    question: 'What does APY mean?',
    answer:
      'APY stands for Annual Percentage Yield, the amount of return a participant can expect in the period of one year. All staking rewards are denoted in APY, although not every pool will continue for a year. If your desired pool is less than one year, divide the APY by 12 (months) and then times by the number of months to calculate your returns at the end of the staking period.',
  },
  {
    question: 'How can I benefit from staking?',
    answer:
      'Staking rewards those who lock their PBX by distributing additional PBX back to the participant. This reward is stated in APY and will be paid at the end of the staking period, based on the pool timeline.',
  },
  {
    question: 'Once I start staking can I exit early?',
    answer:
      'Yes, you are not required to stay for the entire staking period BUT rewards will be slashed by 30% if you leave before your lockup period is complete.',
  },
  {
    question: 'How are rewards paid?',
    answer:
      'Rewards are paid directly to the staking participants. These rewards will be shown and distributed directly on the Paribus staking DApp website.',
  },
  {
    question: 'What is the reward slashing period and when does it apply?',
    answer:
      'This is the period in which, if a staking participant removes their PBX before the end of the initial staking period, a deduction of rewards will be returned. After the slashing period is over, funds can be withdrawn without a deduction of return, and will be paid based on the number of days staked — at the rate of that pool.',
  },
  {
    question: 'What is the (lockup period)?',
    answer:
      'This is the period which PBX tokens within that pool are still subject to the “slashing period”. After the “lockup period” is over, PBX can be removed and rewards will be paid based on the number of days in the pool.',
  },
];

function FAQPanel({
  className,
  ...rest
}: Props): React.ReactElement<HTMLDivElement> {
  const [expandedIndex, setExpandedIndex] = useState<number>();
  const theme = useTheme();
  const limitedHeight = useMediaQuery(theme.breakpoints.up('md'));
  const fadeColor = theme.variant === 'light' ? Color.WHITE : Color.DARK1;

  const handleChange =
    (index: number) => (_event: React.ChangeEvent<{}>, expanded: boolean) => {
      setExpandedIndex(expanded ? index : undefined);
    };

  return (
    <LimitedHeightBox
      {...rest}
      className={clsx(className)}
      disabled={!limitedHeight}
      fadeColor={fadeColor}
      maxHeight="50vh"
      scrollable
    >
      {ITEMS.map((item, index) => (
        <FAQQuestion
          key={index}
          answerText={item.answer}
          expanded={expandedIndex === index}
          questionText={item.question}
          onChange={handleChange(index)}
        />
      ))}
    </LimitedHeightBox>
  );
}

export default FAQPanel;
