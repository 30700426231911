import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const BUTTON_OFFSET = 20;

const useStyles = makeStyles((theme) => ({
  text: {
    display: 'block',
    marginBottom: theme.spacing(3),
  },
  topBar: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(4),
  },
  topBarItem: {
    border: `2px solid ${
      theme.variant === 'light' ? Color.WHITE : Color.DARK3
    }`,
    borderRadius: 20,
    flexBasis: '100%',
    marginBottom: theme.spacing(2),
    padding: `10px 16px`,

    [theme.breakpoints.up('md')]: {
      flexBasis: 'auto',
      marginBottom: 0,
      marginRight: theme.spacing(2),
    },
  },
  topBarLabel: {
    marginBottom: theme.spacing(0.75),
  },
  heading: {
    marginBottom: theme.spacing(1.5),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
  },
  textFieldWrapper: {
    flex: 1,
  },
  textField: {
    [theme.breakpoints.up('md')]: {
      marginTop: 5,
    },
  },
  inputRoot: {
    background: theme.variant === 'light' ? Color.WHITE : 'transparent',
  },
  inputInput: {
    paddingBottom: 20,
    paddingTop: 20,
  },
  usdValue: {
    display: 'block',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  maxButton: {
    cursor: 'pointer',

    [theme.breakpoints.up('md')]: {
      paddingRight: BUTTON_OFFSET,
    },
  },
  button: {
    marginTop: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      marginLeft: -BUTTON_OFFSET,
      marginTop: 0,
    },
  },
}));

export default useStyles;
