import { useCallback, useState } from 'react';
import { simulateStake } from '../chain';
import { bignumber, BigNumber, BigNumberish } from '../math';
import { useAccount, useProvider } from '../wallet';

interface Options {
  poolAddress: string;
  time: number;
}

type SimulateResult = {
  error?: any;
};

type SimulateFn = (amount: BigNumberish) => Promise<SimulateResult>;

type SimulateState = {
  data?: BigNumber;
  error?: any;
  loading: boolean;
};

export function useSimulateStake({
  poolAddress,
  time,
}: Options): [SimulateFn, SimulateState] {
  const provider = useProvider();
  const { account } = useAccount();

  const [data, setData] = useState<BigNumber>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);

  const simulate = useCallback(
    async (amount: BigNumberish): Promise<SimulateResult> => {
      if (!provider || !account) {
        return {
          error: new Error('Wallet not connected'),
        };
      }

      if (bignumber(amount).comparedTo(0) === 0) {
        setData(bignumber(0));
        return {};
      }

      setError(undefined);
      setData(undefined);

      try {
        const data = await simulateStake(provider, {
          account,
          amount,
          poolAddress,
          time,
        });

        setData(data);
        return {};
      } catch (error) {
        setError(error);
        return { error };
      } finally {
        setLoading(false);
      }
    },
    [account, poolAddress, provider, time],
  );

  return [simulate, { data, error, loading }];
}
