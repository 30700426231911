import { ChainErrorCode } from './ChainErrorCode';

interface ChainErrorLike {
  code: ChainErrorCode;
  [key: string]: unknown;
}

const VALID_ERROR_CODES = Object.values(ChainErrorCode).filter(
  (x) => typeof x === 'number',
);

export class ChainError {
  public static fromError(error: any) {
    if (isChainError(error)) {
      return new ChainError(error.code);
    }

    return new ChainError(ChainErrorCode.UNKNOWN_ERROR);
  }

  constructor(public code: ChainErrorCode) {}

  public toString(): string {
    return ChainErrorCode[this.code];
  }
}

function isChainError(error: any): error is ChainErrorLike {
  return (
    typeof error === 'object' && VALID_ERROR_CODES.includes((error as any).code)
  );
}
