import { useCallback, useEffect, useRef, useState } from 'react';
import { getAccountPoolStats } from '../chain';
import { AccountPoolStats } from '../types';
import { useAccount, useProvider } from '../wallet';

interface Options {
  poolAddress: string;
  refetchOnBlock?: boolean;
}

export function useAccountPoolStats(options: Options) {
  const { poolAddress, refetchOnBlock = false } = options;
  const provider = useProvider();
  const { account } = useAccount();

  const [data, setData] = useState<AccountPoolStats>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const requestId = useRef(0);

  const loadData = useCallback(
    async (silent = false) => {
      if (!provider || !account) {
        return;
      }

      const thisRequestId = requestId.current + 1;
      requestId.current = thisRequestId;

      if (!silent) {
        setLoading(true);
        setError(undefined);
        setData(undefined);
      }

      try {
        const data = await getAccountPoolStats(provider, {
          account,
          poolAddress,
        });

        if (thisRequestId === requestId.current) {
          setData(data);
          setLoading(false);
        }
      } catch (error) {
        if (thisRequestId === requestId.current) {
          setError(error);
          setLoading(false);
        }
      }
    },
    [account, poolAddress, provider],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (refetchOnBlock && provider) {
      const refetch = () => loadData(true);

      provider.on('block', refetch);

      return () => {
        provider.off('block', refetch);
      };
    }
  }, [loadData, provider, refetchOnBlock]);

  return {
    data,
    error,
    loading,
    refetch: loadData,
  };
}
