import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import darkTheme from '../../darkTheme';
import lightTheme from '../../lightTheme';
import { ThemeVariant } from '../../types/ThemeVariant';

export interface Props {
  children: React.ReactNode;
  storageKey?: string;
}

export interface ThemeProviderContextShape {
  systemThemeVariant: ThemeVariant;
  themeVariant: ThemeVariant;
  setThemeVariant(nextThemeVariant: ThemeVariant): void;
  toggleThemeVariant(): void;
}

export const ThemeProviderContext = createContext<ThemeProviderContextShape>({
  systemThemeVariant: 'light',
  themeVariant: 'light',
  setThemeVariant: () => {},
  toggleThemeVariant: () => {},
});

const DEFAULT_THEME_VARIANT_STORAGE_KEY = '__theme_variant';
const MEDIA_QUERY = '(prefers-color-scheme: dark)';

const createLightTheme = () => Object.assign({}, lightTheme);
const createDarkTheme = () => Object.assign({}, darkTheme);

export function ThemeProvider({
  children,
  storageKey = DEFAULT_THEME_VARIANT_STORAGE_KEY,
}: Props): React.ReactElement {
  const [storedThemeVariant, setStoredThemeVariant] = useState(
    getStoredThemeVariant(storageKey),
  );
  const [systemThemeVariant, setSystemThemeVariant] = useState(
    getSystemThemeVariant(),
  );
  const themeVariant = storedThemeVariant || systemThemeVariant;

  // Material UI provider needs a fresh theme instance each switch
  const theme = useMemo(() => {
    return themeVariant === 'dark' ? createDarkTheme() : createLightTheme();
  }, [themeVariant]);

  const setThemeVariant = useCallback(
    (nextThemeVariant: ThemeVariant) => {
      localStorage.setItem(storageKey, nextThemeVariant);
      setStoredThemeVariant(nextThemeVariant);
    },
    [storageKey],
  );

  const toggleThemeVariant = useCallback(() => {
    setThemeVariant(themeVariant === 'dark' ? 'light' : 'dark');
  }, [setThemeVariant, themeVariant]);

  const handleSystemThemeVariantChange = useCallback(
    (event: MediaQueryListEvent) => {
      setSystemThemeVariant(event.matches ? 'dark' : 'light');
    },
    [],
  );

  useEffect(() => {
    if (storedThemeVariant === null) {
      const mediaQuery = window.matchMedia(MEDIA_QUERY);
      mediaQuery.addEventListener('change', handleSystemThemeVariantChange);

      return () => {
        mediaQuery.removeEventListener(
          'change',
          handleSystemThemeVariantChange,
        );
      };
    }
  }, [handleSystemThemeVariantChange, storedThemeVariant]);

  return (
    <ThemeProviderContext.Provider
      value={{
        systemThemeVariant,
        themeVariant,
        setThemeVariant,
        toggleThemeVariant,
      }}
    >
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeProviderContext.Provider>
  );
}

function getStoredThemeVariant(storageKey: string): ThemeVariant | null {
  const storedTheme = localStorage.getItem(storageKey);
  return isThemeVariant(storedTheme) ? storedTheme : null;
}

function getSystemThemeVariant(): ThemeVariant {
  return window.matchMedia(MEDIA_QUERY).matches ? 'dark' : 'light';
}

function isThemeVariant(input: any): input is ThemeVariant {
  return ['dark', 'light'].includes(input);
}
