import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function WithdrawIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <rect
          opacity="0.1"
          x="6"
          y="12"
          width="17"
          height="12"
          rx="2"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 10H18V11H20V10C20 8.89543 19.1046 8 18 8H4C2.89543 8 2 8.89543 2 10V20C2 21.1046 2.89543 22 4 22H18C19.1046 22 20 21.1046 20 20V19H18V20H4L4 10Z"
          fill="currentColor"
        />
        <circle cx="17" cy="15" r="1" fill="currentCOlor" />
        <path
          d="M14 15C14 13.3431 15.3431 12 17 12H21V18H17C15.3431 18 14 16.6569 14 15Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M8.42678 3.57322L11.8232 0.176777C11.9209 0.0791456 12.0791 0.0791453 12.1768 0.176776L15.5732 3.57322C15.7307 3.73071 15.6192 4 15.3964 4H8.60355C8.38083 4 8.26928 3.73072 8.42678 3.57322Z"
          fill="currentColor"
        />
        <path
          d="M11 6.75C11 6.88807 11.1119 7 11.25 7H12.75C12.8881 7 13 6.88807 13 6.75V4H11V6.75Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default WithdrawIcon;
