import clsx from 'clsx';
import React from 'react';
import InfoButton from '../InfoButton';
import Paper, { PaperProps } from '../Paper';
import useStyles from './OverviewCard.styles';

export type Props = PaperProps & {
  info?: {
    heading: React.ReactNode;
    text: React.ReactNode;
  };
};

function OverviewCard({
  children,
  className,
  info,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <Paper
      elevation={0}
      largeRoundings
      {...rest}
      className={clsx(classNames.root, className)}
    >
      {children}

      {!!info && (
        <InfoButton
          className={classNames.infoButton}
          popoverHeading={info.heading}
          popoverText={info.text}
        />
      )}
    </Paper>
  );
}

export default OverviewCard;
