import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function DepositIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <rect
          opacity="0.1"
          x="6"
          y="12"
          width="17"
          height="12"
          rx="2"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 10H18V11H20V10C20 8.89543 19.1046 8 18 8H4C2.89543 8 2 8.89543 2 10V20C2 21.1046 2.89543 22 4 22H18C19.1046 22 20 21.1046 20 20V19H18V20H4L4 10Z"
          fill="currentColor"
        />
        <circle cx="17" cy="15" r="1" fill="currentColor" />
        <path
          d="M14 15C14 13.3431 15.3431 12 17 12H21V18H17C15.3431 18 14 16.6569 14 15Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M8.42678 3.42678L11.8232 6.82322C11.9209 6.92085 12.0791 6.92085 12.1768 6.82322L15.5732 3.42678C15.7307 3.26929 15.6192 3 15.3964 3H8.60355C8.38083 3 8.26928 3.26928 8.42678 3.42678Z"
          fill="currentColor"
        />
        <path
          d="M11 0.25C11 0.111929 11.1119 0 11.25 0H12.75C12.8881 0 13 0.111929 13 0.25V3H11V0.25Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default DepositIcon;
