import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.variant === 'light' ? Color.LIGHT_GREY : Color.DARK2,
    border: theme.variant === 'light' ? 'none' : `1px solid ${Color.DARK3}`,
    marginBottom: theme.spacing(2),

    '&::before': {
      display: 'none',
    },

    '&:last-child': {
      marginBottom: 0,
    },
  },
  rounded: {
    borderRadius: 8,

    '&:first-child': {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },

    '&:last-child': {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
  },
}));

export default useStyles;
