import { alpha, makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    background: alpha(
      theme.variant === 'light' ? Color.PURPLE : Color.DARK1,
      0.1,
    ),
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      background: alpha(theme.palette.primary.main, 0.05),
      flexDirection: 'row',
      height: 64,
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  item: {
    alignItems: 'center',
    display: 'flex',
    height: 48,
  },
  tncLink: {
    textDecoration: 'none',
  },
}));

export default useStyles;
