import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.variant === 'light' ? Color.LIGHT_GREY : Color.DARK2,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    position: 'relative',
  },
  infoButton: {
    color: Color.GREY,
    position: 'absolute',
    right: 5,
    top: 5,

    '& svg': {
      fontSize: 16,
    },
  },
}));

export default useStyles;
