import { BigNumber, Contract, providers, Signer } from 'ethers';
import { bignumber, BigNumberish } from '../../../math';
import { erc20TokenABI } from './erc20TokenABI';

export class ERC20 {
  protected contract: Contract;

  constructor(address: string, providerOrSigner: providers.Provider | Signer) {
    this.contract = new Contract(address, erc20TokenABI, providerOrSigner);
  }

  /**
   * Fetches current allowance in units of the asset (10 ^ underlying decimals)
   *
   * @param owner Address of owner account
   * @param spender Address of spender contract
   */
  public async allowance(owner: string, spender: string) {
    const allowance: BigNumber = await this.contract.allowance(owner, spender);

    return bignumber(allowance.toString());
  }

  /**
   * Approve spending of this asset by given spender
   *
   * @param spender address of contract that will spend this asset
   * @param amount amount to spend in units of asset (1 * 10 ^ decimals)
   */
  public async approve(spender: string, amount: BigNumberish) {
    const tx = await this.contract.approve(spender, bignumber(amount).toHex());

    return tx;
  }

  /**
   * Fetches balance of given account in units of the asset (10 ^ underlying decimals)
   * @param owner Address of account
   */
  public async balanceOf(owner: string) {
    const balance: BigNumber = await this.contract.balanceOf(owner);
    return bignumber(balance.toString());
  }

  /**
   * Fetches number of decimals of the asset
   */
  public async decimals() {
    const decimals: number = await this.contract.decimals();
    return decimals;
  }

  /**
   * Fetches symbol of the asset
   */
  public async symbol() {
    const symbol: string = await this.contract.symbol();
    return symbol;
  }
}
