import clsx from 'clsx';
import React, { createContext } from 'react';
import useStyles from './PillTabs.styles';

export interface PillTabsContextShape {
  onChange?(nextValue: string): void;
  value: string;
}

export const PillTabsContext = createContext<PillTabsContextShape>({
  value: '',
});

type BaseProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'onChange' | 'value'
>;

export interface Props extends BaseProps {
  value: string;
  onChange?(nextValue: string): void;
}

function PillTabs({
  children,
  className,
  value,
  onChange,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();

  return (
    <PillTabsContext.Provider
      value={{
        onChange,
        value,
      }}
    >
      <div {...rest} className={clsx(classNames.root, className)}>
        {children}
      </div>
    </PillTabsContext.Provider>
  );
}

export default PillTabs;
