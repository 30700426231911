import React, { useMemo } from 'react';
import NumberFormat, { NumberFormatPropsBase } from 'react-number-format';
import { bignumber, BigNumber, BigNumberish } from '../../math';

type CompactNumber = {
  suffix: string | null;
  value: BigNumber;
};

type Breakpoint = {
  mantissa: BigNumberish;
  suffix: string;
  threshold: BigNumberish;
};

type ParseOptions = {
  breakpoints: Breakpoint[];
  compact: boolean;
  value: BigNumberish;
};

type BaseProps = Omit<NumberFormatPropsBase, 'displayType' | 'value'>;

export type Props = BaseProps & {
  breakpoints?: Breakpoint[];
  compact?: boolean;
  value: BigNumberish;
};

const DEFAULT_BREAKPOINTS: Breakpoint[] = [
  {
    mantissa: 1000,
    suffix: 'K',
    threshold: 10000,
  },
  {
    mantissa: 1000000,
    suffix: 'M',
    threshold: 1000000,
  },
  {
    mantissa: 1000000000,
    suffix: 'B',
    threshold: 1000000000,
  },
];

function parseValue({
  breakpoints,
  compact,
  value,
}: ParseOptions): CompactNumber {
  const bn = bignumber(value);

  if (!compact) {
    return {
      suffix: null,
      value: bn,
    };
  }

  const sortedBreakpoints = [...breakpoints].sort((a, b) => {
    return bignumber(b.threshold).comparedTo(bignumber(a.threshold));
  });

  const breakpoint = sortedBreakpoints.find((breakpoint) => {
    return bn.comparedTo(bignumber(breakpoint.threshold)) >= 0;
  });

  if (!breakpoint) {
    return {
      suffix: null,
      value: bn,
    };
  }

  return {
    suffix: breakpoint.suffix,
    value: bn.div(bignumber(breakpoint.mantissa)),
  };
}

function concatsuffix(
  value: CompactNumber,
  suffix: string | undefined,
): string | undefined {
  if (typeof suffix === 'undefined' && value.suffix === null) {
    return undefined;
  }

  if (value.suffix === null) {
    return suffix;
  }

  return value.suffix + (suffix || '');
}

function BigNumberFormat({
  breakpoints = DEFAULT_BREAKPOINTS,
  compact = false,
  suffix,
  value,
  ...rest
}: Props): React.ReactElement {
  const compactValue = useMemo(() => {
    return parseValue({ breakpoints, compact, value });
  }, [breakpoints, compact, value]);

  return (
    <NumberFormat
      {...rest}
      displayType="text"
      suffix={concatsuffix(compactValue, suffix)}
      value={compactValue.value.toNumber()}
    />
  );
}

export default BigNumberFormat;
