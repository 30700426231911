import { BigNumber, Contract, providers, Signer } from 'ethers';
import { bignumber, BigNumber as BN } from '../../../math';
import ABI from './uniswapPairABI';

export class UniswapPair {
  protected contract: Contract;

  constructor(address: string, providerOrSigner: providers.Provider | Signer) {
    this.contract = new Contract(address, ABI, providerOrSigner);
  }

  public async getReserves(): Promise<[BN, BN]> {
    const [reserve0, reserve1]: [BigNumber, BigNumber] =
      await this.contract.getReserves();
    return [bignumber(reserve0.toString()), bignumber(reserve1.toString())];
  }

  public async token0(): Promise<string> {
    const address: string = await this.contract.token0();
    return address;
  }

  public async token1(): Promise<string> {
    const address: string = await this.contract.token1();
    return address;
  }
}
