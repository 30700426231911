import { useCallback, useState } from 'react';
import { unstake } from '../chain/unstake';
import { BigNumberish } from '../math';
import { TransactionStatus } from '../types';
import { useAccount, useProvider } from '../wallet';

type Result = {
  error: any | null;
};

type UntakeFn = (amount: BigNumberish) => Promise<Result>;

type State = {
  error?: any;
  loading: boolean;
  status: TransactionStatus;
};

export function useUnstake(poolAddress: string): [UntakeFn, State] {
  const provider = useProvider();
  const { account } = useAccount();

  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(TransactionStatus.INITIALIZED);

  const execute = useCallback(
    async (amount: BigNumberish): Promise<Result> => {
      if (!provider || !account) {
        return {
          error: new Error('Wallet not connected'),
        };
      }

      setLoading(true);
      setError(undefined);

      try {
        for await (const currentStatus of unstake(provider, {
          amount,
          poolAddress,
        })) {
          setStatus(currentStatus);
        }

        return {
          error: null,
        };
      } catch (error) {
        setError(error);

        return {
          error,
        };
      } finally {
        setLoading(false);
      }
    },
    [account, poolAddress, provider],
  );

  return [
    execute,
    {
      error,
      loading,
      status,
    },
  ];
}
