import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color:
      theme.palette.text[theme.variant === 'light' ? 'secondary' : 'primary'],
    padding: theme.spacing(3),
  },
}));

export default useStyles;
