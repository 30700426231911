import { Pool } from '../types';
import { usePoolInfo } from './usePoolInfo';
import { usePoolUSDPrices } from './usePoolUSDPrices';

const DEFAULT_USD_PRICE_REFRESH_INTERVAL = 60000;

const USD_PRICE_REFRESH_INTERVAL_S = process.env
  .REACT_APP_USD_PRICE_REFRESH_INTERVAL
  ? parseInt(process.env.REACT_APP_USD_PRICE_REFRESH_INTERVAL)
  : null;

const USD_PRICE_REFRESH_INTERVAL =
  USD_PRICE_REFRESH_INTERVAL_S !== null && !isNaN(USD_PRICE_REFRESH_INTERVAL_S)
    ? USD_PRICE_REFRESH_INTERVAL_S * 1000
    : DEFAULT_USD_PRICE_REFRESH_INTERVAL;

export function usePool(poolAddress: string) {
  const {
    data: poolInfo,
    error: poolInfoError,
    loading: poolInfoLoading,
  } = usePoolInfo(poolAddress);
  const {
    data: poolUSDPrices,
    error: poolUSDPricesError,
    loading: poolUSDPricesLoading,
  } = usePoolUSDPrices(poolInfo || null, {
    refreshInterval: USD_PRICE_REFRESH_INTERVAL,
  });

  const data: Pool | undefined =
    !!poolInfo && !!poolUSDPrices
      ? {
          ...poolInfo,
          ...poolUSDPrices,
        }
      : undefined;

  return {
    data,
    error: poolInfoError || poolUSDPricesError,
    loading: poolInfoLoading || poolUSDPricesLoading,
  };
}
