import { useCallback, useEffect, useRef, useState } from 'react';
import { getPoolUSDPrices } from '../chain';
import { PoolInfo, PoolUSDPrices } from '../types';
import { useProvider } from '../wallet';

interface Options {
  refreshInterval?: number;
}

export function usePoolUSDPrices(
  poolInfo: PoolInfo | null,
  options: Options = {},
) {
  const { refreshInterval } = options;
  const provider = useProvider();

  const [data, setData] = useState<PoolUSDPrices>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const requestId = useRef(0);

  const loadData = useCallback(
    async (silent = false) => {
      if (!provider || !poolInfo) {
        return;
      }

      const thisRequestId = requestId.current + 1;
      requestId.current = thisRequestId;

      if (!silent) {
        setLoading(true);
        setError(undefined);
        setData(undefined);
      }

      try {
        const data = await getPoolUSDPrices(provider, poolInfo);

        if (thisRequestId === requestId.current) {
          setData(data);
          setLoading(false);
        }
      } catch (error) {
        if (thisRequestId === requestId.current) {
          setError(error);
          setLoading(false);
        }
      }
    },
    [poolInfo, provider],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (provider && typeof refreshInterval === 'number') {
      const refetch = () => loadData(true);

      const intervalId = setInterval(refetch, refreshInterval);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [loadData, provider, refreshInterval]);

  return {
    data,
    error,
    loading,
    refetch: loadData,
  };
}
