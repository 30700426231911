import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { useTokenBalance } from '../../hooks';
import { useStake } from '../../hooks/useStake';
import { BigNumber, bignumber } from '../../math';
import { Pool } from '../../types';
import { renderErrorMessage } from '../../utils';
import BigNumberFormat from '../BigNumberFormat';
import Button from '../Button';
import EstimatedRewardsCard from '../EstimatedRewardsCard';
import { PoolsIcon } from '../icons/PoolsIcon';
import WalletIcon from '../icons/WalletIcon';
import Message from '../Message';
import NumericTextField from '../NumericTextField';
import Paper from '../Paper';
import PoolAccountLimitText from '../PoolAccountLimitText';
import StakeTransactionDialog from '../StakeTransactionDialog';
import useStyles from './DepositForm.styles';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

export type Props = BaseProps & {
  canChangePool: boolean;
  pool: Pool;
  totalStaked: BigNumber;
  onRequestChangePool(): void;
};

type ValidationError = {
  suggestPoolChange: boolean;
  text: string;
};

function DepositForm({
  canChangePool,
  pool,
  totalStaked,
  onRequestChangePool,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();
  const [amount, setAmount] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { data: balance, refetch: refetchBalance } = useTokenBalance({
    tokenAddress: pool.stakingTokenAddress,
    refetchOnBlock: true,
  });

  const [stake, { loading: stakeInProgress, status: stakeStatus }] = useStake(
    pool.id,
  );

  const validationError: ValidationError | null = useMemo(() => {
    if (amount === '') {
      return null;
    }

    const amountBN = amount !== '' ? bignumber(amount) : bignumber(0);

    if (balance && amountBN.gt(balance)) {
      return {
        suggestPoolChange: false,
        text: 'Insufficent wallet balance.',
      };
    }

    if (amountBN.gt(pool.remainingStakeLimit)) {
      return {
        suggestPoolChange: false,
        text: 'The amount you wish to put into the pool would exceed the total pool stake limit.',
      };
    }

    if (amountBN.add(totalStaked).lt(pool.minStakingTokenLimit)) {
      return {
        suggestPoolChange: true,
        text: `It seems that you're trying to stake fewer tokens than required.`,
      };
    }

    if (
      pool.maxStakingTokenLimit.gt(0) &&
      amountBN.add(totalStaked).gt(pool.maxStakingTokenLimit)
    ) {
      return {
        suggestPoolChange: true,
        text: `The amount you wish to put into the pool would exceed the stake limit for your account.`,
      };
    }

    return null;
  }, [
    amount,
    balance,
    pool.maxStakingTokenLimit,
    pool.minStakingTokenLimit,
    pool.remainingStakeLimit,
    totalStaked,
  ]);

  const disabled =
    stakeInProgress ||
    validationError !== null ||
    amount === '' ||
    parseFloat(amount) <= 0;

  const handleDepositButtonClick = useCallback(() => {
    if (amount === '') {
      return;
    }

    stake(amount).then(({ error }) => {
      if (error) {
        enqueueSnackbar(renderErrorMessage(error), { variant: 'error' });
      } else {
        enqueueSnackbar('Deposit successfull', { variant: 'success' });
        setAmount('');
        refetchBalance(true);
      }
    });
  }, [amount, enqueueSnackbar, refetchBalance, stake]);

  const handleAmountChange = useCallback((value: string) => {
    setAmount(value);
  }, []);

  const handleMaxAmountButtonClick = useCallback(() => {
    if (typeof balance !== 'undefined') {
      const lowerLimit = pool.minStakingTokenLimit
        .sub(totalStaked)
        .clamp(0, pool.minStakingTokenLimit);

      const remainingAccountUpperLimit =
        pool.maxStakingTokenLimit.sub(totalStaked);

      const upperLimit =
        pool.maxStakingTokenLimit.gt(0) &&
        remainingAccountUpperLimit.lt(pool.remainingStakeLimit)
          ? remainingAccountUpperLimit
          : pool.remainingStakeLimit;
      const maxAmount = balance.clamp(lowerLimit, upperLimit);
      setAmount(maxAmount.toString());
    }
  }, [
    balance,
    pool.maxStakingTokenLimit,
    pool.minStakingTokenLimit,
    pool.remainingStakeLimit,
    totalStaked,
  ]);

  return (
    <React.Fragment>
      <div className={classNames.topBar}>
        <div className={classNames.topBarItem}>
          <WalletIcon className={classNames.topBarIcon} color="primary" />
          <Typography variant="caption">
            Wallet {pool.stakingTokenSymbol} balance:&nbsp;
          </Typography>
          <Typography variant="overline">
            {typeof balance !== 'undefined' && (
              <BigNumberFormat
                compact
                decimalScale={3}
                thousandSeparator
                value={balance}
              />
            )}

            {typeof balance === 'undefined' && <span>-</span>}
          </Typography>
        </div>

        <div className={classNames.topBarItem}>
          <PoolsIcon className={classNames.topBarIcon} color="primary" />
          <Typography variant="caption">Pool limit:&nbsp;</Typography>
          <Typography variant="overline">
            <BigNumberFormat
              compact
              decimalScale={3}
              thousandSeparator
              value={pool.remainingStakeLimit}
            />
          </Typography>
        </div>

        <div className={classNames.topBarItem}>
          <PoolsIcon className={classNames.topBarIcon} color="primary" />
          <Typography variant="caption">Your limit:&nbsp;</Typography>
          <PoolAccountLimitText
            max={pool.maxStakingTokenLimit}
            min={pool.minStakingTokenLimit}
            variant="overline"
          />
        </div>
      </div>
      <Grid {...rest} alignItems="stretch" container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper className={classNames.paper} elevation={0} largeRoundings>
            <Typography
              className={classNames.heading}
              color="inherit"
              variant="h6"
            >
              Enter Deposit Amount
            </Typography>

            <NumericTextField
              className={classNames.textField}
              fullWidth
              maxDecimalPlaces={pool.stakingTokenDecimals}
              variant="outlined"
              onChange={handleAmountChange}
              value={amount}
              InputProps={{
                classes: {
                  root: clsx(classNames.input, {
                    [classNames.inputError]: validationError !== null,
                  }),
                  focused: classNames.inputFocused,
                  notchedOutline: classNames.inputOutline,
                },
                endAdornment: (
                  <InputAdornment
                    className={classNames.maxButton}
                    onClick={handleMaxAmountButtonClick}
                    position="end"
                    role="button"
                  >
                    <Typography color="primary" variant="body2">
                      Max.
                    </Typography>
                  </InputAdornment>
                ),
                placeholder: 'Enter deposit amount...',
              }}
            />

            {validationError !== null && (
              <Message className={classNames.alert} variant="error">
                {validationError.text}
                {canChangePool && validationError.suggestPoolChange && (
                  <span>
                    {' '}
                    Try to{' '}
                    <span
                      className={classNames.poolChangeLink}
                      role="button"
                      onClick={onRequestChangePool}
                    >
                      change the pool
                    </span>
                    .
                  </span>
                )}
              </Message>
            )}

            <Button
              classes={{
                root: classNames.button,
              }}
              color="secondary"
              disabled={disabled}
              disableElevation
              fullWidth
              rounded
              size="large"
              variant="contained"
              onClick={handleDepositButtonClick}
            >
              Deposit
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <EstimatedRewardsCard
            amount={amount === '' ? '0' : amount}
            className={classNames.summary}
            pool={pool}
          />
        </Grid>
      </Grid>

      <StakeTransactionDialog open={stakeInProgress} status={stakeStatus} />
    </React.Fragment>
  );
}

export default DepositForm;
