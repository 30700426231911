import CssBaseline from '@material-ui/core/CssBaseline';
import { InjectedConnector } from '@web3-react/injected-connector';
import React from 'react';
import { ReactComponent as MetamaskIcon } from '../../assets/images/metamask-icon.svg';
import { HomePage } from '../../pages/HomePage';
import InvalidChainPage from '../../pages/InvalidChainPage';
import { ThemeProvider } from '../../theme';
import { WalletConfig, WalletErrorCode, WalletProvider } from '../../wallet';
import Snackbar from '../Snackbar';

const METAMASK_CONFIG: WalletConfig<InjectedConnector> = {
  label: 'Metamask',
  icon: <MetamaskIcon />,
  connector: new InjectedConnector({}),
  beforeConnect: async () => {
    const metamaskInstalled = !!(window as any).ethereum?.isMetaMask;

    if (!metamaskInstalled) {
      window.open('https://metamask.io', '_blank');
      return false;
    }

    return true;
  },
};

export function App(): React.ReactElement {
  return (
    <ThemeProvider>
      <CssBaseline />
      <WalletProvider
        desiredChainId={process.env.REACT_APP_DESIRED_CHAIN_ID}
        walletsConfig={[METAMASK_CONFIG]}
      >
        {({ error, loading }) => {
          if (loading) {
            return null;
          }

          return (
            <Snackbar>
              {!!error && error.code === WalletErrorCode.INVALID_CHAIN && (
                <InvalidChainPage />
              )}

              {!error && <HomePage />}
            </Snackbar>
          );
        }}
      </WalletProvider>
    </ThemeProvider>
  );
}
