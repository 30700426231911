import { makeStyles } from '@material-ui/core/styles';
import { Color } from '../../theme';

const useStyles = makeStyles((theme) => {
  const borderColor =
    theme.variant === 'light' ? Color.LIGHT_GREY : Color.DARK3;

  return {
    root: {
      alignItems: 'center',
      border: `2px solid ${borderColor}`,
      borderRadius: 8,
      display: 'flex',
      height: 48,
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  };
});

export default useStyles;
