import { Contract, providers, Signer } from 'ethers';
import ABI from './paribusStakeManagerABI';

export class ParibusStakeManager {
  public static ADDRESS =
    process.env.REACT_APP_PARIBUS_STAKE_MANAGER_ADDRESS || '';

  protected contract: Contract;

  constructor(providerOrSigner: providers.Provider | Signer) {
    this.contract = new Contract(
      ParibusStakeManager.ADDRESS,
      ABI,
      providerOrSigner,
    );
  }

  public async getContracts(): Promise<string[]> {
    const pools: string[] = await this.contract.getContracts();
    return pools;
  }
}
