import { Token } from '@uniswap/sdk-core';
import { providers } from 'ethers';
import { DESIRED_CHAIN_ID } from '../../constants';
import { getTokenDecimals } from '../getTokenDecimals';

export async function getUniswapToken(
  provider: providers.Provider,
  tokenOrAddress: Token | string,
): Promise<Token> {
  if (typeof tokenOrAddress !== 'string') {
    return tokenOrAddress;
  }

  const decimals = await getTokenDecimals(provider, tokenOrAddress);

  return new Token(DESIRED_CHAIN_ID, tokenOrAddress, decimals);
}
