import Typography, { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useCallback, useContext } from 'react';
import { HighlightTabsContext } from '../HighlightTabs';
import useStyles from './HighlightTab.styles';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
type LabelCreator = (active: boolean) => React.ReactNode;
type Classes = ReturnType<typeof useStyles>;

export interface Props extends BaseProps {
  classes?: Classes;
  disableTypography?: boolean;
  icon?: React.ReactNode;
  label: React.ReactNode | LabelCreator;
  TypographyProps?: TypographyProps;
  value: string;
}

function HighlightTab({
  classes,
  className,
  disableTypography = false,
  icon,
  label,
  TypographyProps,
  value,
  onClick,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();
  const { onChange, value: currentValue } = useContext(HighlightTabsContext);
  const active = value === currentValue;
  const labelContent = typeof label === 'function' ? label(active) : label;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      onClick?.(event);
      onChange?.(value);
    },
    [onChange, onClick, value],
  );

  return (
    <div
      {...rest}
      className={clsx(
        classNames.root,
        classes?.root,
        {
          [clsx(classNames.active, classes?.active)]: active,
        },
        className,
      )}
      onClick={handleClick}
    >
      {!!icon && <div className={classNames.iconWrapper}>{icon}</div>}

      {!disableTypography && (
        <Typography
          color="inherit"
          component="span"
          variant="subtitle1"
          {...TypographyProps}
        >
          {labelContent}
        </Typography>
      )}

      {disableTypography && labelContent}
    </div>
  );
}

export default HighlightTab;
