import { providers } from 'ethers';
import { ParibusStake } from '.';
import { memoizeAsync } from '../utils';

async function getPoolStakingTokenAddressUncached(
  provider: providers.Provider,
  poolAddress: string,
): Promise<string> {
  const pool = new ParibusStake(poolAddress, provider);
  const stakingTokenAddress = await pool.stakingToken();
  return stakingTokenAddress;
}

// memoize staking token address because it doesn't change
export const getPoolStakingTokenAddress = memoizeAsync(
  getPoolStakingTokenAddressUncached,
  (_, poolAddress) => poolAddress,
);
