import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export function HamburgerIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <line
        x1="4.42102"
        y1="5"
        x2="21.7894"
        y2="5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />

      <line
        x1="2"
        y1="12"
        x2="21.7894"
        y2="12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />

      <line
        x1="6"
        y1="19"
        x2="21"
        y2="19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />

      <g opacity="0.1">
        <line
          x1="5.63159"
          y1="7"
          x2="23"
          y2="7"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />

        <line
          x1="3.21057"
          y1="14"
          x2="23"
          y2="14"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />

        <line
          x1="8"
          y1="21"
          x2="23"
          y2="21"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    </SvgIcon>
  );
}
