import { providers } from 'ethers';
import { PoolInfo, PoolUSDPrices } from '../types';
import { ChainError } from './errors/ChainError';
import {
  getTokenUSDPrice,
  Options as GetTokenUSDPriceOptions,
} from './getTokenUSDPrice';

async function safeGetTokenUSDPrice(
  provider: providers.Provider,
  options: GetTokenUSDPriceOptions,
) {
  try {
    const price = await getTokenUSDPrice(provider, options);
    return price;
  } catch (error) {
    console.warn('An error occured when fetching token USD price', error);
    return null;
  }
}

export async function getPoolUSDPrices(
  provider: providers.Provider,
  poolInfo: PoolInfo,
): Promise<PoolUSDPrices> {
  try {
    const [stakingTokenPriceUSD, rewardTokenPriceUSD] = await Promise.all([
      safeGetTokenUSDPrice(provider, {
        tokenAddress: poolInfo.stakingTokenAddress,
        tokenDecimals: poolInfo.stakingTokenDecimals,
      }),
      safeGetTokenUSDPrice(provider, {
        tokenAddress: poolInfo.rewardTokenAddress,
        tokenDecimals: poolInfo.rewardTokenDecimals,
      }),
    ]);

    return {
      rewardTokenPriceUSD,
      stakingTokenPriceUSD,
    };
  } catch (error) {
    console.warn('An error occured when fetching pool USD prices', error);
    throw ChainError.fromError(error);
  }
}
