import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { ReactComponent as TotalRewardsIcon } from '../../assets/images/total-rewards.svg';
import { useClaimRewards } from '../../hooks';
import { adjustDecimals } from '../../math';
import { AccountPoolStats, Pool } from '../../types';
import BigNumberFormat from '../BigNumberFormat';
import Button from '../Button';
import UnstakeTransactionDialog from '../UnstakeTransactionDialog';
import useStyles from './ClaimRewardsForm.styles';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

export type Props = BaseProps & {
  accountPoolStats: AccountPoolStats;
  pool: Pool;
};

function ClaimRewardsForm({
  accountPoolStats,
  pool,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [claimRewards, { loading: claimInProgress, status: claimStatus }] =
    useClaimRewards(pool.id);

  const handleButtonClick = useCallback(() => {
    claimRewards().then(({ error }) => {
      if (error) {
        enqueueSnackbar(
          'An error occured when claiming rewards. Please try again later.',
          { variant: 'error' },
        );
      } else {
        enqueueSnackbar('Rewards claimed!', { variant: 'success' });
      }
    });
  }, [claimRewards, enqueueSnackbar]);

  return (
    <div {...rest}>
      <Typography
        className={classNames.text}
        color="textSecondary"
        variant="caption"
      >
        Withdraw all of your gained rewards.
      </Typography>

      <div className={classNames.wrapper}>
        <div className={classNames.content}>
          <TotalRewardsIcon className={classNames.icon} />

          <div className={classNames.values}>
            <Typography color="textSecondary" variant="button">
              <BigNumberFormat
                compact
                decimalScale={2}
                fixedDecimalScale
                suffix={' ' + pool.rewardTokenSymbol}
                thousandSeparator
                value={adjustDecimals(
                  accountPoolStats.rewards,
                  pool.rewardTokenDecimals,
                )}
              />
            </Typography>

            {pool.rewardTokenPriceUSD !== null && (
              <Typography className={classNames.usdValue} variant="caption">
                <BigNumberFormat
                  compact
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={adjustDecimals(
                    accountPoolStats.rewards.mul(pool.rewardTokenPriceUSD),
                    pool.rewardTokenDecimals,
                  )}
                />
              </Typography>
            )}
          </div>
        </div>

        <Button
          color="primary"
          disabled={claimInProgress || accountPoolStats.rewards.toNumber() <= 0}
          onClick={handleButtonClick}
          rounded
          size="large"
          variant="contained"
        >
          Withdraw
        </Button>
      </div>

      <UnstakeTransactionDialog open={claimInProgress} status={claimStatus} />
    </div>
  );
}

export default ClaimRewardsForm;
