import {
  all,
  BigNumber,
  bignumber,
  create,
  Fraction,
  MathArray,
  Matrix,
  MathJsStatic,
} from 'mathjs';

export type BigNumberish =
  | number
  | string
  | Fraction
  | BigNumber
  | MathArray
  | Matrix
  | boolean
  | Fraction
  | null;

export const mathjs = create(all, {
  number: 'BigNumber',
  precision: 18,
}) as MathJsStatic;

export { bignumber };
export type { BigNumber };
