interface ShortenOptions {
  leading?: number;
  trailing?: number;
}

export function shortenAddress(
  address: string,
  options: ShortenOptions = {},
): string {
  const { leading = 4, trailing = 4 } = options;

  return address.slice(0, leading) + '...' + address.slice(-trailing);
}
