import { providers } from 'ethers';
import { adjustDecimals, bignumber } from '../math';
import { Pool } from '../types';
import { ParibusStake } from './contracts/ParibusStake';
import { ChainError } from './errors/ChainError';
import { getPoolStakingTokenAddress } from './getPoolStakingToken';
import { getTokenDecimals } from './getTokenDecimals';
import { getTokenSymbol } from './getTokenSymbol';
import {
  getTokenUSDPrice,
  Options as GetTokenUSDPriceOptions,
} from './getTokenUSDPrice';

async function safeGetTokenUSDPrice(
  provider: providers.Provider,
  options: GetTokenUSDPriceOptions,
) {
  try {
    const price = await getTokenUSDPrice(provider, options);
    return price;
  } catch (error) {
    console.warn('An error occured when fetching token USD price', error);
    return null;
  }
}

export async function getPool(
  provider: providers.Provider,
  poolAddress: string,
): Promise<Pool> {
  try {
    const pool = new ParibusStake(poolAddress, provider);
    const [rewardTokenAddress, stakingTokenAddress, info, state] =
      await Promise.all([
        pool.rewardToken(),
        getPoolStakingTokenAddress(provider, poolAddress),
        pool.getPoolInfo(),
        pool.getPoolState(),
      ]);

    const [
      rewardTokenSymbol,
      rewardTokenDecimals,
      stakingTokenSymbol,
      stakingTokenDecimals,
    ] = await Promise.all([
      getTokenSymbol(provider, rewardTokenAddress),
      getTokenDecimals(provider, rewardTokenAddress),
      getTokenSymbol(provider, stakingTokenAddress),
      getTokenDecimals(provider, stakingTokenAddress),
    ]);

    const [
      name,
      ,
      penaltyLockupPeriod,
      maximumStakedTokenAllowed,
      unlockedAt,
      minAmountOfStakedTokensRequired,
      maxAmountOfStakedTokensRequired,
    ] = info;
    const [
      stakedTokenCurrentAmount,
      ,
      updateUnlocksPerSecond,
      rewardTokenFromAllTimeToDistribute,
      rewardTokenTotalClaimedRewards,
      ,
      rewardTokensCurrentlyUnlocked,
      rewardTokensCurrentlyToBeUnlocked,
    ] = state;

    const [stakingTokenPriceUSD, rewardTokenPriceUSD] = await Promise.all([
      safeGetTokenUSDPrice(provider, {
        tokenAddress: stakingTokenAddress,
        tokenDecimals: stakingTokenDecimals,
      }),
      safeGetTokenUSDPrice(provider, {
        tokenAddress: rewardTokenAddress,
        tokenDecimals: rewardTokenDecimals,
      }),
    ]);

    const rewardsPerSecond = adjustDecimals(
      updateUnlocksPerSecond,
      rewardTokenDecimals,
    );

    const totalValueLocked = adjustDecimals(
      stakedTokenCurrentAmount,
      stakingTokenDecimals,
    );

    const rewardToStakingTokenRatio =
      stakingTokenAddress.toLowerCase() === rewardTokenAddress.toLowerCase()
        ? bignumber(1)
        : stakingTokenPriceUSD !== null && rewardTokenPriceUSD !== null
        ? rewardTokenPriceUSD.div(stakingTokenPriceUSD)
        : null;

    const apr =
      rewardToStakingTokenRatio !== null
        ? rewardsPerSecond
            .mul(rewardToStakingTokenRatio)
            .mul(365 * 24 * 60 * 60)
            .div(totalValueLocked.add(1))
        : null;

    return {
      id: poolAddress,
      apr,
      maxStakingTokenLimit: adjustDecimals(
        maxAmountOfStakedTokensRequired,
        stakingTokenDecimals,
      ),
      minStakingTokenLimit: adjustDecimals(
        minAmountOfStakedTokensRequired,
        stakingTokenDecimals,
      ),
      name,
      fullyUnlockedAt: unlockedAt * 1000,
      lockedRewards: adjustDecimals(
        rewardTokensCurrentlyToBeUnlocked,
        rewardTokenDecimals,
      ),
      penaltyPeriod: penaltyLockupPeriod,
      remainingStakeLimit: adjustDecimals(
        maximumStakedTokenAllowed.sub(stakedTokenCurrentAmount),
        stakingTokenDecimals,
      ),
      rewardsPerSecond,
      rewardTokenAddress,
      rewardTokenDecimals,
      rewardTokenPriceUSD,
      rewardTokenSymbol,
      stakingTokenAddress,
      stakingTokenDecimals,
      stakingTokenPriceUSD,
      stakingTokenSymbol,
      totalAccruedRewards: adjustDecimals(
        rewardTokensCurrentlyUnlocked,
        rewardTokenDecimals,
      ),
      totalClaimedRewards: adjustDecimals(
        rewardTokenTotalClaimedRewards,
        rewardTokenDecimals,
      ),
      totalRewards: adjustDecimals(
        rewardTokenFromAllTimeToDistribute,
        rewardTokenDecimals,
      ),
      totalValueLocked,
    };
  } catch (error) {
    throw ChainError.fromError(error);
  }
}
