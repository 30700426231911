import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useState } from 'react';
import depositImageUrl from '../../assets/images/deposit.png';
import { ReactComponent as LockedRewardsIcon } from '../../assets/images/locked-rewards.svg';
import { useAccountPoolStats } from '../../hooks';
import { adjustDecimals } from '../../math';
import { Pool } from '../../types';
import Accordion from '../Accordion';
import AccordionDetails from '../AccordionDetails';
import AccordionSummary from '../AccordionSummary';
import ClaimRewardsForm from '../ClaimRewardsForm';
import LeavePoolForm from '../LeavePoolForm';
import PoolOverview from '../PoolOverview';
import Spinner from '../Spinner';
import WithdrawForm from '../WithdrawForm';
import useStyles from './WithdrawPanel.styles';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

export type Props = BaseProps & {
  pool: Pool;
};

function WithdrawPanel({ pool, ...rest }: Props): React.ReactElement {
  const classNames = useStyles();
  const [mode, setMode] = useState<string>();
  const { data: accountPoolStats, loading: accountPoolStatsLoading } =
    useAccountPoolStats({
      poolAddress: pool.id,
      refetchOnBlock: true,
    });

  const nothingToWithdraw =
    !!accountPoolStats &&
    accountPoolStats.withdrawable.comparedTo(0) <= 0 &&
    accountPoolStats.estimatedAccrued.comparedTo(0) <= 0;

  const handleModeChange =
    (mode: string) => (_event: React.ChangeEvent<{}>, expanded: boolean) => {
      setMode(expanded ? mode : undefined);
    };

  return (
    <div {...rest}>
      <PoolOverview
        className={classNames.overview}
        estimatedAccrued={accountPoolStats?.estimatedAccrued}
        pool={pool}
      />

      {accountPoolStatsLoading && (
        <Box display="flex" justifyContent="center">
          <Spinner />
        </Box>
      )}

      {!accountPoolStatsLoading && !accountPoolStats && (
        <Typography align="center" color="textSecondary">
          Could not load withdrawal data. Please try again later.
        </Typography>
      )}

      {!accountPoolStatsLoading && nothingToWithdraw && (
        <Box alignItems="center" display="flex" flexDirection="column">
          <LockedRewardsIcon className={classNames.lockedRewardsIcon} />

          <Typography align="center" color="textSecondary">
            It seems that you haven't deposited anything yet.
          </Typography>
          <Typography align="center" color="textSecondary">
            At the moment, you cannot withdraw tokens and rewards.
          </Typography>
        </Box>
      )}

      {!accountPoolStatsLoading && !!accountPoolStats && !nothingToWithdraw && (
        <div className={classNames.wrapper}>
          <div className={classNames.content}>
            <Accordion
              expanded={mode === 'lossless'}
              onChange={handleModeChange('lossless')}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon color="primary" />}
              >
                <Typography variant="subtitle2">
                  Withdraw staked tokens
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <WithdrawForm
                  className={classNames.accordionContent}
                  pool={pool}
                  unlocked={adjustDecimals(
                    accountPoolStats.withdrawableWithoutLoss,
                    pool.stakingTokenDecimals,
                  )}
                  total={adjustDecimals(
                    accountPoolStats.withdrawable,
                    pool.stakingTokenDecimals,
                  )}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={mode === 'rewards'}
              onChange={handleModeChange('rewards')}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon color="primary" />}
              >
                <Typography variant="subtitle2">Withdraw rewards</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <ClaimRewardsForm
                  accountPoolStats={accountPoolStats}
                  className={classNames.accordionContent}
                  pool={pool}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={mode === 'leave'}
              onChange={handleModeChange('leave')}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon color="primary" />}
              >
                <Typography variant="subtitle2">Leave contract</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <LeavePoolForm
                  accountPoolStats={accountPoolStats}
                  className={classNames.accordionContent}
                  pool={pool}
                />
              </AccordionDetails>
            </Accordion>
          </div>

          <div className={classNames.imageWrapper}>
            <img alt="" className={classNames.image} src={depositImageUrl} />
          </div>
        </div>
      )}
    </div>
  );
}

export default WithdrawPanel;
