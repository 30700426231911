import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useAccountPoolStats } from '../../hooks';
import { adjustDecimals } from '../../math';
import { Pool } from '../../types';
import DepositForm from '../DepositForm';
import PoolClosedInfo from '../PoolClosedInfo';
import PoolOverview from '../PoolOverview';
import useStyles from './DepositPanel.styles';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

export type Props = BaseProps & {
  canChangePool: boolean;
  pool: Pool;
  onRequestChangePool(): void;
};

function hasPoolEnded(poolEndDate: number): boolean {
  const endDate = dayjs.utc(poolEndDate).local();
  return endDate.isBefore(dayjs());
}

function DepositPanel({
  canChangePool,
  pool,
  onRequestChangePool,
  ...rest
}: Props): React.ReactElement {
  const classNames = useStyles();
  const [poolEnded, setPoolEnded] = useState(
    hasPoolEnded(pool.fullyUnlockedAt),
  );
  const { data: accountPoolStats } = useAccountPoolStats({
    poolAddress: pool.id,
    refetchOnBlock: true,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPoolEnded(hasPoolEnded(pool.fullyUnlockedAt));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [pool.fullyUnlockedAt]);

  return (
    <div {...rest}>
      <PoolOverview
        className={classNames.overview}
        estimatedAccrued={accountPoolStats?.estimatedAccrued}
        pool={pool}
      />

      {poolEnded && <PoolClosedInfo />}

      {!poolEnded && !!accountPoolStats && (
        <DepositForm
          canChangePool
          onRequestChangePool={onRequestChangePool}
          pool={pool}
          totalStaked={adjustDecimals(
            accountPoolStats.withdrawable,
            pool.stakingTokenDecimals,
          )}
        />
      )}
    </div>
  );
}

export default DepositPanel;
