import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.pillTabs.backgroundColor,
    boxShadow: theme.pillTabs.pillShadow,
    color: theme.pillTabs.color,
    cursor: 'pointer',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px`,

    '&:first-child': {
      borderBottomLeftRadius: 8,
      borderTopLeftRadius: 8,
    },

    '&:last-child': {
      borderBottomRightRadius: 8,
      borderTopRightRadius: 8,
    },

    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    },
  },
  active: {
    backgroundColor: theme.pillTabs.activeBackgroundColor,
    color: theme.pillTabs.activeColor,
    position: 'relative',
    zIndex: 1,
  },
}));

export default useStyles;
