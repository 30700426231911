import { providers } from 'ethers';
import { memoizeAsync } from '../utils';
import { ERC20 } from './contracts/ERC20';

async function getTokenSymbolUncached(
  provider: providers.Provider,
  address: string,
): Promise<string> {
  const token = new ERC20(address, provider);
  const symbol = await token.symbol();
  return symbol;
}

// memoize token decimals because they don't change
export const getTokenSymbol = memoizeAsync(
  getTokenSymbolUncached,
  (_, address) => address,
);
