import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function EyeIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <g opacity="0.1">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.4651 7.93024C7.74419 7.93024 3.93024 13.6512 3.93024 13.6512C3.93024 13.6512 7.74419 19.3721 13.4651 19.3721C19.1861 19.3721 23 13.6512 23 13.6512C23 13.6512 19.1861 7.93024 13.4651 7.93024ZM16.8023 13.6512C16.8023 15.4943 15.3082 16.9884 13.4651 16.9884C11.622 16.9884 10.1279 15.4943 10.1279 13.6512C10.1279 11.8081 11.622 10.314 13.4651 10.314C15.3082 10.314 16.8023 11.8081 16.8023 13.6512Z"
            fill="currentColor"
          />
        </g>
        <path
          d="M2.5 12.2209C2.5 12.2209 6.31395 6.5 12.0349 6.5C17.7558 6.5 21.5698 12.2209 21.5698 12.2209C21.5698 12.2209 17.7558 17.9419 12.0349 17.9419C6.31395 17.9419 2.5 12.2209 2.5 12.2209Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
    </SvgIcon>
  );
}

export default EyeIcon;
