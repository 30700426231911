import { decomposeColor, recomposeColor } from '@material-ui/core/styles';

export function blendColors(
  overlayColor: string,
  backgroundColor: string,
): string {
  const {
    type: overlayColorType,
    values: [r1, g1, b1, alpha = 1],
  } = decomposeColor(overlayColor);

  const {
    type: backgroundColorType,
    values: [r2, g2, b2],
  } = decomposeColor(backgroundColor);

  if (overlayColorType !== 'rgba' || backgroundColorType !== 'rgb') {
    console.warn('Invalid colors specified for blending.');
    return overlayColor;
  }

  const r = r2 + Math.round((r1 - r2) * alpha);
  const g = g2 + Math.round((g1 - g2) * alpha);
  const b = b2 + Math.round((b1 - b2) * alpha);

  return recomposeColor({
    type: 'rgb',
    values: [r, g, b],
  });
}
