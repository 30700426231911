import Box, { BoxProps } from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { TNC_URL } from '../../constants';
import { useThemeVariant } from '../../theme';
import { Switch } from '../Switch';
import useStyles from './ControlsBar.styles';

export type Props = Omit<BoxProps, 'children'>;

export function ControlsBar({ className, ...rest }: Props): React.ReactElement {
  const classNames = useStyles();
  const { themeVariant, setThemeVariant } = useThemeVariant();

  const handleDarkModeSwitchChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, darkModeEnabled: boolean) => {
      setThemeVariant(darkModeEnabled ? 'dark' : 'light');
    },
    [setThemeVariant],
  );

  return (
    <Box display="flex" {...rest} className={clsx(classNames.root, className)}>
      <div className={classNames.item}>
        <Typography
          className={classNames.tncLink}
          color="textSecondary"
          component="a"
          href={TNC_URL}
          target="_blank"
          variant="h6"
        >
          Terms &amp; Conditions
        </Typography>
      </div>

      <Box alignItems="center" display="flex" className={classNames.item}>
        <Typography color="textSecondary" variant="h6">
          Dark mode:
        </Typography>
        <Switch
          checked={themeVariant === 'dark'}
          color="primary"
          onChange={handleDarkModeSwitchChange}
        />
      </Box>
    </Box>
  );
}
