import Typography, { TypographyProps } from '@material-ui/core/Typography';
import React from 'react';
import { BigNumber } from '../../math';
import BigNumberFormat from '../BigNumberFormat';

type BaseProps = Omit<TypographyProps, 'children'>;
export type Props = BaseProps & {
  max: BigNumber;
  min: BigNumber;
  separator?: React.ReactNode;
  suffix?: string;
};

function PoolAccountLimitText({
  max,
  min,
  separator = '-',
  suffix,
  ...rest
}: Props): React.ReactElement {
  if (max.eq(0) && min.eq(0)) {
    return <Typography {...rest}>NO LIMIT</Typography>;
  }

  const minElement = (
    <BigNumberFormat
      compact
      decimalScale={3}
      suffix={suffix}
      thousandSeparator
      value={min}
    />
  );

  const maxElement = max.gt(0) ? (
    <BigNumberFormat
      compact
      decimalScale={3}
      suffix={suffix}
      thousandSeparator
      value={max}
    />
  ) : (
    'NO LIMIT'
  );

  return (
    <Typography {...rest}>
      {minElement}
      {separator}
      {maxElement}
    </Typography>
  );
}

export default PoolAccountLimitText;
