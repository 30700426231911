import React from 'react';
import { ReactComponent as LogoDarkTheme } from '../../assets/images/logo-dark-theme.svg';
import { ReactComponent as LogoLightTheme } from '../../assets/images/logo-light-theme.svg';
import { useThemeVariant } from '../../theme';

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

export interface Props extends BaseProps {
  size?: number;
  variant?: 'auto' | 'light-theme' | 'dark-theme';
}

export function Logo({
  size,
  variant = 'auto',
  ...rest
}: Props): React.ReactElement {
  const { themeVariant } = useThemeVariant();

  return (
    <div {...rest}>
      {themeVariant === 'light' ? (
        <LogoLightTheme height={size} />
      ) : (
        <LogoDarkTheme height={size} />
      )}
    </div>
  );
}
